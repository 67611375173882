import { WarningFilled } from '@carbon/icons-react';

const WarningIcon = () => {
  return (
    <div
      className={
        'flex items-center justify-center bg-warning-surface-light rounded-full min-w-[40px] h-[40px] m-auto'
      }
    >
      <WarningFilled fill='var(--warning-content-default)' size={24} />
    </div>
  );
};

export default WarningIcon;
