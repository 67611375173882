import { Dialog } from 'primereact/dialog';
import React, { useMemo, useState } from 'react';
import { Translate, translateWithValues, TranslateWithValues } from '../../../../i18n/translate';
import { CurrencySelect, CountrySelect, TextInput } from '../../../../components';
import { Button } from 'primereact/button';
import { useForm, type SubmitHandler, type UseFormReturn } from 'react-hook-form';
import BranchLookupModal from './BranchLookupModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import PaymentService from '../../paymentService';
import { cn, createRegexFromString } from '../../../../utils/helper';
import { useAppDispatch } from '../../../../store';
import { sendToast } from '../../../../store/slices/main/mainSlice';
import { useSelector } from 'react-redux';
import {
  selectCountries,
  selectCustomerCurrencies,
} from '../../../../store/slices/refdata/refdataSlice';
import getCountryISO2, {
  getCountryISO3,
} from '../../../../components/CountrySelect/isoncCodeMapping';

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  quoteId: string;
  buyCurrency: string;
};

const BeneficiaryCreationModal = ({ visible, setVisible, quoteId, buyCurrency }: Props) => {
  return (
    <>
      <Dialog
        className='w-4/6 scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
        header={
          <>
            <div className='text-lg font-semibold text-neutral-1'>
              <Translate value='add_new_beneficiary.header' />
            </div>
            <div className='text-sm-regular mt-1 text-neutral-3'>
              <Translate value='add_new_beneficiary.subtitle' />
            </div>
          </>
        }
        visible={visible}
        style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
        onHide={() => setVisible(false)}
        draggable={false}
      >
        <BeneficiaryFormLoader
          quoteId={quoteId}
          buyCurrency={buyCurrency}
          closeModal={() => setVisible(false)}
        />
      </Dialog>
    </>
  );
};

type FormType = {
  [key: string]: any;
};

function BeneficiaryFormLoader({
  quoteId,
  buyCurrency,
  closeModal,
}: {
  quoteId: string;
  buyCurrency: string;
  closeModal: () => void;
}) {
  const selectedCustomerCurrencies = useSelector(selectCustomerCurrencies);
  const selectedCountries = useSelector(selectCountries);

  const [beneficiaryCountry, setBeneficiaryCountry] = useState(() => {
    const homeCountries = selectedCustomerCurrencies.find(
      (item) => item.isocode === buyCurrency,
    )?.homeCountries;
    return homeCountries && homeCountries.length === 1
      ? selectedCountries.find((item) => item.code === getCountryISO3(homeCountries[0]))
      : undefined;
  });

  const countries = useMemo(() => {
    const item = selectedCustomerCurrencies.find((item) => item.isocode === buyCurrency);
    const countryCodeList =
      item?.homeCountries.concat(item.intermediaries).map((item) => getCountryISO3(item)) || [];
    return countryCodeList.reduce((acc, code) => {
      const country = selectedCountries.find((item) => item.code === code);
      return country ? [...acc, country] : acc;
    }, [] as Array<Country>);
  }, [buyCurrency, selectedCustomerCurrencies]);

  return (
    <div className='grid gap-4 grid-rows-[auto_1fr] h-full overflow-auto pr-2'>
      <div className='grid grid-cols-3 gap-5 mt-6 h-full'>
        <CurrencySelect
          label='currency'
          placeholder='currency'
          isRequired
          disabled
          value={buyCurrency}
        />
        <CountrySelect
          label='beneficiary_country'
          placeholder='beneficiary_country'
          isRequired
          countryList={countries}
          value={beneficiaryCountry}
          onChange={(value) => setBeneficiaryCountry(value)}
        />
      </div>
      {!beneficiaryCountry || !buyCurrency ? (
        <div className='text-neutral-3 h-full grid place-content-center'>
          <Translate value='select_country' className='text-neutral-3 text-center' />
        </div>
      ) : (
        <BeneficiaryForm
          key={beneficiaryCountry.code} // to clear the form when country changes
          quoteId={quoteId}
          buyCurrency={buyCurrency}
          beneficiaryCountry={beneficiaryCountry}
          closeModal={closeModal}
        />
      )}
    </div>
  );
}

function BeneficiaryForm({
  quoteId,
  buyCurrency,
  beneficiaryCountry,
  closeModal,
}: {
  quoteId: string;
  buyCurrency: string;
  beneficiaryCountry: Country;
  closeModal: () => void;
}) {
  const form = useForm<FormType>();

  const {
    register,
    formState: { errors },
    watch,
    trigger,
    setValue,
    handleSubmit,
  } = form;

  const [swiftBicCode, setSwiftBicCode] = useState('');

  const selectedCustomerCurrencies = useSelector(selectCustomerCurrencies);
  const dispatch = useAppDispatch();

  const homeCountries = selectedCustomerCurrencies.find(
    (item) => item.isocode === buyCurrency,
  )?.homeCountries;

  const beneficiaryDataQuery = useQuery({
    queryKey: ['request_quote_beneficiary', beneficiaryCountry.code, buyCurrency],
    queryFn: async () => {
      const data = await PaymentService.getBeneficiaryFormByCountry(
        getCountryISO2(beneficiaryCountry.code),
        buyCurrency,
      );
      return data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!beneficiaryCountry && !!buyCurrency,
  });

  const submitFormMutation = useMutation({
    mutationFn: async (data: FormType) => {
      const response = await PaymentService.addPaymentCreditor(quoteId, data);
      return response;
    },
    onSuccess: () => {
      closeModal();
    },
    onError: () => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'Failed',
          detail: 'add_new_beneficiary.error',
        }),
      );
    },
  });

  const onSubmit: SubmitHandler<FormType> = (data) => {
    submitFormMutation.mutate(data);
  };

  if (beneficiaryDataQuery.isLoading) {
    return (
      <div className='text-neutral-3 h-full grid place-content-center'>
        <Translate value='loading' />
      </div>
    );
  }

  if (beneficiaryDataQuery.isError) {
    return (
      <div className='text-neutral-3 h-full grid place-content-center'>
        <Translate value='beneficiary_loading_error' className='text-neutral-3 text-center' />
      </div>
    );
  }

  if (beneficiaryDataQuery.data?.length === 0) {
    return (
      <div className='text-neutral-3 h-full grid place-content-center'>
        <Translate value='no_beneficiary_data' className='text-neutral-3 text-center' />
      </div>
    );
  }

  function groupByOrder(arr: Array<BeneficiaryType>) {
    const grouped = arr.reduce<Record<number, Array<BeneficiaryType>>>((acc, obj) => {
      if (!acc[obj.order]) {
        acc[obj.order] = [];
      }
      acc[obj.order].push(obj);
      return acc;
    }, {});

    return Object.entries(grouped).map(([key, value]) => {
      return { [key]: value };
    });
  }

  const mainFieldElements: Array<BeneficiaryType> = [];
  const additionalFieldElements: Array<BeneficiaryType> = [];
  beneficiaryDataQuery.data?.forEach((beneficiary) => {
    if (!beneficiary.rules.some((rule) => rule.ruleName === 'MANDATORY')) {
      return;
    }

    if (beneficiary.displaySection === 'Beneficiary Details') {
      mainFieldElements.push(beneficiary);
    } else {
      additionalFieldElements.push(beneficiary);
    }
  });

  const fields = [...groupByOrder(mainFieldElements), ...groupByOrder(additionalFieldElements)];

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
        className='grid grid-rows-[1fr_auto] gap-6 h-full pb-6'
      >
        <div className='grid gap-5'>
          {fields.map((order, index) => (
            <div key={`order-${index}`} className='grid gap-5 md:grid-cols-2 lg:grid-cols-3'>
              {Object.values(order)[0].map((beneficiary: BeneficiaryType, i) => {
                const isIntermediaryAgent = beneficiary.mappings.includes(
                  'IntermediaryAgents.[0].RoutingCode',
                );
                const isRequired = beneficiary.rules.some((rule) => rule.ruleName === 'MANDATORY');
                const maxLength = beneficiary.rules.find((rule) => rule.ruleName === 'MAX LENGTH')
                  ?.ruleValues[0] as number;
                const fixLength = beneficiary.rules.find((rule) => rule.ruleName === 'FIX LENGTH')
                  ?.ruleValues as Array<number>;
                const pattern = beneficiary.rules.some(
                  (rule) => rule.ruleName === 'ALLOWED CHARACTER SET',
                )
                  ? createRegexFromString(
                      beneficiary.rules.find((rule) => rule.ruleName === 'ALLOWED CHARACTER SET')
                        ?.ruleValues[0] as string,
                    )
                  : undefined;

                const textInputComponent = (
                  <TextInput
                    label={beneficiary.fieldLabel}
                    isRequired={isRequired}
                    placeholder={beneficiary.fieldName}
                    formRegister={register(beneficiary.fieldIdentifier, {
                      onChange: () => {
                        if (
                          beneficiary.mappings.includes('CreditorAgent.RoutingCode') ||
                          isIntermediaryAgent
                        ) {
                          trigger(beneficiary.fieldIdentifier);
                        }
                      },
                      required: isRequired,
                      maxLength: maxLength
                        ? {
                            value: maxLength,
                            message: translateWithValues('max_length_error', { maxLength }),
                          }
                        : undefined,
                      pattern: pattern
                        ? {
                            value: pattern,
                            message: 'invalid_characters',
                          }
                        : undefined,
                      validate: (value) => {
                        if (fixLength && fixLength.length > 0) {
                          return fixLength.includes(value.length.toString())
                            ? true
                            : `Must be ${fixLength.join(' or ')} characters long`;
                        }
                        return true;
                      },
                    })}
                    error={errors[beneficiary.fieldIdentifier]}
                    className={cn(
                      beneficiary.fieldLabelIdentifier === 'BeneficiaryAccountName' && 'col-span-3',
                    )}
                  />
                );

                if (
                  beneficiary.mappings.includes('CreditorAgent.RoutingCode') ||
                  isIntermediaryAgent
                ) {
                  return (
                    <div key={i} className='grid md:col-span-2 lg:col-span-3 gap-5 mb-5'>
                      <BankCode
                        form={form}
                        fieldLabel={beneficiary.fieldLabel}
                        fieldValue={watch(beneficiary.fieldIdentifier)}
                        buyCurrency={buyCurrency}
                        countryCode={beneficiaryCountry.code}
                        countries={
                          isIntermediaryAgent && homeCountries?.length === 1
                            ? (homeCountries
                                .map((item) => getCountryISO3(item))
                                .filter((item) => item !== undefined) as string[])
                            : [beneficiaryCountry.code]
                        }
                        autoCompletes={beneficiary.autoCompletes}
                        updateBicCode={(bicCode) => {
                          setValue(beneficiary.fieldIdentifier, bicCode);
                          if (beneficiary.fieldLabelIdentifier === 'SwiftBICCode') {
                            setSwiftBicCode(bicCode);
                          }
                        }}
                        fieldLabelIdentifier={beneficiary.fieldLabelIdentifier}
                        swiftBicCode={swiftBicCode}
                        isButtonDisabled={
                          !!errors[beneficiary.fieldIdentifier] ||
                          !watch(beneficiary.fieldIdentifier)
                        }
                      >
                        {textInputComponent}
                      </BankCode>
                    </div>
                  );
                }

                return <React.Fragment key={i}>{textInputComponent}</React.Fragment>;
              })}
            </div>
          ))}
        </div>
        <div className='flex justify-center gap-6'>
          <Button type='button' severity='contrast'>
            <Translate value='delete_entry' />
          </Button>
          <Button
            severity='info'
            className='w-56 inline-flex gap-2 justify-center items-center'
            disabled={!beneficiaryDataQuery.isSuccess}
            loading={submitFormMutation.isPending}
          >
            <Translate value='submit_for_approval' />
          </Button>
        </div>
      </form>
    </>
  );
}

function BankCode({
  form,
  fieldLabel,
  fieldValue,
  buyCurrency,
  countryCode,
  countries,
  autoCompletes,
  updateBicCode,
  fieldLabelIdentifier,
  swiftBicCode,
  isButtonDisabled,
  children,
}: {
  form: UseFormReturn<FormType, any, undefined>;
  fieldLabel: string;
  fieldValue: string;
  buyCurrency: string;
  countryCode: string;
  countries: Array<string>;
  autoCompletes: BeneficiaryType['autoCompletes'];
  updateBicCode: (bicCode: string) => void;
  fieldLabelIdentifier: string;
  swiftBicCode: string;
  isButtonDisabled: boolean;
  children: React.ReactNode;
}) {
  const [branchDialogVisible, setBranchDialogVisible] = useState(false);

  const {
    register,
    formState: { errors },
    setValue,
  } = form;

  const getInterMediariesDataQuery = useQuery({
    queryKey: ['getInterMediariesData', buyCurrency, swiftBicCode],
    queryFn: async () => {
      const data = await PaymentService.getInterMediariesData(buyCurrency, swiftBicCode);
      if (data && data.length > 0 && data[0].intermediary && data[0].intermediary.length === 1) {
        setValue('IntermediaryBankCode', data[0].intermediary[0].localcode);
        setValue('InstitutionName', data[0].intermediary[0].bankname);
        setValue('City', data[0].intermediary[0].address[1]);
        setValue('StreetAddress1', data[0].intermediary[0].address[0]);
      }
      return data;
    },
    enabled: fieldLabelIdentifier === 'IntermediaryBankABACode' && !!swiftBicCode && !!buyCurrency,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const branchDataQuery = useQuery({
    queryKey: ['branch_data', countryCode, fieldLabelIdentifier, fieldValue, '', ''],
    queryFn: async () => {
      const data =
        fieldLabelIdentifier === 'SwiftBICCode'
          ? await PaymentService.getBICCode(getCountryISO2(countryCode), fieldValue, '', '')
          : await PaymentService.getBankDirectory(getCountryISO2(countryCode), fieldValue, '', '');
      if (data && data.length === 1) {
        updateBicCode(fieldLabelIdentifier === 'SwiftBICCode' ? data[0].Bic : data[0].NationalId);
        autoCompletes.forEach((autoComplete) => {
          setValue(autoComplete.fieldId, data[0][autoComplete.fieldId]);
        });
      } else {
        autoCompletes.forEach((autoComplete) => {
          setValue(autoComplete.fieldId, '');
        });
      }
      return data;
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  });

  if (fieldLabelIdentifier === 'IntermediaryBankABACode' && getInterMediariesDataQuery.isPending) {
    return null;
  }

  return (
    <>
      <div className='grid gap-5 bg-primary-surface-light p-6 rounded-lg'>
        <div className='flex gap-6 h-fit'>
          {children}
          <Button
            type='button'
            severity='info'
            className='mt-7 w-28 flex justify-center'
            onClick={() => void branchDataQuery.refetch()}
            disabled={isButtonDisabled}
          >
            <Translate value='done' />
          </Button>
        </div>
        <Button
          link
          type='button'
          className='text-selected w-fit text-sm-semibold'
          onClick={() => setBranchDialogVisible(true)}
        >
          <Translate value='search_code' />
        </Button>
      </div>
      <div className='grid gap-5 md:grid-cols-2 lg:grid-cols-3'>
        {autoCompletes.map((autoComplete) => (
          <TextInput
            key={autoComplete.fieldId}
            label={autoComplete.fieldIdReference}
            isRequired
            placeholder={autoComplete.fieldIdReference}
            formRegister={register(autoComplete.fieldId, {
              required: true,
            })}
            error={errors[autoComplete.fieldId]}
            disabled
          />
        ))}
      </div>
      <Dialog
        className='w-3/5 scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
        header={
          <>
            <div className='text-lg font-semibold text-neutral-1'>
              <TranslateWithValues value='branch_lookup.header' params={{ label: fieldLabel }} />
            </div>
            <div className='text-sm-regular mt-1 text-neutral-3'>
              <Translate value='branch_lookup.subtitle' />
            </div>
          </>
        }
        visible={branchDialogVisible}
        style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
        onHide={() => setBranchDialogVisible(false)}
        draggable={false}
      >
        <BranchLookupModal
          buyCurrency={buyCurrency}
          countries={countries}
          fieldLabelIdentifier={fieldLabelIdentifier}
          fieldLabel={fieldLabel}
          onBicCodeSelect={(bicCodeData) => {
            autoCompletes.forEach((autoComplete) => {
              setValue(autoComplete.fieldId, bicCodeData[autoComplete.fieldId]);
            });
            setBranchDialogVisible(false);
            updateBicCode(
              fieldLabelIdentifier === 'SwiftBICCode' ? bicCodeData.Bic : bicCodeData.NationalId,
            );
          }}
          closeModal={() => setBranchDialogVisible(false)}
        />
      </Dialog>
    </>
  );
}

export default BeneficiaryCreationModal;
