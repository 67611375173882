const FetchUpload = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.25 16.5001H17.625C18.8329 16.5005 19.9979 16.0524 20.894 15.2426C21.7902 14.4327 22.3537 13.319 22.4752 12.1172C22.5967 10.9155 22.2676 9.71144 21.5516 8.73863C20.8357 7.76581 19.784 7.09355 18.6005 6.85223C18.2809 5.33851 17.4507 3.98065 16.249 3.00621C15.0474 2.03178 13.5473 1.5 12.0002 1.5C10.4531 1.5 8.95307 2.03178 7.75143 3.00621C6.54978 3.98065 5.7196 5.33851 5.40001 6.85223C4.21639 7.09338 3.16452 7.76555 2.44845 8.73836C1.73237 9.71116 1.40313 10.9152 1.52458 12.1171C1.64604 13.3189 2.20953 14.4327 3.10576 15.2426C4.00199 16.0525 5.16707 16.5006 6.37501 16.5001H6.75001V15.0001H6.37501C5.50335 15.0003 4.66542 14.6632 4.03688 14.0593C3.40834 13.4553 3.03799 12.6315 3.00347 11.7606C2.96894 10.8896 3.27293 10.0391 3.85169 9.38727C4.43045 8.73548 5.23906 8.33305 6.10801 8.26433L6.71963 8.2164L6.79396 7.6074C6.94969 6.33621 7.56511 5.16592 8.52418 4.31717C9.48324 3.46843 10.7197 2.99989 12.0004 2.99989C13.2811 2.99989 14.5175 3.46843 15.4766 4.31717C16.4357 5.16592 17.0511 6.33621 17.2068 7.6074L17.2808 8.2164L17.8923 8.26433C18.7613 8.33305 19.5699 8.73551 20.1487 9.38732C20.7274 10.0391 21.0314 10.8897 20.9968 11.7607C20.9623 12.6317 20.5919 13.4555 19.9633 14.0594C19.3347 14.6633 18.4967 15.0004 17.625 15.0001H17.25V16.5001ZM8.25 13.5001L12 9.75009L15.75 13.5001L14.6925 14.5576L12.75 12.6226V21.0001C12.75 21.3979 12.592 21.7794 12.3107 22.0608C12.0294 22.3421 11.6478 22.5001 11.25 22.5001H4.5V21.0001H11.25V12.6226L9.3075 14.5576L8.25 13.5001Z'
      fill='url(#paint0_linear_505_26819)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_505_26819'
        x1='11.9999'
        y1='1.5'
        x2='11.9999'
        y2='22.5001'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='var(--primary-gradient-to)' />
        <stop offset='1' stopColor='var(--primary-gradient-from)' />
      </linearGradient>
    </defs>
  </svg>
);

export default FetchUpload;
