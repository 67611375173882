import { Outlet } from 'react-router';

const ManagedPricingOutlet = () => {
  return (
    <>
      <Outlet />
    </>
  );
};
export default ManagedPricingOutlet;
