import { Button } from 'primereact/button';
import { Translate } from '../../../../i18n/translate';
import { DropdownInput, RichEditor } from '../../../../components';
import { DISCLOSURE_TYPE_OPTIONS } from '../../configurationsConstants';
import { fetchDisclaimer, updateDisclaimer } from '../../configurationService';
import { useEffect, useState } from 'react';
import { Disclaimers, DisclosureType } from '../../../../types';
import { DisclaimerData, Disclosure } from '../type';
import { EditorTextChangeEvent } from 'primereact/editor';
import useRole from '../../../../hooks/useRoles';
import configurationRole from '../../configurationRole';

const Dashboard = () => {
  const [disclaimerData, setDisclaimerData] = useState<DisclaimerData>({});
  const [disclosureType, setDisclosureType] = useState<Disclosure>(
    DisclosureType.PREPAYMENT_DISCLOSURE,
  );
  const [isLoading, setIsLoading] = useState(true);
  const { hasRole } = useRole();

  const getDisclaimer = async () => {
    const disclaimerData = await fetchDisclaimer('2');
    if (disclaimerData) {
      const formData: DisclaimerData = {};
      disclaimerData.forEach((disclaimerEntity) => {
        formData[disclaimerEntity.type] = disclaimerEntity.content;
      });
      setDisclaimerData(formData);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDisclaimer();
  }, []);

  const saveDisclaimers = async () => {
    const payload = Object.keys(disclaimerData).map((type) => ({
      type,
      content: disclaimerData[type as keyof DisclaimerData],
    }));
    const response = await updateDisclaimer(payload, '2');
    console.log(response);
  };

  const handleChange = (e: EditorTextChangeEvent, name: string) => {
    setDisclaimerData({ ...disclaimerData, [name]: e.htmlValue });
  };

  return !isLoading ? (
    <div className='mx-8 px-6 py-2 bg-white rounded-c8 border border-neutral-surface-dark'>
      <div className='flex justify-between py-4 border-b border-neutral-border-2'>
        <div>
          <div className='text-lg-semibold'>
            <Translate value='disclaimer.title' />
          </div>
          <div className='text-neutral-3 mt-1'>
            <Translate value='disclaimer.subtitle' />
          </div>
        </div>
        {hasRole(
          `${configurationRole.editConfiguration}&${configurationRole.viewConfiguration}`,
        ) && (
          <div>
            <Button
              severity='info'
              onClick={() => {
                saveDisclaimers();
              }}
            >
              <Translate value='save' />
            </Button>
          </div>
        )}
      </div>
      <div className='py-6 border-b border-neutral-border-2'>
        <div className='flex justify-between'>
          <div className='text-neutral-1 text-sm-medium'>
            <Translate value='licensing' />
          </div>
          <div className='w-3/5'>
            <RichEditor
              value={disclaimerData[Disclaimers.LICENSING]}
              onChange={handleChange}
              name={Disclaimers.LICENSING}
            />
          </div>
        </div>
      </div>
      <div className='py-6 border-b border-neutral-border-2'>
        <div className='flex justify-between'>
          <div className='text-neutral-1 text-sm-medium'>
            <Translate value='disclaimer.title' />
          </div>
          <div className='w-3/5'>
            <RichEditor
              value={disclaimerData[Disclaimers.DISCLAIMER]}
              onChange={handleChange}
              name={Disclaimers.DISCLAIMER}
            />
          </div>
        </div>
      </div>
      <div className='py-6 border-b border-neutral-border-2'>
        <div className='flex justify-between'>
          <div className='text-neutral-1 text-sm-medium w-2/5'>
            <Translate value='disclosure' />
            <DropdownInput
              className='mt-5 w-4/5'
              label='disclosure_type'
              placeholder='disclosure_type'
              value={disclosureType}
              onChange={(e) => {
                setDisclosureType(e.target.value as Disclosure);
              }}
              options={DISCLOSURE_TYPE_OPTIONS}
            />
          </div>
          <div className='w-3/5'>
            <RichEditor
              value={disclaimerData[disclosureType]}
              onChange={handleChange}
              name={disclosureType}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Dashboard;
