import { CheckmarkFilled, RadioButtonChecked } from '@carbon/icons-react';
import { MenuItem } from 'primereact/menuitem';
import { Steps } from 'primereact/steps';
import { memo } from 'react';
import { Translate } from '../../../../../i18n/translate';

type Props = {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
};

const AddStructureSteps = memo(({ activeIndex, setActiveIndex }: Props) => {
  const itemRenderer = (item: MenuItem, itemIndex: number) => {
    const isFilledItem = activeIndex >= itemIndex;
    return (
      <button
        disabled={activeIndex <= itemIndex || activeIndex === 3}
        className='z-10 flex flex-col items-center mt-4'
        onClick={() => setActiveIndex(itemIndex)}
      >
        {isFilledItem ? (
          <div className='bg-background z-10 rounded-full'>
            <CheckmarkFilled className='text-selected' size={22} />
          </div>
        ) : (
          <div className='z-10 bg-background'>
            <RadioButtonChecked size={22} className='text-neutral-5' />
          </div>
        )}
        <span
          className={`text-sm-semibold ${isFilledItem ? 'text-selected' : 'text-neutral-2'}`}
        >{`Step ${itemIndex + 1}`}</span>
        {itemIndex !== 0 && (
          <div
            className={`absolute top-7 w-[calc(100%-11px)] right-[calc(50%)] h-[1px] border  ${isFilledItem ? 'border-primary' : 'border-neutral-5'}`}
          ></div>
        )}
        <Translate value={item.label ?? ''} className='text-neutral-3' />
      </button>
    );
  };

  const items: MenuItem[] = [
    {
      label: 'pick_the_category_you_want_for_approvals',
      template: (item) => itemRenderer(item, 0),
    },
    {
      label: 'pick_approvers_from_your_organization',
      template: (item) => itemRenderer(item, 1),
    },
    {
      label: 'pick_initiators_from_your_organization',
      template: (item) => itemRenderer(item, 2),
    },
    {
      label: 'experience_seamless_approvals',
      template: (item) => itemRenderer(item, 3),
    },
  ];

  return (
    <Steps
      model={items}
      activeIndex={activeIndex}
      readOnly={false}
      className='horizontal-stepper'
    />
  );
});

AddStructureSteps.displayName = 'AddStructureSteps';

export default AddStructureSteps;
