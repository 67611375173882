import { RoleGuard } from '../../components';
import { AddOrEditOrView } from '../../types';
import AddClient from './AddClient';
import Dashboard from './Dashboard';
import ViewClient from './ViewClient';
import ClientRole from './clientRole';

const clientRoutes = [
  {
    id: 'dashboard',
    index: true,
    handle: {
      breadcrumb: '',
    },
    element: <Dashboard />,
  },
  {
    id: 'childDashboard',
    path: ':customerId/view-child',
    handle: {
      breadcrumb: '',
    },
    element: <Dashboard />,
  },
  {
    id: 'addnew',
    path: 'add',
    handle: {
      breadcrumb: 'Add New Client',
    },
    element: (
      <RoleGuard roles={ClientRole.AddClient}>
        <AddClient mode={AddOrEditOrView.Add} />
      </RoleGuard>
    ),
  },
  {
    id: 'addChildClient',
    path: ':customerId/add',
    handle: {
      breadcrumb: 'Add New Child Client',
    },
    element: (
      <RoleGuard roles={ClientRole.AddClient}>
        <AddClient mode={AddOrEditOrView.Add} />
      </RoleGuard>
    ),
  },
  {
    id: 'edit',
    path: 'edit/:customerId',
    handle: {
      breadcrumb: 'Edit Client',
    },
    element: (
      <RoleGuard roles={ClientRole.UpdateClient}>
        <AddClient mode={AddOrEditOrView.Edit} />
      </RoleGuard>
    ),
  },
  {
    id: 'view',
    path: 'view/:customerId',
    handle: {
      breadcrumb: 'View Client',
    },
    element: (
      <RoleGuard roles={ClientRole.ViewClient}>
        <ViewClient mode={AddOrEditOrView.View} />
      </RoleGuard>
    ),
  },
  {
    id: 'approve',
    path: 'approve/:customerId',
    handle: {
      breadcrumb: 'Approve/Reject Client',
    },
    element: (
      <RoleGuard roles={ClientRole.ApproveClient}>
        <ViewClient mode={AddOrEditOrView.Approve} />
      </RoleGuard>
    ),
  },
];

export default clientRoutes;
