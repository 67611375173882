import DOMPurify from 'dompurify';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

export const Translate = ({ value, className }: { value: string; className?: string }) => {
  if (!value) return <></>;
  const intl = useIntl();
  const formattedMsg = intl.formatMessage({ id: value });
  return <span className={className}>{formattedMsg}</span>;
};

const replaceVariables = (message: string, variables: { [x: string]: any }): string => {
  return message.replace(/\{(\w+)\}/g, (match, key) => variables[key] || match);
};

export const translateWithValues = (key: any, translateValues = {}) => {
  if (!key) return '';
  const intl = useIntl();
  const formattedMsg = intl.formatMessage({ id: key }, translateValues);
  return replaceVariables(formattedMsg, translateValues);
};

interface TranslateWithValuesProps {
  value: string;
  params?: { [key: string]: any };
  className?: string;
}

export const TranslateWithValues: React.FC<TranslateWithValuesProps> = ({
  value,
  params = {},
  className,
}) => {
  if (!value) return null;
  const intl = useIntl();
  const formattedMsg = intl.formatMessage({ id: value }, params);

  return <span className={className}>{formattedMsg}</span>;
};

export const RawHtmlContent = ({
  htmlContent,
  requireTranslate = true,
  translateValues = {},
}: {
  htmlContent: string;
  requireTranslate?: boolean;
  translateValues?: { [key: string]: any };
}) => {
  let afterTranslate: string | ReactNode = htmlContent;
  if (requireTranslate) {
    afterTranslate = translateWithValues(htmlContent, translateValues);
  }
  DOMPurify.addHook('afterSanitizeAttributes', function (node: any) {
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
  });
  if (afterTranslate) {
    const sanitizedHtml = DOMPurify.sanitize(afterTranslate.toString());
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  }
  return <></>;
};
