const STATUS_OPTIONS = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
];

const STRUCTURE_TYPES = [
  { label: 'Simple', value: 'SIMPLE' },
  { label: 'Sequential', value: 'SEQUENTIAL' },
  { label: 'Tiered', value: 'TIERED' },
  { label: 'Grouped', value: 'GROUPED' },
];

export { STATUS_OPTIONS, STRUCTURE_TYPES };
