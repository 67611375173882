const Search = () => {
  return (
    <svg
      width='17.0'
      height='17.0'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.1247 17.2408L13.4046 12.5208C14.5388 11.1591 15.1044 9.41264 14.9837 7.64461C14.863 5.87658 14.0653 4.22315 12.7566 3.02827C11.4479 1.83339 9.72885 1.18906 7.95716 1.22932C6.18548 1.26958 4.49752 1.99133 3.24442 3.24442C1.99133 4.49752 1.26958 6.18548 1.22932 7.95717C1.18906 9.72885 1.83339 11.4479 3.02827 12.7566C4.22315 14.0653 5.87658 14.863 7.64461 14.9837C9.41264 15.1044 11.1591 14.5388 12.5208 13.4046L17.2408 18.1247L18.1247 17.2408ZM2.49966 8.12466C2.49966 7.01214 2.82956 5.9246 3.44764 4.99958C4.06573 4.07455 4.94423 3.35358 5.97206 2.92784C6.9999 2.50209 8.1309 2.3907 9.22204 2.60774C10.3132 2.82478 11.3155 3.36051 12.1021 4.14718C12.8888 4.93385 13.4245 5.93613 13.6416 7.02728C13.8586 8.11842 13.7472 9.24942 13.3215 10.2773C12.8957 11.3051 12.1748 12.1836 11.2497 12.8017C10.3247 13.4198 9.23718 13.7497 8.12466 13.7497C6.63332 13.748 5.20354 13.1548 4.14901 12.1003C3.09448 11.0458 2.50131 9.616 2.49966 8.12466Z'
        fill='url(#paint0_linear_2666_50692)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2666_50692'
          x1='9.6761'
          y1='1.22754'
          x2='9.6761'
          y2='18.1247'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='var(--primary-gradient-to)' />
          <stop offset='1' stopColor='var(--primary-gradient-from)' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Search;
