const Switch = () => {
  return (
    <svg width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='0.5'
        y='40'
        width='40'
        height='40'
        rx='8'
        transform='rotate(-90 0.5 40)'
        fill='var(--primary-gradient-from)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.75 28.125L13 24.375L16.75 20.625L17.625 21.5L15.375 23.75H28V25H15.375L17.625 27.25L16.75 28.125ZM25.625 15L23.375 12.75L24.25 11.875L28 15.625L24.25 19.375L23.375 18.5L25.625 16.25H13V15H25.625Z'
        fill='white'
      />
    </svg>
  );
};

export default Switch;
