import Dashboard from './Dashboard';

const emailSettingsRoutes = [
  {
    id: 'emailSettingsDashboard',
    index: true,
    element: <Dashboard />,
  },
];

export default emailSettingsRoutes;
