import { useSelector } from 'react-redux';
import { IconField } from 'primereact/iconfield';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { Translate } from '../../../../../../i18n/translate';
import { ErrorIcon } from '../../../../../../components';
import { cn } from '../../../../../../utils/helper';
import { selectCurrentCustomer } from '../../../../../../store/slices/main/mainSlice';

type Props = {
  isEditMode: boolean;
  minThreshold: string;
  maxThreshold: string;
  setMinThreshold: (value: string) => void;
  setMaxThreshold: (value: string) => void;
};

function AmountForm({
  isEditMode,
  minThreshold,
  maxThreshold,
  setMinThreshold,
  setMaxThreshold,
}: Props) {
  return (
    <div className='grid gap-4 p-6 border-b border-neutral-surface-subtle'>
      <CurrencyInput
        name='fromAmount'
        label='create_approval.from_amount'
        value={minThreshold}
        onChange={(value) => setMinThreshold(value)}
        disabled={!isEditMode}
      />
      <CurrencyInput
        name='toAmount'
        label='create_approval.to_amount'
        value={maxThreshold}
        onChange={(value) => setMaxThreshold(value)}
        disabled={!isEditMode}
      />
    </div>
  );
}

type CurrencyInputProps = {
  name: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  error?: string;
};

function CurrencyInput({ name, label, value, onChange, disabled, error }: CurrencyInputProps) {
  const currentCustomer = useSelector(selectCurrentCustomer);

  return (
    <div>
      <label htmlFor={name}>
        <Translate value={label} />
      </label>
      <div className='relative'>
        <IconField iconPosition='right'>
          <InputText
            id={name}
            type='number'
            value={value}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
            placeholder='5000.000'
            className={cn('pl-20 py-2 w-full', !!error && 'pr-10')}
            tooltip={error}
            invalid={!!error}
          />
          {!!error && (
            <InputIcon>
              <ErrorIcon isFilled={false} size={16} withBackground={false} />
            </InputIcon>
          )}
        </IconField>
        <div className='absolute left-2 top-1/2 translate-y-[-50%] flex gap-2'>
          <img
            className='rounded-full w-[19px] h-[19px] object-cover inline-block'
            src='/images/c_flags_svg/usd.svg'
            alt='USD flag'
          />
          <span>{currentCustomer?.currency || 'USD'}</span>
        </div>
      </div>
    </div>
  );
}

export default AmountForm;
