import { createAsyncThunk } from '@reduxjs/toolkit';
import UserprincipalService from '../../../modules/UserPrinciple/userPrinicple';

export const fethCurrentUser = createAsyncThunk<RolesResponse | null>(
  'mainData/fetchCurrentUser',
  async (): Promise<RolesResponse | null> => {
    const response = await UserprincipalService.getCurrentUser();
    return response;
  },
);
