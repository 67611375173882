import { combineReducers, PayloadAction } from '@reduxjs/toolkit';
import confirmModalSlice from './slices/main/confirmModalSlice';
import mainSlice from './slices/main/mainSlice';
import refdataSlice from './slices/refdata/refdataSlice';
import roleSlice from './slices/role/roleSlice';

const _combinedReducer = combineReducers({
  refdata: refdataSlice,
  main: mainSlice,
  role: roleSlice,
  confirmModal: confirmModalSlice,
});

const rootReducer = (state: RootState | undefined, action: PayloadAction): RootState => {
  if (action.type === 'session/resetState') {
    state = undefined;
  }
  return _combinedReducer(state, action);
};

export type RootState = ReturnType<typeof _combinedReducer>;

export default rootReducer;
