import { RoleGuard } from '../../../components';
import approvalsRoles from './approvalsRoles';
import Dashboard from './Dashboard';
import AddStructure from './Dashboard/AddStructure';
import UpdateApprovalStatus from './Dashboard/UpdateStatus';

const approvalsRoutes = [
  {
    id: 'approvalsDashboard',
    index: true,
    element: <Dashboard />,
  },
  {
    id: 'addStructure',
    path: 'add-structure',
    handle: {
      breadCrumb: 'Add New Structure',
    },
    element: (
      <RoleGuard roles={approvalsRoles.AddApprovalFlow}>
        <AddStructure />
      </RoleGuard>
    ),
  },
  {
    id: 'editStructure',
    path: 'edit-structure',
    handle: {
      breadCrumb: 'Edit Structure',
    },
    element: (
      <RoleGuard roles={approvalsRoles.UpdateApprovalFlow}>
        <AddStructure />
      </RoleGuard>
    ),
  },
  {
    id: 'updateStatus',
    path: 'update-status',
    handle: {
      breadCrumb: 'Update Status',
    },
    element: (
      <RoleGuard roles={approvalsRoles.ApproveApprovalFlow}>
        <UpdateApprovalStatus />
      </RoleGuard>
    ),
  },
];

export default approvalsRoutes;
