import { useQuery } from '@tanstack/react-query';
import ApprovalSetup from '../ApprovalSetup';
import ApprovalsTable from './ApprovalsTable';
import ApprovalsSerive from '../approvalsService';
import { selectCurrentCustomer } from '../../../../store/slices/main/mainSlice';
import { useSelector } from 'react-redux';

type Props = {
  feature: FeatureType;
};

const ApprovalsTab = ({ feature }: Props) => {
  const selectedCustomer = useSelector(selectCurrentCustomer);

  const { isLoading, data, isFetched, isFetching } = useQuery({
    queryKey: ['approvals_list', feature, selectedCustomer?.id],
    queryFn: async () => {
      const data = await ApprovalsSerive.getApprovalStructures(feature);
      return data;
    },
    retry: false,
  });

  if (!isFetched && isLoading) {
    return <div className='text-center'>Loading...</div>;
  }

  if (!data || !data.length) {
    return <ApprovalSetup feature={feature} />;
  }

  return <ApprovalsTable feature={feature} data={data} isFetching={isFetching} />;
};

export default ApprovalsTab;
