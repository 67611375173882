import { createContext, useState } from 'react';
import { PageHead } from '../../components';

export interface PageHeadData {
  pageHeadData: PageHeadProps;
  setPageHeadData: (value: PageHeadProps) => void;
}

export const PageHeadContext = createContext<PageHeadData>({
  pageHeadData: { title: '', description: '' },
  setPageHeadData: () => {
    return;
  },
});

export const PageHeadProvider = ({ children }: { children: React.ReactNode }) => {
  const [pageHeadData, setPageHeadData] = useState<PageHeadProps>({
    title: '',
    description: '',
  });

  return (
    <PageHeadContext.Provider value={{ pageHeadData, setPageHeadData }}>
      {pageHeadData.title && (
        <div className='dashboard-padding bg-white pt-[16px]'>
          <PageHead
            button={pageHeadData.button}
            title={pageHeadData.title}
            description={pageHeadData.description}
            main={false}
            actionUI={pageHeadData.actionUI}
            className={pageHeadData.className}
          ></PageHead>
        </div>
      )}
      {children}
    </PageHeadContext.Provider>
  );
};
