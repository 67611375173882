const NextFilled = () => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.5 11C0.5 13.0767 1.11581 15.1068 2.26957 16.8335C3.42332 18.5602 5.0632 19.906 6.98182 20.7007C8.90045 21.4955 11.0116 21.7034 13.0484 21.2982C15.0852 20.8931 16.9562 19.8931 18.4246 18.4246C19.8931 16.9562 20.8931 15.0852 21.2982 13.0484C21.7034 11.0116 21.4955 8.90045 20.7007 6.98182C19.906 5.0632 18.5602 3.42332 16.8335 2.26957C15.1068 1.11581 13.0767 0.5 11 0.5C8.21523 0.5 5.54451 1.60625 3.57538 3.57538C1.60625 5.54451 0.5 8.21523 0.5 11ZM5 10.25H14.1125L9.9275 6.04445L11 5L17 11L11 17L9.9275 15.9296L14.1125 11.75H5V10.25Z'
        fill='url(#paint0_linear_5759_120062)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_5759_120062'
          x1='11'
          y1='0.5'
          x2='11'
          y2='21.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='var(--primary-gradient-to)' />
          <stop offset='1' stopColor='var(--primary-gradient-from)' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NextFilled;
