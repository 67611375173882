import Dashboard from './Dashboard';

const disclaimerRoutes = [
  {
    id: 'disclaimerDashboard',
    index: true,
    element: <Dashboard />,
  },
];

export default disclaimerRoutes;
