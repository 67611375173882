import { CheckmarkFilled } from '@carbon/icons-react';
import { useEffect, useState } from 'react';

interface CheckMarkProps {
  className?: string;
  type?: 'success' | 'info' | 'warn' | 'error';
  size?: number;
}

const CheckMark = ({ className, type = 'info', size = 24 }: CheckMarkProps) => {
  const [color, setColor] = useState('');
  const [bgColor, setBgColor] = useState('');

  useEffect(() => {
    let colorVal = '#8493A9';
    let bgColorVal = 'neutral-4/20';
    switch (type) {
      case 'success':
        colorVal = '#078A04';
        bgColorVal = 'success-bg';
        break;
      case 'error':
        colorVal = '#EB0000';
        break;
      case 'warn':
        colorVal = '#F29E1A';
        bgColorVal = 'warning-bg';
        break;
    }
    setColor(`${colorVal}`);
    setBgColor(`bg-${bgColorVal}`);
  }, []);

  return (
    <div className={`inline-block p-2 ${bgColor} rounded-full ${className || ''}`}>
      <CheckmarkFilled size={size} color={color} />
    </div>
  );
};

export default CheckMark;
