import React, { useContext, useEffect, useState } from 'react';
import { CustomStepper } from '../../../../components';
import { PageHeadContext } from '../../../../providers/PageHead';
import { AddOrEditOrView, RequestQuickQuoteType } from '../../../../types';
import { BeneficiaryDetails } from '../BeneficiaryDetails';
import { CustomerDetails } from '../CustomerDetails';
import { QuoteAmount } from '../QuoteAmount';

interface Step {
  id: string;
  content: React.ReactNode;
}

const RequestQuickQuote = ({ mode }: { mode: AddOrEditOrView }) => {
  const [activeStep, setActiveStep] = useState<string>(RequestQuickQuoteType.QuoteAmounCurrency);
  const [visited, setVisited] = useState<Set<string>>(new Set());
  const [visitPermitted, setVisitPermitted] = useState<Set<string>>(new Set());
  const [validationOnStepper, setValidationOnStepper] = useState<Set<string>>(new Set());
  const [quoteId, setQuoteId] = useState<string>('');
  const [buyCurrency, setBuyCurrency] = useState<string>();
  const [clientId, setClientId] = useState<string>('');
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  useEffect(() => {
    setVisitPermitted(new Set([RequestQuickQuoteType.QuoteAmounCurrency]));
    setPageHeadData({
      title: 'requestQuickQuote',
      description: 'requestQuickQuoteDescription',
    });
  }, [setPageHeadData]);

  const handleStepperValidation = (formName: RequestQuickQuoteType, isValid: boolean) => {
    setValidationOnStepper((prev: Set<string>) => {
      const newValidations = new Set(prev);
      if (!isValid) {
        newValidations.add(formName);
      } else {
        newValidations.delete(formName);
      }
      return newValidations;
    });
  };

  const steps: Step[] = [
    {
      id: RequestQuickQuoteType.QuoteAmounCurrency,
      content: (
        <QuoteAmount
          onSaveAndProceed={(currency: string, id?: string) => {
            if (id) {
              setQuoteId(id);
              setBuyCurrency(currency);
              setVisitPermitted(
                new Set([...visitPermitted, RequestQuickQuoteType.QuoteAmounCurrency]),
              );
              setVisited(new Set([...visited, RequestQuickQuoteType.QuoteAmounCurrency]));
              if (
                mode === AddOrEditOrView.Add &&
                !visited.has(RequestQuickQuoteType.QuoteAmounCurrency)
              ) {
                setActiveStep(RequestQuickQuoteType.QuoteAmounCurrency);
              } else {
                setActiveStep('');
              }
            }
          }}
          handleStepperValidation={handleStepperValidation}
        />
      ),
    },
    {
      id: RequestQuickQuoteType.CustomerDetails,
      content: (
        <CustomerDetails
          quoteId={quoteId}
          onSaveAndProceed={(clientId: string) => {
            setClientId(clientId);
            setVisitPermitted(new Set([...visitPermitted, RequestQuickQuoteType.CustomerDetails]));
            setVisited(new Set([...visited, RequestQuickQuoteType.CustomerDetails]));
            if (
              mode === AddOrEditOrView.Add &&
              !visited.has(RequestQuickQuoteType.CustomerDetails)
            ) {
              setActiveStep(RequestQuickQuoteType.CustomerDetails);
            } else {
              setActiveStep('');
            }
          }}
        />
      ),
    },
    {
      id: RequestQuickQuoteType.BeneficiaryDetails,
      content: (
        <BeneficiaryDetails
          disabled={!quoteId || !buyCurrency || !clientId}
          onSaveAndProceed={() => {
            setVisitPermitted(
              new Set([...visitPermitted, RequestQuickQuoteType.BeneficiaryDetails]),
            );
            setVisited(new Set([...visited, RequestQuickQuoteType.BeneficiaryDetails]));
            if (
              mode === AddOrEditOrView.Add &&
              !visited.has(RequestQuickQuoteType.BeneficiaryDetails)
            ) {
              setActiveStep(RequestQuickQuoteType.BeneficiaryDetails);
            } else {
              setActiveStep('');
            }
          }}
          quoteId={quoteId}
          buyCurrency={buyCurrency!}
        />
      ),
    },
  ];

  return (
    <>
      <div className='pt-10 mx-auto rounded-md'>
        <CustomStepper
          steps={steps}
          activeStep={activeStep}
          visited={visited}
          validations={validationOnStepper}
        />
      </div>
    </>
  );
};

export default RequestQuickQuote;
