import { InputTextarea } from 'primereact/inputtextarea';
import { Controller } from 'react-hook-form';
import { translateWithValues } from '../../i18n/translate';

interface TextAreaProps {
  id: string;
  label: string;
  label2: string;
  placeholder?: string;
  ariaDescribedBy?: string;
  isRequired?: boolean;
  control?: any;
  rules?: any;
  name: string;
  error?: any;
  onChange?: (e: any) => void;
}

const TextArea = ({
  id,
  label,
  label2,
  placeholder,
  ariaDescribedBy,
  isRequired,
  control,
  name,
  rules,
  error,
  onChange,
}: TextAreaProps) => {
  const errorMessage =
    error &&
    (error.type === 'required'
      ? `${translateWithValues(label)} ${translateWithValues('is_required')}`
      : error.message);

  return (
    <div className='flex flex-col gap-1'>
      <label htmlFor='username'>
        {translateWithValues(label)} {isRequired ? <span className='text-error-1'>*</span> : ''}
      </label>
      {control && name ? (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <InputTextarea
              autoResize
              id={id}
              tooltip={error && errorMessage}
              tooltipOptions={{ position: 'bottom' }}
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              invalid={!!error}
              placeholder={translateWithValues(placeholder)}
              aria-describedby={ariaDescribedBy}
              rows={7}
            />
          )}
        />
      ) : (
        <InputTextarea
          autoResize
          id={id}
          placeholder={translateWithValues(placeholder)}
          aria-describedby={ariaDescribedBy}
          onChange={onChange}
          rows={7}
        />
      )}
      <label htmlFor='username'>{translateWithValues(label2)}</label>
    </div>
  );
};

export default TextArea;
