import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownInput, TextInput } from '../../../../components';
import PermissionModal from '../../../../components/PermissionModal';
import TextArea from '../../../../components/TextArea';
// import UserPermissionModal from '../../../../components/UserPermissionModal';
import { Translate } from '../../../../i18n/translate';
import { useAppDispatch } from '../../../../store';
import { selectCurrentCustomer, sendToast } from '../../../../store/slices/main/mainSlice';
import { fetchRoles, setSelectedRole } from '../../../../store/slices/role/roleSlice';
import RoleService from '../roleService';

interface CreateCustomRoleProps {
  parentCustomerId?: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onComplete: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function;
}

const CreateCustomRole = ({ onComplete, onCancel, parentCustomerId }: CreateCustomRoleProps) => {
  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<CreateRoleFormData>();
  const currentCustomer = useSelector(selectCurrentCustomer);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [isModalVisible, setModalVisible] = useState(false);
  const [primaryRoles, setPrimaryRoles] = useState<Role[]>([]);
  const [selectedRole, setRole] = useState<Role | null>(null);
  // const [isUserPermissionModalVisible, setUserPermissionModalVisible] = useState(false);

  useEffect(() => {
    fetchPrimaryUsers();
  }, []);

  const fetchPrimaryUsers = async () => {
    const roles = await RoleService.getPrimaryRoles();

    if (roles) {
      setPrimaryRoles(roles);
    }
  };

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = (rolePermissions?: RolePermission[]) => {
    if (rolePermissions) {
      createNewRole(rolePermissions);
    }

    setModalVisible(false);
  };

  const onSave = (data: any) => {
    const selectedRoleT = primaryRoles.find((p) => p.roleName === data.selectedPrimaryRole);
    if (selectedRoleT) {
      setRole(selectedRoleT);
    }
    handleOpenModal();
  };

  const createNewRole = async (rolePermissions: RolePermission[]) => {
    // call role creation API
    const submitData = [
      {
        customerId: parentCustomerId ? parentCustomerId : currentCustomer?.id,
        roleName: getValues('roleName')?.trim(),
        description: getValues('roleDescription'),
        isPrimary: false,
        rolePermissions: rolePermissions
          .map((p) => ({
            actions: p.selectedActions,
            feature: p.feature,
          }))
          .filter((e) => e.actions?.length),
      },
    ];

    const { data, status } = await RoleService.createCustomerRole(submitData as Role[]);
    if (status !== 200) {
      console.error('Error creating custom role');
      return;
    } else if (data.result.errors[0] && data.result.errors[0].errorCode == 400) {
      const { errors } = data.result;
      showErrorToast('error', (errors[0] && errors[0].errorTextCode) ?? '');
      return;
    }

    // Show toaster on success/failure
    const toastSummary = data.result.success ? 'customRoleCreated' : 'customRoleCreationFailed';
    const toastDetail = data.result.success ? 'addUserForRoleSuccess' : 'couldNotCreateRole';
    dispatch(
      sendToast({
        severity: data.result.success ? 'success' : 'error',
        summary: toastSummary,
        detail: toastDetail,
        params: { roleName: getValues('roleName') },
      }),
    );

    //handle role creation success
    if (data.result.success) {
      handleSuccess(data[0]);
      //TODO: Below commented, as we should confirm if assign users modal should show up after role creation
      // setUserPermissionModalVisible(true);
    }
  };

  const handleSuccess = (role: Role) => {
    dispatch(setSelectedRole(role));
    currentCustomer?.id && appDispatch(fetchRoles(currentCustomer.id.toString()));
    onComplete();
  };

  const showErrorToast = (summary: string, detail: string) => {
    dispatch(
      sendToast({
        severity: 'error',
        summary: summary,
        detail: detail,
      }),
    );
  };

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSave)}>
        <div className='card'>
          <div className='flex justify-between m-auto'>
            <div>
              <div className='text-xs-bold text-neutral-1'>
                <Translate value='roles.custom.title' />
              </div>
              <div className='text-neutral-3 text-md-regular'>
                <Translate value='roles.custom.description' />
              </div>
            </div>
          </div>{' '}
          <div className='mt-9 ml-14 grid grid-cols-3'>
            <DropdownInput
              label='select_primary_role_edit'
              placeholder='select_primary_role_edit'
              name='selectedPrimaryRole'
              isRequired
              control={control}
              options={primaryRoles.map((e) => ({ value: e.roleName, label: e.roleName, ...e }))}
              rules={{ required: true }}
              error={errors['selectedPrimaryRole']}
            />
          </div>
          <div className='mt-9 ml-14 grid grid-cols-3'>
            <TextInput
              id='roleName'
              label='role_name'
              placeholder='test_role_name'
              isRequired
              formRegister={register('roleName', { required: true })}
              error={errors['roleName']}
            />
          </div>
          <div className='mt-9 ml-14'>
            <TextArea
              id='roleDescription'
              label='test_role_desc_label'
              name='roleDescription'
              placeholder='test_role_description'
              label2='write_details'
              isRequired
              rules={{ required: true }}
              control={control}
              error={errors['roleDescription']}
            />
          </div>
        </div>
        <div className='flex items-center justify-center mt-10 gap-5'>
          <Button severity='contrast' className='!px-28' onClick={() => void onCancel()}>
            <Translate value='button_cancel' />
          </Button>
          <Button
            severity='info'
            className='!px-7'
            type='submit'
            onClick={() => {
              if (!isValid) {
                console.log(errors);
                showErrorToast('client.field.validation.error', 'roleCreationValidation');
                return;
              }
            }}
          >
            <Translate value='button_proceed_permission' />
          </Button>
        </div>
      </form>
      {isModalVisible && selectedRole && (
        <PermissionModal
          visible={isModalVisible}
          onHide={(data: RolePermission[]) => handleCloseModal(data)}
          role={selectedRole}
          readOnly={false}
        />
      )}
      {/* {isUserPermissionModalVisible && (
        <UserPermissionModal
          visible={isUserPermissionModalVisible}
          onHide={() => setUserPermissionModalVisible(false)}
          title='Assign users to Custom role'
        />
      )} */}
    </>
  );
};

export default CreateCustomRole;
