// ConfirmModal.tsx
import { useDispatch, useSelector } from 'react-redux';
import Confirm from '.';
import { hideModal, selectConfirmModalData } from '../../store/slices/main/confirmModalSlice';

const ConfirmModal = () => {
  const dispatch = useDispatch();
  const modalState = useSelector(selectConfirmModalData);
  const modalData = modalState.confirmModal;

  const handleConfirm = () => {
    if (modalData.onConfirm) {
      modalData.onConfirm();
    }
    dispatch(hideModal());
  };

  const handleCancel = () => {
    if (modalData.onCancel) {
      modalData.onCancel();
    }
    dispatch(hideModal());
  };

  return (
    <Confirm
      visible={modalData.visible}
      title={modalData.title ?? ''}
      message={modalData.message ?? ''}
      confirmLabel={modalData.confirmLabel}
      cancelLabel={modalData.cancelLabel}
      className={modalData.className}
      headerIcon={modalData.headerIcon}
      singleButton={modalData.singleButton}
      i18nParameters={modalData.i18nParameters}
      checkboxLabel={modalData.checkboxLabel}
      checkboxChecked={modalData.checkboxChecked}
      confirmButtonSeverify={modalData.confirmButtonSeverify}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    />
  );
};

export default ConfirmModal;
