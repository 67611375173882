/* eslint-disable @typescript-eslint/restrict-template-expressions */

const statuses: { [key: string]: StatusColor } = {
  PENDING: {
    name: 'Pending',
    backgroundColor: 'bg-sucess-bg',
    textColor: 'text-success-content',
  },
  completed: {
    name: 'Completed',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  ACTIVE: {
    name: 'Active',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  INACTIVE: {
    name: 'Inactive',
    backgroundColor: 'bg-neutral-surface-dark',
    textColor: '',
  },
  inProgress: {
    name: 'In Progress',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  failed: {
    name: 'Failed',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  PENDING_CREATION: {
    name: 'Submitted for approval',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  PENDING_APPROVAL: {
    name: 'Pending Approval',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  PENDING_RATE: {
    name: 'Pending Rate',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  EXECUTED: {
    name: 'Executed',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  SubmittingQuote: {
    name: 'Submitting quote request',
    backgroundColor: 'bg-primary-surface-light',
    textColor: 'text-primary-dark-content',
  },
  RecievingData: {
    name: 'Receiving Rate',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  DataRecieved: {
    name: 'Rate Received',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  PaymentStarted: {
    name: 'Payment Started',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  QuoteExpired: {
    name: 'Quote Failed',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
};

const Status = ({ status }: { status: string }) => {
  return (
    statuses[status] && (
      <div
        className={`rounded-[16px] text-xs-medium ${statuses[status].backgroundColor} ${statuses[status].textColor} py-[2px] px-[8px] w-max`}
      >
        {statuses[status].name}
      </div>
    )
  );
};

export default Status;
