import { DropdownProps } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';

const TRANSITIONS = {
  overlay: {
    enterFromClass: 'opacity-0 scale-75',
    enterActiveClass: 'transition-transform transition-opacity duration-150 ease-in',
    leaveActiveClass: 'transition-opacity duration-150 ease-linear',
    leaveToClass: 'opacity-0',
  },
};

const DropDownConf: any = {
  dropdown: {
    root: ({ props }: { props: DropdownProps }) => ({
      className: classNames(
        'cursor-pointer inline-flex relative select-none',
        'bg-surface-default border border-neutral-5 transition-colors duration-200 ease-in-out rounded-lg',
        'w-full',
        'hover:border-input-hover focus:outline-none focus:outline-offset-0 hover:!shadow-none focus:!shadow-none',
        { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled },
        { '!border-error-2 shadow-none': props.invalid },
        { '!bg-neutral-surface-subtle': props.disabled },
      ),
    }),
    input: ({ props }: any) => ({
      className: classNames(
        'cursor-pointer block flex flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap relative w-full',
        'bg-transparent border-0 text-neutral-3',
        'p-3 transition duration-200 bg-transparent rounded appearance-none font-sans text-base  hover:!shadow-none focus:!shadow-none',
        'focus:outline-none focus:shadow-none',
        { 'pr-7': props.showClear },
      ),
    }),
    trigger: {
      className: classNames(
        'flex items-center justify-center shrink-0',
        'bg-transparent text-gray-500 w-12 rounded-tr-lg rounded-br-lg',
      ),
    },
    wrapper: {
      className: classNames(
        'max-h-[200px] overflow-auto',
        'bg-surface-default text-gray-700 border-0 rounded-md  hover:!shadow-none focus:!shadow-none',
        'dark:bg-gray-900 dark:text-white/80',
      ),
    },
    list: 'py-3 list-none m-0',
    item: ({ context }: { context: any }) => ({
      className: classNames(
        'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
        'm-0 p-3 border-0  transition-shadow duration-200 rounded-none',
        'hover:text-gray-700 hover:bg-gray-200',
        {
          'text-gray-700': !context.focused && !context.selected,
          'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90':
            context.focused && !context.selected,
          'bg-primary-surface-light !text-sm-semibold text-neutral-3':
            context.focused && context.selected,
          'bg-primary-surface-light text-neutral-3 !text-sm-semibold':
            !context.focused && context.selected,
          'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
        },
      ),
    }),
    itemgroup: {
      className: classNames(
        'm-0 p-3 text-gray-800 bg-surface-default font-bold',
        'dark:bg-gray-900 dark:text-white/80',
        'cursor-auto',
      ),
    },
    header: {
      className: classNames(
        'p-3 bg-primary-surface-light text-neutral-3 mt-0 rounded-tl-lg rounded-tr-lg',
      ),
    },
    filtercontainer: 'relative',
    filterinput: {
      className: classNames(
        'pr-7 -mr-7',
        'w-full',
        'text-neutral-3 bg-surface-default py-3 px-3 transition duration-200 rounded-lg appearance-none',
        'hover:border-input-hover focus:outline-none focus:outline-offset-0  hover:!shadow-none focus:!shadow-none',
      ),
    },
    filtericon: '-mt-2 absolute top-1/2',
    clearicon: 'text-gray-500 right-12 -mt-2 absolute top-1/2',
    transition: TRANSITIONS.overlay,
  },
};

export default DropDownConf;
