import { Help, Settings, SidePanelClose, SidePanelOpen } from '@carbon/icons-react';
import { Ripple } from 'primereact/ripple';
import { Sidebar as PSideBar } from 'primereact/sidebar';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import useRole from '../../hooks/useRoles';
import { Translate } from '../../i18n/translate';
import {
  clearAllFilters,
  selectCurrentCustomer,
  selectOktaUser,
} from '../../store/slices/main/mainSlice';
import { CustomerType } from '../../types';
import menuItems from './menuItems';
import './sidebar.css';

interface SidebarProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MenuItem {
  key: string;
  link?: string;
  icon?: ReactElement; // For React JSX elements like icons
  children?: MenuItem[]; // Recursive type for nested menu items
}

const Sidebar: React.FC<SidebarProps> = ({ visible, setVisible }) => {
  const location = useLocation();

  const [selectedItem, setSelectedItem] = useState<MenuItem | null>(null);
  const [selectedChildItem, setSelectedChildItem] = useState<MenuItem | null>(null);
  const oktaUser = useSelector(selectOktaUser);
  const [openDropdowns, setOpenDropdowns] = useState<boolean[]>(menuItems.map(() => false));
  const { hasRole } = useRole();
  const selectedCustomer = useSelector(selectCurrentCustomer);

  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth < 768) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const checkRouteAgainstMenu = (path: string) => {
      if (path === '/') {
        setSelectedItem(menuItems[0]);
        return;
      }

      // Loop through the menu items and their children to find a match
      for (const item of menuItems.slice(1)) {
        if (item.link && path.includes(item.link)) {
          setSelectedItem(item);
          return;
        }

        if (item.children) {
          for (const child of item.children) {
            if (path.includes(child.link)) {
              setSelectedItem(item);
              setSelectedChildItem(child);
              const selectedIndex = menuItems.slice(1).findIndex((e) => e.key === item?.key);
              !openDropdowns[selectedIndex + 1] && toggleDropdown(selectedIndex + 1);
              return;
            }
          }
        }
      }
    };

    setSelectedItem(null);
    setSelectedChildItem(null);
    checkRouteAgainstMenu(location.pathname);
  }, [location.pathname]);

  const toggleSidebar = () => {
    setVisible(true);
  };

  const toggleDropdown = (index: number) => {
    setOpenDropdowns([...openDropdowns].map((e, i) => (i === index ? !e : e)));
  };

  const expandDropdown = (index: number) => {
    setOpenDropdowns([...openDropdowns].map((e, i) => (i === index ? true : e)));
  };

  const renderMenuItems = (items: typeof menuItems) =>
    items
      .map((item) => {
        let hasAccess = item.roles ? hasRole(item.roles) : true;
        if (selectedCustomer && selectedCustomer.customerType == CustomerType.ORDERING_CUSTOMER) {
          if (item.key == 'clients') {
            hasAccess = false;
          }
          if (!selectedCustomer.enableForOnline && item.key == 'users') {
            hasAccess = false;
          }
        }
        return { ...item, hasAccess };
      })
      .filter((item) => item.hasAccess)
      .map((item, index) => {
        const hasChildren = item.children && item.children.length > 0;
        const children = item.children?.filter((child) => {
          // TODDO - move to another function
          let childHasAccess = child.roles ? hasRole(child.roles) : true;
          if (selectedCustomer && selectedCustomer.customerType == CustomerType.ORDERING_CUSTOMER) {
            if (!selectedCustomer.enableForOnline && child.key == 'users') {
              childHasAccess = false;
            }
          }
          return childHasAccess;
        });

        const dropdownStyle = {
          maxHeight: openDropdowns[index] ? '500px' : '0', // Adjust '500px' based on expected content height
          overflow: 'hidden',
          transition: 'max-height 0.4s ease-in-out',
        };

        return (
          <li key={item.key}>
            {hasChildren && visible ? (
              <>
                <a
                  onClick={() => toggleDropdown(index)}
                  className={`menu-button p-ripple hover:surface-100 transition-duration-150 transition-colors ${!visible && `justify-center ${selectedItem?.key === item.key && 'menu-item-selected'}`}`}
                >
                  {item.icon}

                  <>
                    <span className='ml-2'>
                      <Translate value={item.key} />
                    </span>
                    <i
                      className={`pi ${item?.children.length ? (!openDropdowns[index] ? 'pi-angle-down' : 'pi-angle-up') : ''} ml-auto mr-1`}
                    />
                    <Ripple />
                  </>
                </a>

                <ul
                  style={dropdownStyle}
                  className={`list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out ${!visible && 'h-0'}`}
                >
                  {children?.map((child) => (
                    <li key={child.key}>
                      <Link
                        to={child.link}
                        onClick={() => {
                          if (isMobile) {
                            setVisible(false);
                          }
                          dispatch(clearAllFilters());
                        }}
                        className={`menu-button p-ripple hover:surface-100 transition-duration-150 transition-colors ${selectedChildItem?.key === child.key && 'menu-item-selected'}`}
                      >
                        <span className='ml-9 text-md-regular'>
                          <Translate value={child.key} />
                        </span>
                        <Ripple />
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <Link
                to={item.link || '#'}
                onClick={() => {
                  if (isMobile || !item.link) {
                    setVisible(!item.link);
                    expandDropdown(index);
                  }
                  dispatch(clearAllFilters());
                }}
                className={`menu-button ${!visible && 'justify-center'} ${selectedItem?.key === item.key && 'menu-item-selected'}`}
              >
                <div>{item.icon}</div>
                {visible && (
                  <>
                    <span className='ml-2'>
                      <Translate value={item.key} />
                    </span>
                    <i className={`pi ${hasChildren ? 'pi-angle-down' : ''} ml-auto mr-1`} />
                  </>
                )}
              </Link>
            )}
          </li>
        );
      });

  return (
    <PSideBar
      modal={false}
      dismissable={false}
      closeOnEscape={false}
      blockScroll={true}
      visible={true}
      position='left'
      onHide={() => {
        setVisible(false);
      }}
      pt={{
        mask: {
          className: visible ? '!w-[312px]' : '!w-24',
        },
      }}
      className={visible ? '!w-[312px]' : '!w-24'}
      content={() => (
        <div
          className={`flex flex-col min-h-screen bg-primary-dark py-[32px] text-primary-surface-dark-content ${visible ? 'px-[24px]' : 'px-[16px]'}`}
        >
          {visible ? (
            <div className='flex justify-between'>
              <div>
                <div
                  id='clientLogo'
                  style={{ width: '166.67px', height: '20px' }}
                  className='client-logo bg-contain  bg-no-repeat'
                />
              </div>

              <div
                className='cursor-pointer'
                onClick={() => {
                  setVisible(false);
                }}
              >
                <SidePanelClose size={20} />
              </div>
            </div>
          ) : (
            <>
              <div className='flex flex-col items-center gap-9'>
                <div
                  id='clientLogoCollapsed'
                  className='client-logo-collapsed bg-contain bg-no-repeat'
                  style={{ width: '25px', height: '22px' }}
                ></div>

                <div onClick={toggleSidebar} className='cursor-pointer'>
                  <SidePanelOpen size={24} />
                </div>
              </div>
            </>
          )}

          <div className='flex-grow flex flex-col justify-between border-b border-menu-border'>
            <ul className='list-none m-0 pt-8 overflow-hidden text-md-semibold'>
              {renderMenuItems(menuItems)}
            </ul>
            <ul className='list-none m-0 pt-8 overflow-hidden text-primary-surface-dark-content text-md-semibold pb-5'>
              <li>
                <a className={`menu-button ${!visible && 'justify-center'}`}>
                  <Help size={24} />
                  {visible && (
                    <span className='ml-2'>
                      <Translate value='support' />
                    </span>
                  )}
                </a>
              </li>
              <li>
                <a className={`menu-button ${!visible && 'justify-center'}`}>
                  <Settings size={24} />
                  {visible && (
                    <span className='ml-2'>
                      <Translate value='settings' />
                    </span>
                  )}
                </a>
              </li>
            </ul>
          </div>
          <div className={`flex align-center pt-10 gap-2 ${!visible && 'justify-center'}`}>
            <div>
              <img
                src='/images/profile_image_placeholder.jpg'
                className='w-10 h-10 rounded-full border border-white'
              />
            </div>
            {visible && (
              <div className='text-sm'>
                <div className='font-bold'>{oktaUser?.name}</div>
                <div className='font-light'>{oktaUser?.email}</div>
              </div>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default Sidebar;
