import React, { useCallback, useEffect, useRef, useState } from 'react';
import oktaAuth from '../../auth/Okta';
import UserprincipalService from '../../modules/UserPrinciple/userPrinicple';
import Confirm from '../Confirm';
import WarningIcon from '../WarningIcon';

interface InactivityTimeoutProps {
  warningDuration?: number;
  timeoutDuration?: number;
}

const InactivityTimeout: React.FC<InactivityTimeoutProps> = ({
  timeoutDuration = 10 * 60 * 1000,
  warningDuration = 60 * 1000,
}) => {
  const [remainingTime, setRemainingTime] = useState<number>(timeoutDuration);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const resetTimer = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    setRemainingTime(timeoutDuration);
    setShowWarning(false);
    startTimeout();
  }, [timeoutDuration]);

  const startTimeout = () => {
    timeoutIdRef.current = setTimeout(() => {
      setShowWarning(true);
    }, timeoutDuration - warningDuration);
  };

  const handleExtendSession = () => {
    resetTimer();
  };

  const handleSessionTimeout = () => {
    oktaAuth
      .signOut({
        postLogoutRedirectUri: window.location.origin + '/logout/callback',
      })
      .then(() => {
        UserprincipalService.signOut().then(async () => {
          await oktaAuth.closeSession();
          oktaAuth.tokenManager.clear();
        });
      });
  };

  const addActivityListeners = useCallback(() => {
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
  }, [resetTimer]);

  const removeActivityListeners = useCallback(() => {
    window.removeEventListener('mousemove', resetTimer);
    window.removeEventListener('keydown', resetTimer);
  }, [resetTimer]);

  useEffect(() => {
    addActivityListeners();
    startTimeout();

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      removeActivityListeners();
    };
  }, [addActivityListeners, removeActivityListeners]);

  useEffect(() => {
    if (showWarning) {
      removeActivityListeners();

      const countdown = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1000);
      }, 1000);

      const timeoutId = setTimeout(() => {
        handleSessionTimeout();
      }, warningDuration);

      return () => {
        clearInterval(countdown);
        clearTimeout(timeoutId);
      };
    } else {
      addActivityListeners();
    }
  }, [showWarning, warningDuration, removeActivityListeners, addActivityListeners]);

  return (
    <>
      {showWarning && (
        <Confirm
          confirmLabel='Extend Session'
          cancelLabel='Log Out'
          title='session.timeout.warning.title'
          message='session.timeout.warning.description'
          i18nParameters={{ remainingTime: `${remainingTime / 1000}` }}
          onConfirm={handleExtendSession}
          onCancel={handleSessionTimeout}
          visible={showWarning}
          className='session-warning-modal'
          headerIcon={<WarningIcon />}
          confirmButtonSeverify='info'
        />
      )}
    </>
  );
};

export default InactivityTimeout;
