import { Filter } from '@carbon/icons-react';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import { CurrencyFlag, PaginatorTemplate, Status, TableSearch } from '../../../../components';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Download } from '../../../../icons';
import { useQuery } from '@tanstack/react-query';
import PaymentService from '../../paymentService';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

interface PaymentsTable {
  totalRecords: number;
  globalSearch: string;
  setGlobalSearch: (globalSearch: string) => void;
}

const PaymentsTable = () => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [globalSearch, setGlobalSearch] = useState('');
  const [activeFilterCount] = useState(0);
  const [selectedRates, setSelectedRates] = useState<RateRequest[] | null>(null);

  const rateRequestQuery = useQuery({
    queryKey: ['rate_requests_query'],
    queryFn: async () => {
      const data = await PaymentService.getRateRequests();
      setTotalRecords(data.length);
      return data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const header = (
    <div className='flex flex-col md:flex-row gap-3 justify-between'>
      <div className='text-neutral-1 text-lg-semibold'>
        <Translate value='payment_requests' />
        <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
          {totalRecords}
        </span>
      </div>
      <div className='flex justify-around gap-2 items-center'>
        <TableSearch
          globalFilterValue={globalSearch}
          onGlobalFilterChange={(e) => {
            setGlobalSearch(e.target.value);
          }}
        />
        <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
          <Button severity='secondary' className='p-button-text h-12'>
            <Filter size={20} />
            <Translate value='filterLabel' className='ml-1 hidden lg:block' />
          </Button>
          {!!activeFilterCount && (
            <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
          )}
        </div>
        <div>
          <Button severity='contrast' className='h-12'>
            <Download /> <Translate value='downloadLabel' className='ml-1 hidden lg:block' />
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <DataTable
      className='mt-5'
      header={header}
      selectAll={true}
      selectionMode={'checkbox'}
      selection={selectedRates!}
      onSelectionChange={(e: { value: RateRequest[] }) => setSelectedRates(e.value)}
      dataKey='paymentId'
      first={0}
      rows={10}
      value={rateRequestQuery.data}
      paginator={true}
      paginatorTemplate={PaginatorTemplate}
      totalRecords={totalRecords}
    >
      <Column selectionMode='multiple' headerStyle={{ width: '1rem' }}></Column>
      <Column field='paymentId' header={translateWithValues('payment_id')} />
      <Column
        field='buyCurrency'
        header={translateWithValues('buy_currency')}
        body={(rateRequest: RateRequest) => (
          <div className='text-sm-medium text-neutral-2'>
            <CurrencyFlag currencyCode={rateRequest.buyCurrency} />
          </div>
        )}
      />
      <Column field='buyAmount' header={translateWithValues('buy_amount')} />
      <Column
        field='sellCurrency'
        header={translateWithValues('sell_currency')}
        body={(rateRequest: RateRequest) => (
          <div className='text-sm-medium text-neutral-2'>
            <CurrencyFlag currencyCode={rateRequest.sellCurrency} />
          </div>
        )}
      />
      <Column field='valueDate' header={translateWithValues('Value Date')} />
      <Column
        field='status'
        header={translateWithValues('status')}
        body={(rateRequest: RateRequest) => <Status status={rateRequest.status} />}
      />
    </DataTable>
  );
};

export default PaymentsTable;
