import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Translate } from '../../i18n/translate';
import { useAppDispatch } from '../../store';
import {
  selectCurrentCustomer,
  selectOwningCustomers,
  setCurrentCustomer,
} from '../../store/slices/main/mainSlice';

const CustomerSelectionDialog = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<Client | null>(null);
  const owningCustomers = useSelector(selectOwningCustomers);
  const selectedCurrentCustomer = useSelector(selectCurrentCustomer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (owningCustomers.length > 1) {
      setSelectedCustomer(owningCustomers[0]);
    } else {
      dispatch(setCurrentCustomer(owningCustomers[0]));
    }
  }, [owningCustomers]);

  const handleCustomerSelect = (customer: Client) => {
    setSelectedCustomer(customer);
    dispatch(setCurrentCustomer(customer));
  };

  return (
    <>
      {owningCustomers.length > 1 ? (
        <Dialog
          draggable={false}
          className='rounded-[12px] confirm-dialog w-[500px]'
          visible={!selectedCurrentCustomer}
          header={<Translate value='selectCustomer' />}
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          closable={false}
          onHide={() => {
            if (!selectedCustomer) {
              setSelectedCustomer(null);
            }
          }}
        >
          <div className='customer-list mt-1'>
            <Translate value='mulipleCustomerSelection' />
            {owningCustomers.map((customer) => (
              <Card
                key={customer.id}
                className={`pointer customer-card ${selectedCustomer?.id === customer.id ? 'selected' : ''}`}
                onClick={() => handleCustomerSelect(customer)}
                title={customer.customerShortName}
              ></Card>
            ))}
          </div>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomerSelectionDialog;
