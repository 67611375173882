import { ChevronLeft, ChevronRight, PageFirst, PageLast } from '@carbon/icons-react';
import { PickList, PickListChangeEvent } from 'primereact/picklist';
import React from 'react';
import './currencyPicklist.scss';
import { Tooltip } from 'primereact/tooltip';
import { translateWithValues } from '../../i18n/translate';

interface CurrencyPickListProps {
  source: CurrencyConfiguration[];
  target: CurrencyConfiguration[];
  onChange: (event: any) => void;
  sourceHeader?: string;
  destinationHeader?: string;
  hasEditPermission?: boolean;
}

const CurrencyPickList: React.FC<CurrencyPickListProps> = ({
  source,
  target,
  onChange,
  sourceHeader,
  destinationHeader,
  hasEditPermission = true,
}) => {
  const onCurrencyChange = (event: PickListChangeEvent) => {
    onChange(event);
  };

  const itemTemplate = (option: CurrencyConfiguration) => {
    return (
      <div className='flex align-items-center'>
        <img
          alt={option.isocode || ''}
          src={`/images/c_flags_svg/${option.isocode?.toLowerCase()}.svg`}
          className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
        />
        <div className='overflow-hidden overflow-ellipsis'>{option.isocode}</div>
      </div>
    );
  };

  return (
    <PickList
      className='custom-currency-picklist'
      dataKey='isocode'
      source={source}
      target={target}
      filter
      filterBy='isocode'
      itemTemplate={itemTemplate}
      sourceHeader={sourceHeader || 'Available Currencies'}
      targetHeader={destinationHeader || 'Selected Currencies'}
      sourceStyle={{ height: '300px' }}
      targetStyle={{ height: '300px' }}
      showSourceControls={false}
      showTargetControls={false}
      onChange={onCurrencyChange}
      moveAllToTargetIcon={
        <>
          <Tooltip
            content={translateWithValues('select_all')}
            target={'.p-picklist-transfer-buttons .p-button:nth-child(2)'}
            position='top'
          />
          {hasEditPermission && <PageLast className='select-all-button' size={24} />}
        </>
      }
      moveToTargetIcon={
        <>
          <Tooltip
            content={translateWithValues('select')}
            target={'.p-picklist-transfer-buttons .p-button:nth-child(1)'}
            position='top'
          />
          {hasEditPermission && <ChevronRight size={24} />}
        </>
      }
      moveToSourceIcon={
        <>
          <Tooltip
            content={translateWithValues('deselect')}
            target={'.p-picklist-transfer-buttons .p-button:nth-child(3)'}
            position='top'
          />
          {hasEditPermission && <ChevronLeft size={24} />}
        </>
      }
      moveAllToSourceIcon={
        <>
          <Tooltip
            content={translateWithValues('deselect_all')}
            target={'.p-picklist-transfer-buttons .p-button:nth-child(4)'}
            position='top'
          />
          {hasEditPermission && <PageFirst size={24} />}
        </>
      }
    />
  );
};

export default CurrencyPickList;
