import { RoleGuard } from '../../components';
import { RouteRoles } from '../../RouteRoles';
import CurrencyOutlet from './Currency';
import currencyRoutes from './Currency/routes';
import DisclaimerOutlet from './Disclaimer';
import disclaimerRoutes from './Disclaimer/routes';
import EmailSettingsOutlet from './EmailSettings';
import emailSettingsRoutes from './EmailSettings/routes';
import ManagedPricingOutlet from './ManagePricing';
import managedPricingRoutes from './ManagePricing/routes';

const configurationRoutes = [
  {
    id: 'currency',
    path: 'currency',
    handle: {
      breadcrumb: 'Currency',
    },
    element: (
      <RoleGuard roles={RouteRoles.Currency}>
        <CurrencyOutlet />
      </RoleGuard>
    ),
    children: currencyRoutes,
  },
  {
    id: 'managed-pricing',
    path: 'managed-pricing',
    handle: {
      breadcrumb: 'Managed Pricing',
    },
    element: (
      <RoleGuard roles={RouteRoles.ManagedPricing}>
        <ManagedPricingOutlet />
      </RoleGuard>
    ),
    children: managedPricingRoutes,
  },
  {
    id: 'disclaimer',
    path: 'disclaimer',
    handle: {
      breadcrumb: 'Disclaimer',
    },
    element: (
      <RoleGuard roles={RouteRoles.configuration}>
        <DisclaimerOutlet />
      </RoleGuard>
    ),
    children: disclaimerRoutes,
  },
  {
    id: 'emailSettings',
    path: 'email-settings',
    handle: {
      breadcrumb: 'Email Settings',
    },
    element: (
      <RoleGuard roles={RouteRoles.configuration}>
        <EmailSettingsOutlet />
      </RoleGuard>
    ),
    children: emailSettingsRoutes,
  },
];

export default configurationRoutes;
