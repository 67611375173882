import { Checkmark } from '@carbon/icons-react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';

interface PermissionCardProps {
  permission: RolePermission;
  selectedPermissions: RolePermission[];
  onHeaderChange: (e: CheckboxChangeEvent, headerName: string) => void;
  onPermissionChange: (e: CheckboxChangeEvent, permissionName: string, action: string) => void;
  readOnly?: boolean;
}

const PermissionCard = ({
  permission,
  selectedPermissions,
  onHeaderChange,
  onPermissionChange,
  readOnly,
}: PermissionCardProps) => {
  return (
    <div className='card flex flex-col w-[21rem]'>
      <div className='flex items-center mb-6 rounded bg-primary-surface-light p-2'>
        {!readOnly ? (
          <Checkbox
            inputId={`header-${permission.feature}`}
            checked={permission.actions.length === permission?.selectedActions?.length}
            onChange={(e) => onHeaderChange(e, permission.feature)}
          />
        ) : (
          <Checkmark fill='var(--checkmark-fill)' />
        )}
        <label htmlFor={`header-${permission.feature}`} className='ml-2 font-semibold'>
          {permission.feature}
        </label>
      </div>
      <div className='p-2 flex flex-col gap-6'>
        {permission.actions.map((action, actionIndex) => (
          <div key={actionIndex} className='flex items-center gap-3'>
            {!readOnly ? (
              <Checkbox
                inputId={`${permission.feature}-${action}`}
                checked={
                  selectedPermissions
                    .find((e) => e.feature === permission.feature)
                    ?.selectedActions?.includes(action) ?? false
                }
                onChange={(e) => onPermissionChange(e, permission.feature, action)}
              />
            ) : (
              <Checkmark fill='var(--checkmark-fill)' />
            )}
            <label htmlFor={`${permission.feature}-${action}`} className='ml-2 font-sm-semibold'>
              {action}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PermissionCard;
