import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Translate, TranslateWithValues } from '../../i18n/translate';
// import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendToast } from '../../store/slices/main/mainSlice';
import PermissionCard from './PermissionCard';

interface PermissionModalProps {
  visible: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onHide: Function;
  role: Role;
  readOnly?: boolean;
}

const PermissionModal = ({ visible, onHide, role, readOnly = true }: PermissionModalProps) => {
  const [renderedPermissions, setRenderedPermissions] = useState<RolePermission[]>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const allSelected = renderedPermissions.every(
      (p) => p.actions.length === p.selectedActions?.length,
    );
    setSelectedAll(allSelected);
  }, [renderedPermissions]);

  useEffect(() => {
    if (role.rolePermissions.length > 0) {
      setRenderedPermissions(
        role.rolePermissions.map((e) => ({ ...e, selectedActions: readOnly ? e.actions : [] })),
      );
    }
  }, [role.rolePermissions]);

  const onSelectAllChange = (e: CheckboxChangeEvent) => {
    const checked = e.checked ?? false;
    setSelectedAll(checked);

    const allPermissions = renderedPermissions.flatMap((p) => ({
      ...p,
      selectedActions: checked ? p.actions : [],
    }));
    setRenderedPermissions(allPermissions);
  };

  const onHeaderChange = (e: CheckboxChangeEvent, headerName: string) => {
    const checked = e.checked ?? false;
    const allPermissions = renderedPermissions.flatMap((p) =>
      headerName === p.feature
        ? {
            ...p,
            selectedActions: checked ? p.actions : [],
          }
        : p,
    );

    setRenderedPermissions(allPermissions);
  };

  const onPermissionChange = (e: CheckboxChangeEvent, permissionName: string, action: string) => {
    const checked = e.checked;

    const allPermissions = renderedPermissions.flatMap((p) => {
      if (permissionName === p.feature) {
        return {
          ...p,
          selectedActions: checked
            ? p.selectedActions?.concat([action])
            : p.selectedActions?.filter((e) => e !== action),
        };
      }
      return p;
    });

    setRenderedPermissions(allPermissions);
  };

  const showErrorToast = () => {
    dispatch(
      sendToast({
        severity: 'error',
        summary: 'error',
        detail: 'permissionValidation',
      }),
    );
  };

  const onSave = () => {
    const isSelected = renderedPermissions.some((permission) => {
      if (permission.selectedActions) return permission.selectedActions.length > 0;
    });
    if (!isSelected) showErrorToast();
    else onHide(renderedPermissions);
  };

  const headerElement = () => (
    <div className='flex justify-between items-center w-full p-4'>
      <div className='flex flex-col gap-1'>
        <div className='text-lg font-semibold text-neutral-1'>
          <TranslateWithValues
            value='permission_modal_header'
            params={{ roleName: role.roleName }}
          />
        </div>
        <div className='text-sm font-regular text-neutral-3'>
          <Translate value='permission_modal_desc' />
        </div>
      </div>
    </div>
  );

  const footerContent = (
    <div className='flex justify-center mt-5 gap-2'>
      <Button severity={readOnly ? 'info' : 'contrast'} onClick={() => onHide()}>
        <Translate value='return' />
      </Button>
      {!readOnly && (
        <Button severity='info' onClick={() => onSave()}>
          <Translate value='assign_permission' />
        </Button>
      )}
    </div>
  );

  return (
    <>
      <Dialog
        className='scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
        header={headerElement}
        visible={visible}
        style={{ width: '75vw', height: '100vh', position: 'fixed', top: 0, right: 0 }}
        onHide={() => onHide(renderedPermissions)}
        footer={footerContent}
        draggable={false}
      >
        <div className='ml-12 !no-scrollbar'>
          <div className='mb-8 flex items-center'>
            {!readOnly && (
              <>
                <Checkbox onChange={onSelectAllChange} checked={selectedAll}></Checkbox>
                <label htmlFor='selectAll' className='ml-2'>
                  <Translate value='select_permission' />
                </label>
              </>
            )}
          </div>
          <div className='grid grid-cols-3 gap-3 mb-4'>
            {renderedPermissions.map((permission, index) => (
              <PermissionCard
                key={index}
                permission={permission}
                selectedPermissions={renderedPermissions}
                onHeaderChange={onHeaderChange}
                onPermissionChange={onPermissionChange}
                readOnly={readOnly}
              />
            ))}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PermissionModal;
