import {
  ButtonConf,
  DropDownConf,
  InputSwitchConf,
  InputTextConf,
  SidebarConf,
} from '../../components';
import InputTextAreaConf from '../../components/TextArea/tailwind.config';

const Custom = {
  ...ButtonConf,
  ...SidebarConf,
  ...InputTextConf,
  ...DropDownConf,
  ...InputSwitchConf,
  ...InputTextAreaConf,
};

export default Custom;
