import { cn } from '../../../../utils/helper';

interface UserCardProps {
  user: User;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onRemoveClick: Function;
  hasEditRole: boolean;
  isBeingRemovedUser: boolean;
}

const RoleUserCard = ({ user, onRemoveClick, hasEditRole, isBeingRemovedUser }: UserCardProps) => {
  return (
    <div
      className={cn(
        'card flex items-center justify-between w-[300px] break-all',
        isBeingRemovedUser ? 'opacity-40' : '',
      )}
    >
      <div className='flex items-center pr-3'>
        <div className='mr-4'>
          <div className='bg-gray-200 rounded-full h-12 w-12 flex items-center justify-center'>
            <img
              src='/images/profile_image_placeholder.jpg'
              className='rounded-full border border-white'
            />
          </div>
        </div>
        <div>
          <h3 className='text-sm-medium font-semibold'>
            {user.userIdentity.firstName} {user.userIdentity.lastName}
          </h3>
          <p className='text-neutral-3 text-sm-regular'>{user.userIdentity.email}</p>
        </div>
      </div>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-return */}
      {hasEditRole && (
        <button disabled={isBeingRemovedUser} onClick={() => onRemoveClick(user.id)}>
          ✕
        </button>
      )}
    </div>
  );
};

export default RoleUserCard;
