import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import InfoTemplate from '../../../../components/InfoTemplate';
import { selectCustomRoles } from '../../../../store/slices/role/roleSlice';
import rolePermission from '../rolePermission';
import CreateCustomRole from './CreateCustomRole';
import RolesTable from './RolesTable';

const CustomRoles = () => {
  const navigate = useNavigate();
  const customRoles = useSelector(selectCustomRoles);
  const [createCustomRole, setCreateCustomRole] = useState(false);

  useEffect(() => {
    // Create an instance of URLSearchParams to extract query parameters
    const searchParams = new URLSearchParams(location.search);

    // If create is true, show the create custom role component
    if (searchParams.get('create') === 'true') {
      setCreateCustomRole(true);
    } else {
      setCreateCustomRole(false);
    }
  }, [location.search]);

  const handleRoleCreationComplete = () => {
    navigate('/manage/role?type=custom');
  };

  const showCustomRoleTableView = () => {
    navigate('/manage/role?type=custom');
  };

  return (
    <>
      {!createCustomRole ? (
        <>
          <div className='mt-5'>
            <InfoTemplate
              titleKey='roles.primary.new.info.title'
              descriptionKey='roles.primary.new.info.desc'
              buttonKey='roles.primary.new.info.button.title'
              btnAction={() => navigate('/manage/role?type=custom&create=true')}
              roles={rolePermission.AddRole}
            />
          </div>
          <RolesTable
            title='customeUserTableHead'
            description='customUserTableDesc'
            data={customRoles}
          />
        </>
      ) : (
        <CreateCustomRole
          onComplete={handleRoleCreationComplete}
          onCancel={showCustomRoleTableView}
        />
      )}
    </>
  );
};

export default CustomRoles;
