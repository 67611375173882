import { SortOrder } from 'primereact/datatable';
import { Status } from '../../types';
import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';

const ROOT = '/api/user-management/users';
const ROLE_PATH = 'api/customer-role-management';
const CUSTOMER_ROLES = '/customer-roles/';
const USER_ROLES = '/user-roles/';
const ASSIGN_ROLES = '/users/assign-roles';

const UserService = {
  async getUsers(customerId: string): Promise<User[]> {
    try {
      const response = await apiClient.get(`${ROOT}/${customerId}`);
      return handleApiResponse<User[]>(response);
    } catch (error) {
      console.error('Error fetching users:', error);
      return [];
    }
  },

  async getCustomerUser(
    customerId: number,
    page?: number,
    rows?: number,
    payload: { [key: string]: string[] | string } = {},
    sortOrder: SortOrder = 0,
    sortField = '',
  ): Promise<UsersResponse | null> {
    try {
      const response = await apiClient.post(
        `${ROOT}/customers/${customerId}?page=${page}&size=${rows}&sortBy=${sortField}&direction=${sortOrder === 1 ? 'asc' : 'desc'}`,
        payload,
      );
      return handleApiResponse<UsersResponse>(response);
    } catch (error) {
      console.error('Error fetching users:', error);
      return null;
    }
  },
  async getApprovalList(
    customerId?: string,
    page?: number,
    rows?: number,
    payload: { [key: string]: string[] | string } = {},
    sortOrder: SortOrder = 0,
    search = '',
  ): Promise<UsersResponse | null> {
    try {
      const response = await apiClient.get(
        `${ROOT}/pending-approval?search=${search}&page=${page}&size=${rows}&direction=${sortOrder === 1 ? 'asc' : 'desc'}${customerId ? `&itemCustomerId=${customerId}` : ''}`,
        payload,
      );
      return handleApiResponse<UsersResponse>(response);
    } catch (error) {
      console.error('Error fetching users:', error);
      return null;
    }
  },
  async getUserById(id: string): Promise<User | null> {
    try {
      const response = await apiClient.get(`${ROOT}/${id}`);
      return handleApiResponse<User | null>(response);
    } catch (error) {
      console.error(`Error fetching user with id ${id}:`, error);
      return null;
    }
  },

  async updateStatus(id: number, status: Status): Promise<User | null> {
    try {
      const response = await apiClient.put(`${ROOT}/${id}/status?status=${status}`);
      return handleApiResponse<User | null>(response);
    } catch (error) {
      console.error(`Error fetching user with id ${id}:`, error);
      return null;
    }
  },

  approveOrRject(userId: number, data: any) {
    return apiClient.post(`${ROOT}/${userId}/approve`, data);
  },

  async createUser(user: SubmitUserData[]) {
    return apiClient.post(`${ROOT}`, user);
  },

  async getRoleByCustomerId(id: number): Promise<any> {
    try {
      const response = await apiClient.get(`${ROLE_PATH}${CUSTOMER_ROLES}${id}`);
      return handleApiResponse<any>(response);
    } catch (error) {
      console.error(`Error fetching user with id ${id}:`, error);
      return null;
    }
  },

  async getRoleByUserId(id: string | undefined): Promise<any> {
    try {
      const response = await apiClient.get(`${ROLE_PATH}${USER_ROLES}${id}`);
      return handleApiResponse<any>(response);
    } catch (error) {
      console.error(`Error fetching user with id ${id}:`, error);
      return null;
    }
  },

  async assignRolesToUser(data: any, isAobo: boolean) {
    try {
      const response = await apiClient.post(
        !isAobo ? `${ROLE_PATH}${ASSIGN_ROLES}` : `${ROLE_PATH}${ASSIGN_ROLES}/aobo`,
        data,
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching currency:', error);
      return [];
    }
  },

  resetMFA(userId: number) {
    return apiClient.get(`${ROOT}/${userId}/reset-factors`);
  },

  resetPassword(userId: number) {
    return apiClient.get(`${ROOT}/${userId}/reset-password`);
  },

  blockUser(userId: number) {
    return apiClient.put(`${ROOT}/${userId}/block-user`);
  },

  unBlockUser(userId: number) {
    return apiClient.put(`${ROOT}/${userId}/unblock-user`);
  },

  getById(id: string) {
    return apiClient.get(`${ROOT}/${id}`);
  },

  delete(id: number) {
    return apiClient.delete(`${ROOT}/${id}`);
  },

  getApprovalItemDetails(recordId: string) {
    return apiClient.get(
      `/api/approval-management/approvals/feature/MANAGE_USER/pending-item/${recordId}`,
    );
  },

  async downloadUser(customerId: number, payload: { [key: string]: string[] | string }) {
    try {
      const response = await apiClient.post(
        `${ROOT}/customers/${customerId}/download/csv`,
        payload,
        {
          responseType: 'blob',
        },
      );
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'customer_data.csv'); // File name for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); //
    } catch (error) {
      console.error('Error fetching client with id', error);
      return null;
    }
  },
};

export default UserService;
