import { InputTextProps } from 'primereact/inputtext';
import { cn } from '../../utils/helper';

const InputTextConf: any = {
  inputtext: {
    root: ({ props, context }: { props: InputTextProps; context: any }) => ({
      className: cn(
        props.className,
        'm-0',
        'pl-5',
        'text-neutral-3 bg-surface-default border border-neutral-5 transition-colors duration-200 appearance-none rounded-c8 shadow-input px-[10px] py-[10px] px-[14px]',
        {
          'hover:border-input-hover  focus:outline-none focus:border-input-hover':
            !context.disabled && !props.invalid,
          'opacity-60 select-none pointer-events-none cursor-default !bg-neutral-surface-subtle':
            context.disabled,
          '!border-error-2': props.invalid,
        },
        {
          'text-lg px-4 py-4': props.size == 'large',
          'text-xs px-2 py-2': props.size == 'small',
          'p-3 text-base': props.size == null,
        },
      ),
    }),
  },
};

export default InputTextConf;
