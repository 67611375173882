import { CheckmarkOutline, CloseOutline, Edit, Filter, TrashCan, View } from '@carbon/icons-react';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTablePageEvent, DataTableSortEvent } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import {
  Confirm,
  InheritedMenu,
  PaginatorTemplate,
  Status as StatusComp,
  TableSearch,
  WarningIcon,
} from '../../../../components';
import FilterModal from '../../../../components/FilterModal';
import { Translate } from '../../../../i18n/translate';
import { Download, MoreAction } from '../../../../icons';
import { useAppDispatch } from '../../../../store';
import {
  selectCurrentCustomer,
  sendToast,
  selectFilters,
  updateFilter,
} from '../../../../store/slices/main/mainSlice';
import { selectCustomerCurrencies } from '../../../../store/slices/refdata/refdataSlice';
import { MarginType, Status } from '../../../../types';
import { enumToCamelCase } from '../../../../utils/helper';
import ManagePricingService from '../managePricingService';
import managePricingRole from '../managePricingRole';
import { STATUS_OPTIONS } from '../../configurations-constants';
import { MARGIN_TYPE_OPTIONS } from './formConstants';

const Margins = ({
  isCustomerPricing,
  client,
  refreshData,
  onEdit,
  onView,
}: {
  isCustomerPricing: boolean;
  client: Client | undefined;
  refreshData: boolean;
  onEdit: (margin: Margin) => void;
  onView: (margin: Margin) => void;
}) => {
  const [margins, setMargins] = useState<Margin[]>([]);
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [currentActionMargin, setCurrentActionMargin] = useState<Margin | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [actionItems, setActionItems] = useState<MenuItem[]>([]);
  const filters = useSelector(selectFilters)['margins'];
  const [filterValues, setFilterValues] = useState<any>();
  const [activeFilterCount, setActiveFilterCount] = useState(
    filters ? Object.keys(filters).length : 0,
  );
  const [isModalVisible, setModalVisible] = useState(false);
  const [globalSearch, setGlobalSearch] = useState('');
  const [debouncedSearch] = useDebounce(globalSearch, 300);
  const currencies = useSelector(selectCustomerCurrencies);
  const dispatch = useAppDispatch();
  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [debouncedPage] = useDebounce(pageParams, 300);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortParams, setSortParams] = useState<SortParams>({
    sortField: '',
    sortOrder: 0,
  });

  const menu = useRef<Menu>(null);
  useEffect(() => {
    getMargins(pageParams);
  }, [debouncedPage, debouncedSearch, filters, sortParams]);

  useEffect(() => {
    if (refreshData) {
      getMargins(pageParams);
    }
  }, [refreshData]);

  const getMargins = async (params: PageParams) => {
    setLoading(true);
    if (selectedCustomer?.id) {
      try {
        const { page, rows } = params;
        const payload = {
          searchText: globalSearch,
          ...filters,
        };
        const data = await ManagePricingService.getCustomerMargins(
          isCustomerPricing && client && client.id ? client.id : selectedCustomer.id,
          page,
          rows,
          payload,
          sortParams,
        );
        if (data) {
          const { content, totalElements } = data;
          setMargins(content);
          setTotalRecords(totalElements);
        } else {
          setMargins([]);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const generateFilterMetaData = () => {
    const _currencies: Currency[] = currencies.reduce((filteredCurrencies, currency) => {
      if (
        currency.isocode !== 'ALL' &&
        !filteredCurrencies.some((item) => item.value === currency.isocode)
      ) {
        filteredCurrencies.push({
          label: currency.isocode,
          value: currency.isocode,
        });
      }
      return filteredCurrencies;
    }, [] as Currency[]);
    return [
      {
        header: 'By Type',
        fields: [
          {
            name: 'type',
            placeholder: 'Select Margin type',
            type: 'single',
            value: filters?.type || '',
            options: MARGIN_TYPE_OPTIONS,
          },
        ],
      },
      {
        header: 'By Currency',
        fields: [
          {
            name: 'currency',
            type: 'currency',
            placeholder: 'Select Currency',
            value: filters?.currency || [],
            options: _currencies,
          },
        ],
      },
      {
        header: 'By Status',
        fields: [
          {
            name: 'status',
            type: 'single',
            placeholder: 'Select Status',
            options: STATUS_OPTIONS,
            value: filters?.status || '',
          },
        ],
      },
    ];
  };

  useEffect(() => {
    const filterMetaData = generateFilterMetaData();
    setFilterValues(filterMetaData);
  }, [currencies]);

  const activateOrDeactivate = async (margin: Margin, _status: Status) => {
    if (margin && margin.id) {
      const { status, data } = await ManagePricingService.updateMarginStatus(margin.id, _status);
      if (status != 200 || data.result.success === false) {
        showToast(
          'success',
          _status == Status.ACTIVE
            ? 'margin.activate.error.toast.title'
            : 'margin.deactivate.error.toast.title',
          '',
          {
            marginType: margin.marginType,
          },
        );
        return;
      } else {
        showToast(
          'success',
          _status == Status.ACTIVE
            ? 'margin.activate.success.toast.title'
            : 'margin.deactivate.success.toast.title',
          '',
          {
            marginType: margin.marginType,
          },
        );
      }
      getMargins(pageParams);
    }
  };

  const actionBodyTemplate = (margin: Margin) => {
    const items: MenuItem[] = [
      {
        label: 'View',
        icon: <View />,
        onClick: () => {
          if (currentActionMargin) onView(margin);
        },
        roles: managePricingRole.ViewManagePricing,
      },
      {
        label: 'Edit',
        icon: <Edit />,
        onClick: () => {
          if (currentActionMargin) onEdit(margin);
        },
        roles: managePricingRole.EditManagePricing,
      },
      {
        label: 'activate',
        icon: <CheckmarkOutline />,
        onClick: () => {
          activateOrDeactivate(margin, Status.ACTIVE);
        },
        roles: managePricingRole.EditManagePricing,
      },
      {
        label: 'deactivate',
        icon: <CloseOutline />,
        onClick: () => {
          activateOrDeactivate(margin, Status.INACTIVE);
        },
        roles: managePricingRole.EditManagePricing,
      },
      {
        label: 'delete',
        icon: <TrashCan />,
        onClick: () => {
          setShowDeleteDialog(true);
        },
        className: 'delete-menu-item',
        roles: managePricingRole.DeleteManagePricing,
      },
    ];

    const actionFilter = items.filter((item) => {
      if (margin.status === Status.PENDING) {
        return item.label !== 'activate' && item.label !== 'deactivate';
      } else if (margin.status == Status.ACTIVE) {
        return item.label !== 'activate';
      } else if (margin.status == Status.INACTIVE) {
        return item.label !== 'deactivate';
      }
      return true;
    });

    return (
      <div>
        <div
          onClick={(event) => {
            setActionItems(actionFilter);
            setCurrentActionMargin(margin);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={actionItems} ref={menu} popupAlign='left' />
      </div>
    );
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const deletMargin = async (margin: Margin) => {
    if (margin.id) {
      const { data, status } = await ManagePricingService.deleteMargin(margin.id);
      if (status != 200 || data.result.success === false) {
        showToast('success', 'margin.delete.success.toast.title', '', {
          marginType: margin.marginType,
        });
        return;
      } else {
        showToast('success', 'margin.delete.success.toast.title', '', {
          marginType: margin.marginType,
        });
        getMargins(pageParams);
      }
    }
  };

  const onPage = (event: DataTablePageEvent) => {
    setPageParams({
      ...event,
      page: event.page ?? 0,
      rows: event.rows ?? 10,
      first: event.first ?? 0,
    });
  };

  const handleGlobalSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    setGlobalSearch(e.target.value);
  };

  const handleApplyFilters = (formdata: { [key: string]: string[] | string }) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    dispatch(updateFilter({ filterKey: 'margins', value: formdata }));
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
  };

  const onSort = (event: DataTableSortEvent) => {
    const { sortField, sortOrder } = event;
    if (sortField && sortOrder) {
      setSortParams({
        sortField,
        sortOrder,
      });
    }
  };

  const downloadMargins = async () => {
    const id = isCustomerPricing && client && client.id ? client.id : selectedCustomer?.id;
    const payload = {
      searchText: globalSearch.trim(),
      ...filters,
    };
    if (id) {
      await ManagePricingService.downloadTableData(id, payload, 'margins');
    }
  };

  const header = (
    <div className='flex flex-col md:flex-row gap-3 justify-between'>
      <div className='text-neutral-1 text-lg-semibold'>
        <Translate value='margins' />
        <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
          {totalRecords}
        </span>
      </div>
      <div className='flex justify-around gap-2 items-center'>
        <TableSearch globalFilterValue={globalSearch} onGlobalFilterChange={handleGlobalSearch} />
        <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
          <Button
            severity='secondary'
            className='p-button-text h-12'
            onClick={() => {
              setModalVisible(true);
            }}
          >
            <Filter size={20} />
            <Translate value='filterLabel' className='ml-1 hidden md:hidden lg:block' />
          </Button>
          {!!activeFilterCount && (
            <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
          )}
        </div>
        <div>
          <Button
            severity='contrast'
            onClick={() => {
              downloadMargins();
            }}
            className='h-12'
          >
            <Download /> <Translate value='downloadLabel' className='ml-1 hidden lg:block' />
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Confirm
        confirmLabel='Delete'
        headerIcon={<WarningIcon />}
        title={`Delete '${enumToCamelCase(currentActionMargin?.marginType || '')}'?`}
        message='Are you sure you want to delete this margin?'
        confirmButtonSeverify='danger'
        onCancel={() => {
          setShowDeleteDialog(false);
        }}
        onConfirm={() => {
          if (currentActionMargin) {
            deletMargin(currentActionMargin);
          }
          setShowDeleteDialog(false);
        }}
        visible={showDeleteDialog}
      />
      <FilterModal
        visible={isModalVisible}
        setFilterModalToggle={setModalVisible}
        filters={filterValues}
        setFilters={setFilterValues}
        title='clientFilter'
        onApply={handleApplyFilters}
      />
      <div className='table-card'>
        <DataTable
          value={margins}
          paginator={true}
          lazy
          paginatorTemplate={PaginatorTemplate}
          first={pageParams.first}
          rows={pageParams.rows}
          loading={loading}
          totalRecords={totalRecords}
          header={header}
          onPage={onPage}
          onSort={onSort}
          sortField={sortParams.sortField}
          sortOrder={sortParams.sortOrder}
        >
          <Column
            sortable
            field='marginType'
            header='Margin Type'
            body={(margin: Margin) => <div>{enumToCamelCase(margin.marginType)}</div>}
          />
          <Column sortable field='description' header='Description' />
          <Column
            sortable
            field='currencyList'
            header='Currency'
            body={(margin: Margin) => <div>{margin.currencyList.join(', ')}</div>}
          />
          <Column
            field='buyCCYMargin'
            header='Buy CCY Margin'
            body={(margin: Margin) => (
              <div>
                {margin.marginType != MarginType.TIERED ? margin.managedMarginList[0]?.margin : ''}
              </div>
            )}
          />
          <Column
            field='minAmount'
            header='Min Amount'
            body={(margin: Margin) => (
              <div>
                {margin.marginType != MarginType.TIERED
                  ? margin.managedMarginList[0]?.minThreshold
                  : ''}
              </div>
            )}
          />
          <Column
            field='maxAmount'
            header='Max Amount'
            body={(margin: Margin) => (
              <div>
                {margin.marginType != MarginType.TIERED
                  ? margin.managedMarginList[0]?.maxThreshold
                  : ''}
              </div>
            )}
          />

          <Column
            field='status'
            header='Status'
            body={(margin: Margin) => <StatusComp status={margin.status ?? ''} />}
          />
          <Column header='Actions' body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
    </>
  );
};

export default Margins;
