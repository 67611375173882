import { Logout, Notification, User } from '@carbon/icons-react';
import { useOktaAuth } from '@okta/okta-react';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { BreadCrumb, InheritedMenu } from '..';
import UserprincipalService from '../../modules/UserPrinciple/userPrinicple';
import { selectLoading } from '../../store/slices/main/mainSlice';
import { AoboDropdown } from './AoboDropdown';
import AoboHeader from './AoboHeader';
import MultiCustomer from './MultiCustomer';

export default function Header() {
  const isLoading = useSelector(selectLoading);
  const menu = useRef<Menu>(null);
  const { oktaAuth } = useOktaAuth();

  const actions: MenuItem[] = [
    {
      label: 'logout',
      icon: <Logout />,
      onClick: () => {
        oktaAuth
          .signOut({
            postLogoutRedirectUri: window.location.origin + '/logout/callback',
          })
          .then(() => {
            UserprincipalService.signOut().then(async () => {
              await oktaAuth.closeSession();
              oktaAuth.tokenManager.clear();
            });
          });
      },
    },
  ];

  return (
    <div className='fixed bg-white z-[1100] w-[inherit]'>
      <div className='flex justify-between items-center h-[78px] p-2.5 border-b border-neutral-border'>
        <BreadCrumb />
        <div className='flex items-center mr-3.5'>
          <MultiCustomer />
          <div className='ml-3'></div>
          <AoboDropdown />
          {/* <Sun className='fill-primary ml-[24px]' size={24} /> TODO */}
          <Notification className='fill-primary ml-[24px]' size={24} />
          <div
            onClick={(event) => {
              menu.current?.toggle(event);
            }}
          >
            <User className='fill-primary ml-[24px]' size={24} />
          </div>

          <InheritedMenu items={actions} ref={menu} popupAlign='right' />
        </div>
      </div>
      {isLoading && (
        <div className='w-full h-1 bg-primary'>
          <div className='h-full bg-white animate-pulse'></div>
        </div>
      )}
      {<AoboHeader />}
    </div>
  );
}
