import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'primereact/button';
import { translateWithValues } from '../../../i18n/translate';
import useRole from '../../../hooks/useRoles';
import approvalsRoles from './approvalsRoles';

type Props = {
  feature: FeatureType;
};

const ApprovalSetup = ({ feature }: Props) => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);

  const { hasRole } = useRole();

  const steps = [
    {
      title: 'approvalTitle1',
      icon: '/images/approval-setup.svg',
      icon_selected: '/images/approval-setup-selected.svg',
      description: 'approvalDescription1',
    },
    {
      title: 'approvalTitle2',
      icon: '/images/approval-profile.svg',
      icon_selected: '/images/approval-profile-selected.svg',
      description: 'approvalDescription2',
    },
    {
      title: 'approvalTitle3',
      icon: '/images/approval-org.svg',
      icon_selected: '/images/approval-org-selected.svg',
      description: 'approvalDescription3',
    },
    {
      title: 'approvalTitle4',
      icon: '/images/approval-done.svg',
      icon_selected: '/images/approval-done-selected.svg',
      description: '4',
    },
  ];

  return (
    <div className='grid gap-6'>
      <div className='!p-8 card opacity-80'>
        <div className='bg-neutral-surface-subtle w-[80%] m-auto p-6 rounded-lg shadow-md'>
          {/* Header Section */}
          <h2 className='text-xs-bold text-neutral-1 text-center mb-2'>
            {translateWithValues('approvalSetUp')}
          </h2>
          <p className='text-center text-neutral-2 mb-8'>
            {translateWithValues('approvalSetUpDescription')}
          </p>
          {/* Steps Bar */}
          <div className='flex justify-between items-center relative'>
            {steps.map((step, index) => (
              <div
                key={index}
                onClick={() => setCurrentStep(index)}
                className={'flex-1 text-center cursor-pointer relative'}
              >
                <div
                  className={'w-10 h-10 rounded-full flex items-center justify-center m-auto mb-2'}
                >
                  <img
                    className='z-[100]'
                    src={index <= currentStep ? step.icon_selected : step.icon}
                  />
                </div>
                {/* Progress bar connector */}
                {index !== steps.length - 1 && (
                  <div
                    className={`absolute top-5 w-full left-[50%]  border-primary border-[1px] ${
                      index < currentStep ? 'border-solid' : 'border-dotted'
                    }`}
                  ></div>
                )}
              </div>
            ))}
          </div>
          <div className='flex justify-between items-center mb-8 relative'>
            {steps.map((step, index) => (
              <div
                key={index}
                onClick={() => setCurrentStep(index)}
                className={'flex-1 text-center cursor-pointer relative'}
              >
                <p className={`px-6 ${index === currentStep ? 'text-primary font-medium ' : ''}`}>
                  {translateWithValues(step.title)}
                </p>
              </div>
            ))}
          </div>
          <div className='bg-white p-4 rounded-lg shadow-sm text-center'>
            <span className='text-md-semibold mr-1 text-primary'>
              {translateWithValues('step')} {currentStep + 1}:
            </span>
            <span className='text-md-regular'>
              {translateWithValues(steps[currentStep].description)}
            </span>
          </div>
        </div>
      </div>
      {hasRole(approvalsRoles.AddApprovalFlow) && (
        <div className='grid place-items-center'>
          <Button severity='info' onClick={() => navigate(`add-structure?feature=${feature}`)}>
            {translateWithValues('create_approval_structure')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ApprovalSetup;
