import { Translate } from '../../i18n/translate';

interface UserPermissionModalHeaderProps {
    title: string;
}

const UserPermissionModalHeader = ({ title }: UserPermissionModalHeaderProps) => (
    <div className="flex justify-between items-center w-full p-4">
        <div className='flex flex-col gap-1'>
            <div className="text-xl-semibold text-neutral-1">
                {title}
            </div>
            <div className="text-sm font-regular text-neutral-3">
                <Translate value='user_modal_desc' />
            </div>
        </div>
    </div>
);

export default UserPermissionModalHeader;
