import { CustomTabView } from '../../../components';
import { translateWithValues } from '../../../i18n/translate';
import Table from './Table';

const Dashboard = () => {
  const tabs = [
    {
      header: translateWithValues('client.all.status'),
      content: <Table pending={false} />,
    },
    {
      header: translateWithValues('client.pending'),
      content: <Table pending={true} />,
    },
  ];

  return (
    <div>
      <CustomTabView tabs={tabs} initialIndex={0} />
    </div>
  );
};

export default Dashboard;
