import { Button } from 'primereact/button';
import useRole from '../../hooks/useRoles';
import { Translate } from '../../i18n/translate';
import { BlueInfo } from '../../icons';

interface InfoTemplateProps {
  titleKey: string;
  descriptionKey: string;
  buttonKey: string;
  roles?: string;
  btnAction: (...args: any[]) => any;
}

const InfoTemplate = ({
  titleKey,
  descriptionKey,
  buttonKey,
  btnAction,
  roles,
}: InfoTemplateProps) => {
  const { hasRole } = useRole();
  return !roles || hasRole(roles) ? (
    <div className='p-[20px] rounded-[12px] border-[1px] border-primary-light bg-primary-surface-light'>
      <div className='flex justify-between'>
        <div className='flex'>
          <div>
            <BlueInfo />
          </div>
          <div className='ml-3'>
            <Translate
              value={titleKey}
              className='block text-sm-semibold text-primary-dark-content'
            />
            <Translate
              value={descriptionKey}
              className='block text-sm-regular text-primary-dark-content mt-1'
            />
          </div>
        </div>
        <div>
          <Button severity='info' onClick={() => btnAction()}>
            <Translate value={buttonKey} />
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default InfoTemplate;
