import { Outlet } from 'react-router';
import { PageHead } from '../../../components';
import { PageHeadProvider } from '../../../providers/PageHead';

const EmailSettingsOutlet = () => {
  return (
    <>
      <div className='pl-[48px] py-[16px] bg-white border-b border-neutral-border'>
        <PageHead title='email_settings.title' description='subheading_placeholder' />
      </div>
      <PageHeadProvider>
        <div className='p-4'>
          <Outlet />
        </div>
      </PageHeadProvider>
    </>
  );
};

export default EmailSettingsOutlet;
