import { Outlet } from 'react-router';
import { PageHead } from '../../../components';
import { PageHeadProvider } from '../../../providers/PageHead';

const RoleOutlet = () => {
  return (
    <>
      <div className='pl-[20px] lg:pl-[49px] py-[16px] bg-white border-b border-neutral-border'>
        <PageHead title='roles.dashboard.title' description='roles.dashboard.description' />
      </div>
      <PageHeadProvider>
        <div>
          <Outlet />
        </div>
      </PageHeadProvider>
    </>
  );
};
export default RoleOutlet;
