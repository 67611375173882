import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';
import { rateRequestMockData } from './paymentMockData';

const ROOT = '/api/pricing-management/';

const CUSTOMER_MANAGEMENT = '/api/customer-management';
const ORDER_MANAGEMENT = '/api/order-management';
const BENEFICIARY_MANAGEMENT = '/api/beneficiary-management';

const PaymentService = {
  async getRateRequests(): Promise<RateRequest[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(rateRequestMockData);
      }, 1000);
    });
  },

  async getQuickQuote(data: any): Promise<QuickQouteProps | 'Error'> {
    try {
      const response = await apiClient.post(`${ROOT}quick-quote`, data);
      return handleApiResponse<QuickQouteProps>(response);
    } catch (error) {
      return 'Error';
    }
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve(quickQuoteMock);
    //   }, 4000);
    // });
  },

  async getQuoteByMargin(data: any): Promise<QuickQouteProps> {
    try {
      const response = await apiClient.post(`${ROOT}quotes`, data);
      return handleApiResponse<QuickQouteProps>(response);
    } catch (error) {
      console.error('Error creating quote', error);
      throw error;
    }
  },

  async recalculateQuote(data: any): Promise<QuickQouteProps | 'Error'> {
    try {
      const response = await apiClient.post(`${ROOT}quotes/recalculate`, data);
      return handleApiResponse<any>(response);
    } catch (error) {
      return 'Error';
    }
  },

  async createNewCustomer(payload: CustomerCreationForm): Promise<Client> {
    const response = await apiClient.post(`${CUSTOMER_MANAGEMENT}/customers-details`, payload);
    return handleApiResponse(response);
  },

  async createPayment(payload: CreatePaymentPayload) {
    try {
      const response = await apiClient.post(`${ORDER_MANAGEMENT}/orders`, payload);
      return handleApiResponse(response);
    } catch (error) {
      console.error('Error createing payment', error);
      return null;
    }
  },

  async getBeneficiaryFormByCountry(countryCode: string, buyCurrency: string) {
    const response = await apiClient.get(
      `${BENEFICIARY_MANAGEMENT}/payment-validation-rules?bankCountryIsoCode=${countryCode}&buyCurrency=${buyCurrency}`,
    );
    return handleApiResponse<Array<BeneficiaryType>>(response);
  },

  async getBICCode(countryCode: string, bicCode: string, city: string, bankName: string) {
    const response = await apiClient.post(`${BENEFICIARY_MANAGEMENT}/bic-code`, {
      bankCountryIsoCode: countryCode,
      bankAddressCity: city,
      institutionName: bankName,
      bic: bicCode,
    });
    return handleApiResponse<Array<Record<string, string>>>(response);
  },

  async getBankDirectory(countryCode: string, bicCode: string, city: string, bankName: string) {
    const response = await apiClient.post(`${BENEFICIARY_MANAGEMENT}/bank-directory`, {
      bankCountryIsoCode: countryCode,
      bankAddressCity: city,
      institutionName: bankName,
      nationalId: bicCode,
    });
    return handleApiResponse<Array<Record<string, string>>>(response);
  },

  async addPaymentCreditor(quoteId: string, formData: any) {
    const response = await apiClient.post(`${BENEFICIARY_MANAGEMENT}/payment-creditors`, {
      quoteId,
      ...formData,
    });
    return handleApiResponse<any>(response);
  },

  async getInterMediariesData(isoCode: string, bicCode: string) {
    const response = await apiClient.get(
      `${BENEFICIARY_MANAGEMENT}/intermediaries/${isoCode}/${bicCode}`,
    );
    return handleApiResponse<
      Array<{
        intermediary: Array<{
          bankcodetype: string;
          bankcode: string;
          localcode: string;
          bankname: string;
          biccode: string;
          address: Array<string>;
        }>;
      }>
    >(response);
  },
};

export default PaymentService;
