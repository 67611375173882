import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error: unknown = useRouteError();
  let errorMessage: string;
  if (typeof error === 'object' && error !== null && 'statusText' in error) {
    errorMessage = (error as { statusText: string }).statusText;
  } else if (typeof error === 'object' && error !== null && 'message' in error) {
    errorMessage = (error as { message: string }).message;
  } else {
    errorMessage = 'Unknown error';
  }

  return (
    <div id='error-page'>
      <h1>Oops!</h1>
      <p>
        <i>{errorMessage}</i>
      </p>
    </div>
  );
}
