import { InputTextareaProps } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';

const InputTextAreaConf: any = {
  inputtextarea: {
    root: ({ context, props }: { context: any; props: InputTextareaProps }) => ({
      className: classNames(
        'm-0',
        'font-sans text-base text-gray-600 bg-white dark:bg-gray-900 p-3 border border-neutral-5 dark:border-blue-900/40 transition-colors duration-200 appearance-none rounded-lg',
        'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        'resize-none',
        {
          'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
          '!border-error-2': props.invalid,
        },
      ),
    }),
  },
};

export default InputTextAreaConf;
