import React from 'react';
import { Translate } from '../../i18n/translate';

interface NoDataMessageProps {
  title: string;
  desc: string;
  children: React.ReactNode;
}

const NoDataPlaceholder: React.FC<NoDataMessageProps> = ({ title, desc, children }) => {
  return (
    <div className='card'>
      <div className='text-center w-[54%] m-auto'>
        <img src='/images/study--skip.svg' className='m-auto pt-[60px]' />
        <div className='text-md-bold neutral-1 mt-5'>
          <Translate value={title} />
        </div>
        <div className='text-md-regular text-neutral-3 mt-3'>
          <Translate value={desc} />
        </div>
      </div>
      <div className='mt-[30px]'>{children}</div>
    </div>
  );
};

export default NoDataPlaceholder;
