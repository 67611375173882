const SwitchOutlined = () => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='0.5'
        y='0.5'
        width='39'
        height='39'
        rx='7.5'
        stroke='url(#paint0_linear_4441_91564)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.875 16.25L15.625 12.5L19.375 16.25L18.5 17.125L16.25 14.875V27.5H15V14.875L12.75 17.125L11.875 16.25ZM25 25.125L27.25 22.875L28.125 23.75L24.375 27.5L20.625 23.75L21.5 22.875L23.75 25.125V12.5H25V25.125Z'
        fill='url(#paint1_linear_4441_91564)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_4441_91564'
          x1='20'
          y1='0'
          x2='20'
          y2='40'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='var(--primary-gradient-to)' />
          <stop offset='1' stopColor='var(--primary-gradient-from)' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4441_91564'
          x1='20'
          y1='12.5'
          x2='20'
          y2='27.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='var(--primary-gradient-to)' />
          <stop offset='1' stopColor='var(--primary-gradient-from)' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SwitchOutlined;
