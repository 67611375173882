import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ClientService from '../../modules/Client/clientService';
import { useAppDispatch } from '../../store';
import { fethCurrentUser } from '../../store/slices/main/actions';
import {
  selectAoboClient,
  selectCurrentCustomer,
  selectUpdateAoboList,
  sendToast,
  setAoboClient,
  setCurrentCustomer,
} from '../../store/slices/main/mainSlice';
import { CustomerType } from '../../types';
import { injectAoboCustoemrID } from '../../utils/apiClient';
import DropdownInput from '../DropdownInput';

interface Option {
  value: string;
  label: string;
}

export const AoboDropdown = () => {
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [customers, setCustomers] = useState<Option[]>([]);
  const [hierarchyCustomers, setHierarchyCustomers] = useState<Client[] | undefined>([]);
  const [parentClient] = useState<Client | null>(selectedCustomer);
  const selectedAoboClient = useSelector(selectAoboClient);
  const [filterCustomerType, setFilterCustomerType] = useState<CustomerType | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const aoboListUpdate = useSelector(selectUpdateAoboList);

  useEffect(() => {
    if (!selectedAoboClient) {
      getChildrenCustomers();
    }
  }, [aoboListUpdate, selectedAoboClient]);

  const getAllChildren = (node: Client): Client[] => {
    const children = node.children || [];
    return children.reduce((acc: Client[], child: Client) => {
      acc.push(child);
      return acc.concat(getAllChildren(child));
    }, []);
  };

  const getChildrenCustomers = async () => {
    if (selectedCustomer && selectedCustomer.id) {
      const hierarchy = await ClientService.getCustomerHierarchy(selectedCustomer.id);
      if (hierarchy) {
        const allChildren = hierarchy ? getAllChildren(hierarchy) : [];
        setHierarchyCustomers(allChildren);
        let options = allChildren?.map((child) => ({
          value: child.id?.toString() || '',
          label: child.customerShortName ?? child.customerLegalName,
        }));
        options = options.sort((a, b) => a.label?.localeCompare(b.label));
        setCustomers(options || []);
      }
    }
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const CUSTOMER_TYPE_OPTIONS = [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: 'Instructing Institution',
      value: CustomerType.INSTRUCTING_INSTITUTION,
    },
    {
      label: 'Ordering Institution',
      value: CustomerType.ORDERING_INSTITUTION,
    },
    {
      label: 'Ordering Customer',
      value: CustomerType.ORDERING_CUSTOMER,
    },
  ];

  const exitAobo = () => {
    if (!selectedAoboClient) {
      return;
    }
    injectAoboCustoemrID(null);
    showToast('success', 'aobo.remove.title', 'aobo.remove.description');
    dispatch(fethCurrentUser());
    dispatch(setCurrentCustomer(parentClient));
    dispatch(setAoboClient(undefined));
    navigate('/');
  };

  return (
    <>
      {parentClient && customers.length != 0 && (
        <>
          <div>AOBO:</div>
          {parentClient.id == 1 && (
            <DropdownInput
              className='mr-1 min-w-[200px] aobo-dropdown hidden lg:flex'
              options={CUSTOMER_TYPE_OPTIONS}
              placeholder='select.aobo.customer.type'
              onChange={(e) => {
                exitAobo();
                setFilterCustomerType(e.value);
                if (e.value === 'All') {
                  let options = hierarchyCustomers?.map((child) => ({
                    value: child.id?.toString() || '',
                    label: child.customerShortName ?? child.customerLegalName,
                  }));
                  options = options?.sort((a, b) => a.label?.localeCompare(b.label));
                  setCustomers(options || []);
                  return;
                }
                const filteredCustomers = hierarchyCustomers?.filter(
                  (client) => client.customerType == e.value,
                );
                let options = filteredCustomers?.map((child) => ({
                  value: child.id?.toString() || '',
                  label: child.customerShortName ?? child.customerLegalName,
                }));
                options = options?.sort((a, b) => a.label?.localeCompare(b.label));
                setCustomers(options || []);
              }}
              value={filterCustomerType?.toString()}
            ></DropdownInput>
          )}

          <DropdownInput
            onChange={(e) => {
              dispatch(setAoboClient(customers.find((customer) => customer.value === e.value)));
              if (parentClient.id == parseInt(e.value)) {
                // TODO: remove this logic if we don't want show root customer in dropdown
                exitAobo();
              } else {
                const label = customers.find((customer) => customer.value === e.value)?.label;
                showToast('success', 'aobo.title', 'aobo.description', {
                  clientName: label || '',
                });
                injectAoboCustoemrID(parseInt(e.value));
                dispatch(fethCurrentUser());
                const aoboClient = hierarchyCustomers?.find((client) => client.id == e.value);
                if (aoboClient) {
                  dispatch(setCurrentCustomer({ ...aoboClient, aobo: true }));
                }
                navigate('/');
              }
            }}
            className='min-w-[250px] aobo-dropdown'
            placeholder='select.aobo.customer'
            options={customers}
            value={selectedAoboClient?.value}
            filter={true}
          ></DropdownInput>
        </>
      )}
    </>
  );
};
