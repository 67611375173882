import { Warning, WarningFilled } from '@carbon/icons-react';

const ErrorIcon = ({
  size = 24,
  isFilled = true,
  withBackground = true,
}: {
  size?: number;
  isFilled?: boolean;
  withBackground?: boolean;
}) => {
  return (
    <>
      {withBackground && (
        <div
          className={
            'flex items-center justify-center bg-error-3 rounded-full min-w-[40px] h-[40px] m-auto'
          }
        >
          {isFilled && <WarningFilled fill='var(--error-content-default)' size={size} />}
          {!isFilled && <Warning fill='var(--error-content-default)' size={size} />}
        </div>
      )}
      {!withBackground && (
        <>
          {isFilled && <WarningFilled fill='var(--error-content-default)' size={size} />}
          {!isFilled && <Warning fill='var(--error-content-default)' size={size} />}
        </>
      )}
    </>
  );
};

export default ErrorIcon;
