import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectCurrentCustomer } from '../../../../store/slices/main/mainSlice';
import EmailSettingsService from '../EmailSettingsService';
import EmailSettingsForm from './EmailSettingsForm';

const Dashboard = () => {
  const selectedCustomer = useSelector(selectCurrentCustomer);

  const { isLoading, data, isFetched } = useQuery({
    queryKey: ['customerEmailSettings'],
    queryFn: async () => {
      const data = await EmailSettingsService.getCustomerEmailSettings(
        selectedCustomer?.id as number,
      );
      return data;
    },
    enabled: !!selectedCustomer?.id,
    refetchOnWindowFocus: false,
  });

  if (!isFetched || isLoading) {
    return <div className='text-center'>Loading...</div>;
  }

  return (
    <EmailSettingsForm
      customerId={selectedCustomer?.id as number}
      owningCustomerId={selectedCustomer?.owningCustomerId}
      initialData={data}
    />
  );
};

export default Dashboard;
