import { Button } from 'primereact/button';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Translate } from '../../../../i18n/translate';
import { useAppDispatch } from '../../../../store';
import { sendToast } from '../../../../store/slices/main/mainSlice';
import BeneficiaryCreationModal from '../Dashboard/BenificieryCreationModal';
import { AddAlt } from '../../../../icons';

interface BeneficiaryDetailsProps {
  disabled: boolean;
  onSaveAndProceed?: () => void;
  quoteId: string;
  buyCurrency: string;
}

export const BeneficiaryDetails = ({
  disabled,
  onSaveAndProceed,
  quoteId,
  buyCurrency,
}: BeneficiaryDetailsProps) => {
  const {
    handleSubmit,
    formState: { isValid },
  } = useForm<any>();
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();

  const saveBeneficiaryDetails = () => {
    if (onSaveAndProceed) {
      onSaveAndProceed();
    }
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const showErrorToast = () => {
    showToast('error', 'client.field.validation.error', 'client.field.validation.error.detail');
  };

  return (
    <div className='relative'>
      {disabled && <div className='absolute inset-0 bg-white opacity-70 cursor-not-allowed z-10' />}
      <div className={(disabled ? 'opacity-50	' : '') + 'rounded-c8 shadow-c bg-white p-8'}>
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='beneficiary.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='beneficiary.description' />
            </div>
          </div>
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(saveBeneficiaryDetails)}>
          <div className='mt-8 flex gap-4 text-neutral-2 text-sm-medium'>
            <Button
              severity='contrast'
              className='h-[43px]'
              onClick={() => setVisible(true)}
              type='button'
            >
              <AddAlt />
              <Translate value='add_new_beneficiary.header' className='ml-1' />
            </Button>
          </div>

          <div className='flex items-center justify-center mt-10 gap-5'>
            <Button
              type='submit'
              severity='info'
              className='w-48 inline-flex items-center justify-center'
              onClick={() => {
                // if (handleStepperValidation) {
                //   handleStepperValidation(RequestQuickQuoteType.QuoteAmounCurrency, isValid);
                // }
                if (!isValid) {
                  showErrorToast();
                  return;
                }
              }}
            >
              <Translate value='save_and_proceed' />
            </Button>
          </div>
        </form>
      </div>
      <BeneficiaryCreationModal
        visible={visible}
        setVisible={setVisible}
        quoteId={quoteId}
        buyCurrency={buyCurrency}
      />
    </div>
  );
};
