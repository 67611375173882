const countries = [
  {
    code: 'ABW',
    name: 'Aruba',
    dialCode: '297',
    currencyCode: 'AWG',
  },
  {
    code: 'AFG',
    name: 'Afghanistan',
    dialCode: '93',
    currencyCode: 'AFN',
  },
  {
    code: 'AGO',
    name: 'Angola',
    dialCode: '244',
    currencyCode: 'AOA',
  },
  {
    code: 'AIA',
    name: 'Anguilla',
    dialCode: '1-264',
    currencyCode: 'XCD',
  },
  {
    code: 'ALA',
    name: 'Åland Islands',
    dialCode: '358',
    currencyCode: 'EUR',
  },
  {
    code: 'ALB',
    name: 'Albania',
    dialCode: '355',
    currencyCode: 'ALL',
  },
  {
    code: 'AND',
    name: 'Andorra',
    dialCode: '376',
    currencyCode: 'EUR',
  },
  {
    code: 'ARE',
    name: 'United Arab Emirates',
    dialCode: '971',
    currencyCode: 'AED',
  },
  {
    code: 'ARG',
    name: 'Argentina',
    dialCode: '54',
    currencyCode: 'ARS',
  },
  {
    code: 'ARM',
    name: 'Armenia',
    dialCode: '374',
    currencyCode: 'AMD',
  },
  {
    code: 'ASM',
    name: 'American Samoa',
    dialCode: '1-684',
    currencyCode: 'USD',
  },
  {
    code: 'ATA',
    name: 'Antarctica',
    dialCode: '672',
    currencyCode: null,
  },
  {
    code: 'ATF',
    name: 'French Southern Territories',
    dialCode: '262',
    currencyCode: 'EUR',
  },
  {
    code: 'ATG',
    name: 'Antigua and Barbuda',
    dialCode: '1-268',
    currencyCode: 'XCD',
  },
  {
    code: 'AUS',
    name: 'Australia',
    dialCode: '61',
    currencyCode: 'AUD',
  },
  {
    code: 'AUT',
    name: 'Austria',
    dialCode: '43',
    currencyCode: 'EUR',
  },
  {
    code: 'AZE',
    name: 'Azerbaijan',
    dialCode: '994',
    currencyCode: 'AZN',
  },
  {
    code: 'BDI',
    name: 'Burundi',
    dialCode: '257',
    currencyCode: 'BIF',
  },
  {
    code: 'BEL',
    name: 'Belgium',
    dialCode: '32',
    currencyCode: 'EUR',
  },
  {
    code: 'BEN',
    name: 'Benin',
    dialCode: '229',
    currencyCode: 'XOF',
  },
  {
    code: 'BES',
    name: 'Bonaire, Sint Eustatius and Saba',
    dialCode: '599',
    currencyCode: 'USD',
  },
  {
    code: 'BFA',
    name: 'Burkina Faso',
    dialCode: '226',
    currencyCode: 'XOF',
  },
  {
    code: 'BGD',
    name: 'Bangladesh',
    dialCode: '880',
    currencyCode: 'BDT',
  },
  {
    code: 'BGR',
    name: 'Bulgaria',
    dialCode: '359',
    currencyCode: 'BGN',
  },
  {
    code: 'BHR',
    name: 'Bahrain',
    dialCode: '973',
    currencyCode: 'BHD',
  },
  {
    code: 'BHS',
    name: 'Bahamas',
    dialCode: '1-242',
    currencyCode: 'BSD',
  },
  {
    code: 'BIH',
    name: 'Bosnia and Herzegovina',
    dialCode: '387',
    currencyCode: 'BAM',
  },
  {
    code: 'BLM',
    name: 'Saint Barthélemy',
    dialCode: '590',
    currencyCode: 'EUR',
  },
  {
    code: 'BLR',
    name: 'Belarus',
    dialCode: '375',
    currencyCode: 'BYR',
  },
  {
    code: 'BLZ',
    name: 'Belize',
    dialCode: '501',
    currencyCode: 'BZD',
  },
  {
    code: 'BMU',
    name: 'Bermuda',
    dialCode: '1-441',
    currencyCode: 'BMD',
  },
  {
    code: 'BOL',
    name: 'Bolivia, Plurinational State of',
    dialCode: '591',
    currencyCode: 'BOB',
  },
  {
    code: 'BRA',
    name: 'Brazil',
    dialCode: '55',
    currencyCode: 'BRL',
  },
  {
    code: 'BRB',
    name: 'Barbados',
    dialCode: '1-246',
    currencyCode: 'BBD',
  },
  {
    code: 'BRN',
    name: 'Brunei Darussalam',
    dialCode: '673',
    currencyCode: 'BND',
  },
  {
    code: 'BTN',
    name: 'Bhutan',
    dialCode: '975',
    currencyCode: 'INR',
  },
  {
    code: 'BVT',
    name: 'Bouvet Island',
    dialCode: '47',
    currencyCode: 'NOK',
  },
  {
    code: 'BWA',
    name: 'Botswana',
    dialCode: '267',
    currencyCode: 'BWP',
  },
  {
    code: 'CAF',
    name: 'Central African Republic',
    dialCode: '236',
    currencyCode: 'XAF',
  },
  {
    code: 'CAN',
    name: 'Canada',
    dialCode: '1',
    currencyCode: 'CAD',
  },
  {
    code: 'CCK',
    name: 'Cocos (Keeling) Islands',
    dialCode: '61',
    currencyCode: 'AUD',
  },
  {
    code: 'CHE',
    name: 'Switzerland',
    dialCode: '41',
    currencyCode: 'CHF',
  },
  {
    code: 'CHL',
    name: 'Chile',
    dialCode: '56',
    currencyCode: 'CLP',
  },
  {
    code: 'CHN',
    name: 'China',
    dialCode: '86',
    currencyCode: 'CNY',
  },
  {
    code: 'CIV',
    name: "Côte d'Ivoire",
    dialCode: '225',
    currencyCode: 'XOF',
  },
  {
    code: 'CMR',
    name: 'Cameroon',
    dialCode: '237',
    currencyCode: 'XAF',
  },
  {
    code: 'COD',
    name: 'Congo, the Democratic Republic of the',
    dialCode: '243',
    currencyCode: null,
  },
  {
    code: 'COG',
    name: 'Congo',
    dialCode: '242',
    currencyCode: 'XAF',
  },
  {
    code: 'COK',
    name: 'Cook Islands',
    dialCode: '682',
    currencyCode: 'NZD',
  },
  {
    code: 'COL',
    name: 'Colombia',
    dialCode: '57',
    currencyCode: 'COP',
  },
  {
    code: 'COM',
    name: 'Comoros',
    dialCode: '269',
    currencyCode: 'KMF',
  },
  {
    code: 'CPV',
    name: 'Cape Verde',
    dialCode: '238',
    currencyCode: 'CVE',
  },
  {
    code: 'CRI',
    name: 'Costa Rica',
    dialCode: '506',
    currencyCode: 'CRC',
  },
  {
    code: 'CUB',
    name: 'Cuba',
    dialCode: '53',
    currencyCode: 'CUP',
  },
  {
    code: 'CUW',
    name: 'Curaçao',
    dialCode: '599',
    currencyCode: 'ANG',
  },
  {
    code: 'CXR',
    name: 'Christmas Island',
    dialCode: '61',
    currencyCode: 'AUD',
  },
  {
    code: 'CYM',
    name: 'Cayman Islands',
    dialCode: '1-345',
    currencyCode: 'KYD',
  },
  {
    code: 'CYP',
    name: 'Cyprus',
    dialCode: '357',
    currencyCode: 'EUR',
  },
  {
    code: 'CZE',
    name: 'Czech Republic',
    dialCode: '420',
    currencyCode: 'CZK',
  },
  {
    code: 'DEU',
    name: 'Germany',
    dialCode: '49',
    currencyCode: 'EUR',
  },
  {
    code: 'DJI',
    name: 'Djibouti',
    dialCode: '253',
    currencyCode: 'DJF',
  },
  {
    code: 'DMA',
    name: 'Dominica',
    dialCode: '1-767',
    currencyCode: 'XCD',
  },
  {
    code: 'DNK',
    name: 'Denmark',
    dialCode: '45',
    currencyCode: 'DKK',
  },
  {
    code: 'DOM',
    name: 'Dominican Republic',
    dialCode: '1-809',
    currencyCode: 'DOP',
  },
  {
    code: 'DZA',
    name: 'Algeria',
    dialCode: '213',
    currencyCode: 'DZD',
  },
  {
    code: 'ECU',
    name: 'Ecuador',
    dialCode: '593',
    currencyCode: 'USD',
  },
  {
    code: 'EGY',
    name: 'Egypt',
    dialCode: '20',
    currencyCode: 'EGP',
  },
  {
    code: 'ERI',
    name: 'Eritrea',
    dialCode: '291',
    currencyCode: 'ERN',
  },
  {
    code: 'ESH',
    name: 'Western Sahara',
    dialCode: '212',
    currencyCode: 'MAD',
  },
  {
    code: 'ESP',
    name: 'Spain',
    dialCode: '34',
    currencyCode: 'EUR',
  },
  {
    code: 'EST',
    name: 'Estonia',
    dialCode: '372',
    currencyCode: 'EUR',
  },
  {
    code: 'ETH',
    name: 'Ethiopia',
    dialCode: '251',
    currencyCode: 'ETB',
  },
  {
    code: 'FIN',
    name: 'Finland',
    dialCode: '358',
    currencyCode: 'EUR',
  },
  {
    code: 'FJI',
    name: 'Fiji',
    dialCode: '679',
    currencyCode: 'FJD',
  },
  {
    code: 'FLK',
    name: 'Falkland Islands (Malvinas)',
    dialCode: '500',
    currencyCode: 'FKP',
  },
  {
    code: 'FRA',
    name: 'France',
    dialCode: '33',
    currencyCode: 'EUR',
  },
  {
    code: 'FRO',
    name: 'Faroe Islands',
    dialCode: '298',
    currencyCode: 'DKK',
  },
  {
    code: 'FSM',
    name: 'Micronesia, Federated States of',
    dialCode: '691',
    currencyCode: 'USD',
  },
  {
    code: 'GAB',
    name: 'Gabon',
    dialCode: '241',
    currencyCode: 'XAF',
  },
  {
    code: 'GBR',
    name: 'United Kingdom',
    dialCode: '44',
    currencyCode: 'GBP',
  },
  {
    code: 'GEO',
    name: 'Georgia',
    dialCode: '995',
    currencyCode: 'GEL',
  },
  {
    code: 'GGY',
    name: 'Guernsey',
    dialCode: '44',
    currencyCode: 'GBP',
  },
  {
    code: 'GHA',
    name: 'Ghana',
    dialCode: '233',
    currencyCode: 'GHS',
  },
  {
    code: 'GIB',
    name: 'Gibraltar',
    dialCode: '350',
    currencyCode: 'GIP',
  },
  {
    code: 'GIN',
    name: 'Guinea',
    dialCode: '224',
    currencyCode: 'GNF',
  },
  {
    code: 'GLP',
    name: 'Guadeloupe',
    dialCode: '590',
    currencyCode: 'EUR',
  },
  {
    code: 'GMB',
    name: 'Gambia',
    dialCode: '220',
    currencyCode: 'GMD',
  },
  {
    code: 'GNB',
    name: 'Guinea-Bissau',
    dialCode: '245',
    currencyCode: 'XOF',
  },
  {
    code: 'GNQ',
    name: 'Equatorial Guinea',
    dialCode: '240',
    currencyCode: 'XAF',
  },
  {
    code: 'GRC',
    name: 'Greece',
    dialCode: '30',
    currencyCode: 'EUR',
  },
  {
    code: 'GRD',
    name: 'Grenada',
    dialCode: '1-473',
    currencyCode: 'XCD',
  },
  {
    code: 'GRL',
    name: 'Greenland',
    dialCode: '299',
    currencyCode: 'DKK',
  },
  {
    code: 'GTM',
    name: 'Guatemala',
    dialCode: '502',
    currencyCode: 'GTQ',
  },
  {
    code: 'GUF',
    name: 'French Guiana',
    dialCode: '594',
    currencyCode: 'EUR',
  },
  {
    code: 'GUM',
    name: 'Guam',
    dialCode: '1-671',
    currencyCode: 'USD',
  },
  {
    code: 'GUY',
    name: 'Guyana',
    dialCode: '592',
    currencyCode: 'GYD',
  },
  {
    code: 'HKG',
    name: 'Hong Kong',
    dialCode: '852',
    currencyCode: 'HKD',
  },
  {
    code: 'HMD',
    name: 'Heard Island and McDonald Islands',
    dialCode: '672',
    currencyCode: 'AUD',
  },
  {
    code: 'HND',
    name: 'Honduras',
    dialCode: '504',
    currencyCode: 'HNL',
  },
  {
    code: 'HRV',
    name: 'Croatia',
    dialCode: '385',
    currencyCode: 'HRK',
  },
  {
    code: 'HTI',
    name: 'Haiti',
    dialCode: '509',
    currencyCode: 'USD',
  },
  {
    code: 'HUN',
    name: 'Hungary',
    dialCode: '36',
    currencyCode: 'HUF',
  },
  {
    code: 'IDN',
    name: 'Indonesia',
    dialCode: '62',
    currencyCode: 'IDR',
  },
  {
    code: 'IMN',
    name: 'Isle of Man',
    dialCode: '44',
    currencyCode: 'GBP',
  },
  {
    code: 'IND',
    name: 'India',
    dialCode: '91',
    currencyCode: 'INR',
  },
  {
    code: 'IOT',
    name: 'British Indian Ocean Territory',
    dialCode: '246',
    currencyCode: 'USD',
  },
  {
    code: 'IRL',
    name: 'Ireland',
    dialCode: '353',
    currencyCode: 'EUR',
  },
  {
    code: 'IRN',
    name: 'Iran, Islamic Republic of',
    dialCode: '98',
    currencyCode: 'IRR',
  },
  {
    code: 'IRQ',
    name: 'Iraq',
    dialCode: '964',
    currencyCode: 'IQD',
  },
  {
    code: 'ISL',
    name: 'Iceland',
    dialCode: '354',
    currencyCode: 'ISK',
  },
  {
    code: 'ISR',
    name: 'Israel',
    dialCode: '972',
    currencyCode: 'ILS',
  },
  {
    code: 'ITA',
    name: 'Italy',
    dialCode: '39',
    currencyCode: 'EUR',
  },
  {
    code: 'JAM',
    name: 'Jamaica',
    dialCode: '1-876',
    currencyCode: 'JMD',
  },
  {
    code: 'JEY',
    name: 'Jersey',
    dialCode: '44',
    currencyCode: 'GBP',
  },
  {
    code: 'JOR',
    name: 'Jordan',
    dialCode: '962',
    currencyCode: 'JOD',
  },
  {
    code: 'JPN',
    name: 'Japan',
    dialCode: '81',
    currencyCode: 'JPY',
  },
  {
    code: 'KAZ',
    name: 'Kazakhstan',
    dialCode: '7',
    currencyCode: 'KZT',
  },
  {
    code: 'KEN',
    name: 'Kenya',
    dialCode: '254',
    currencyCode: 'KES',
  },
  {
    code: 'KGZ',
    name: 'Kyrgyzstan',
    dialCode: '996',
    currencyCode: 'KGS',
  },
  {
    code: 'KHM',
    name: 'Cambodia',
    dialCode: '855',
    currencyCode: 'KHR',
  },
  {
    code: 'KIR',
    name: 'Kiribati',
    dialCode: '686',
    currencyCode: 'AUD',
  },
  {
    code: 'KNA',
    name: 'Saint Kitts and Nevis',
    dialCode: '1-869',
    currencyCode: 'XCD',
  },
  {
    code: 'KOR',
    name: 'Korea, Republic of',
    dialCode: '82',
    currencyCode: 'KRW',
  },
  {
    code: 'KWT',
    name: 'Kuwait',
    dialCode: '965',
    currencyCode: 'KWD',
  },
  {
    code: 'LAO',
    name: "Lao People's Democratic Republic",
    dialCode: '856',
    currencyCode: 'LAK',
  },
  {
    code: 'LBN',
    name: 'Lebanon',
    dialCode: '961',
    currencyCode: 'LBP',
  },
  {
    code: 'LBR',
    name: 'Liberia',
    dialCode: '231',
    currencyCode: 'LRD',
  },
  {
    code: 'LBY',
    name: 'Libya',
    dialCode: '218',
    currencyCode: 'LYD',
  },
  {
    code: 'LCA',
    name: 'Saint Lucia',
    dialCode: '1-758',
    currencyCode: 'XCD',
  },
  {
    code: 'LIE',
    name: 'Liechtenstein',
    dialCode: '423',
    currencyCode: 'CHF',
  },
  {
    code: 'LKA',
    name: 'Sri Lanka',
    dialCode: '94',
    currencyCode: 'LKR',
  },
  {
    code: 'LSO',
    name: 'Lesotho',
    dialCode: '266',
    currencyCode: 'ZAR',
  },
  {
    code: 'LTU',
    name: 'Lithuania',
    dialCode: '370',
    currencyCode: 'EUR',
  },
  {
    code: 'LUX',
    name: 'Luxembourg',
    dialCode: '352',
    currencyCode: 'EUR',
  },
  {
    code: 'LVA',
    name: 'Latvia',
    dialCode: '371',
    currencyCode: 'EUR',
  },
  {
    code: 'MAC',
    name: 'Macao',
    dialCode: '853',
    currencyCode: 'MOP',
  },
  {
    code: 'MAF',
    name: 'Saint Martin (French part)',
    dialCode: '590',
    currencyCode: 'EUR',
  },
  {
    code: 'MAR',
    name: 'Morocco',
    dialCode: '212',
    currencyCode: 'MAD',
  },
  {
    code: 'MCO',
    name: 'Monaco',
    dialCode: '377',
    currencyCode: 'EUR',
  },
  {
    code: 'MDA',
    name: 'Moldova, Republic of',
    dialCode: '373',
    currencyCode: 'MDL',
  },
  {
    code: 'MDG',
    name: 'Madagascar',
    dialCode: '261',
    currencyCode: 'MGA',
  },
  {
    code: 'MDV',
    name: 'Maldives',
    dialCode: '960',
    currencyCode: 'MVR',
  },
  {
    code: 'MEX',
    name: 'Mexico',
    dialCode: '52',
    currencyCode: 'MXN',
  },
  {
    code: 'MHL',
    name: 'Marshall Islands',
    dialCode: '692',
    currencyCode: 'USD',
  },
  {
    code: 'MKD',
    name: 'Macedonia, the Former Yugoslav Republic of',
    dialCode: '389',
    currencyCode: 'MKD',
  },
  {
    code: 'MLI',
    name: 'Mali',
    dialCode: '223',
    currencyCode: 'XOF',
  },
  {
    code: 'MLT',
    name: 'Malta',
    dialCode: '356',
    currencyCode: 'EUR',
  },
  {
    code: 'MMR',
    name: 'Myanmar',
    dialCode: '95',
    currencyCode: 'MMK',
  },
  {
    code: 'MNE',
    name: 'Montenegro',
    dialCode: '382',
    currencyCode: 'EUR',
  },
  {
    code: 'MNG',
    name: 'Mongolia',
    dialCode: '976',
    currencyCode: 'MNT',
  },
  {
    code: 'MNP',
    name: 'Northern Mariana Islands',
    dialCode: '1-670',
    currencyCode: 'USD',
  },
  {
    code: 'MOZ',
    name: 'Mozambique',
    dialCode: '258',
    currencyCode: 'MZN',
  },
  {
    code: 'MRT',
    name: 'Mauritania',
    dialCode: '222',
    currencyCode: 'MRO',
  },
  {
    code: 'MSR',
    name: 'Montserrat',
    dialCode: '1-664',
    currencyCode: 'XCD',
  },
  {
    code: 'MTQ',
    name: 'Martinique',
    dialCode: '596',
    currencyCode: 'EUR',
  },
  {
    code: 'MUS',
    name: 'Mauritius',
    dialCode: '230',
    currencyCode: 'MUR',
  },
  {
    code: 'MWI',
    name: 'Malawi',
    dialCode: '265',
    currencyCode: 'MWK',
  },
  {
    code: 'MYS',
    name: 'Malaysia',
    dialCode: '60',
    currencyCode: 'MYR',
  },
  {
    code: 'MYT',
    name: 'Mayotte',
    dialCode: '262',
    currencyCode: 'EUR',
  },
  {
    code: 'NAM',
    name: 'Namibia',
    dialCode: '264',
    currencyCode: 'ZAR',
  },
  {
    code: 'NCL',
    name: 'New Caledonia',
    dialCode: '687',
    currencyCode: 'XPF',
  },
  {
    code: 'NER',
    name: 'Niger',
    dialCode: '227',
    currencyCode: 'XOF',
  },
  {
    code: 'NFK',
    name: 'Norfolk Island',
    dialCode: '672',
    currencyCode: 'AUD',
  },
  {
    code: 'NGA',
    name: 'Nigeria',
    dialCode: '234',
    currencyCode: 'NGN',
  },
  {
    code: 'NIC',
    name: 'Nicaragua',
    dialCode: '505',
    currencyCode: 'NIO',
  },
  {
    code: 'NIU',
    name: 'Niue',
    dialCode: '683',
    currencyCode: 'NZD',
  },
  {
    code: 'NLD',
    name: 'Netherlands',
    dialCode: '31',
    currencyCode: 'EUR',
  },
  {
    code: 'NOR',
    name: 'Norway',
    dialCode: '47',
    currencyCode: 'NOK',
  },
  {
    code: 'NPL',
    name: 'Nepal',
    dialCode: '977',
    currencyCode: 'NPR',
  },
  {
    code: 'NRU',
    name: 'Nauru',
    dialCode: '674',
    currencyCode: 'AUD',
  },
  {
    code: 'NZL',
    name: 'New Zealand',
    dialCode: '64',
    currencyCode: 'NZD',
  },
  {
    code: 'OMN',
    name: 'Oman',
    dialCode: '968',
    currencyCode: 'OMR',
  },
  {
    code: 'PAK',
    name: 'Pakistan',
    dialCode: '92',
    currencyCode: 'PKR',
  },
  {
    code: 'PAN',
    name: 'Panama',
    dialCode: '507',
    currencyCode: 'USD',
  },
  {
    code: 'PCN',
    name: 'Pitcairn',
    dialCode: '870',
    currencyCode: 'NZD',
  },
  {
    code: 'PER',
    name: 'Peru',
    dialCode: '51',
    currencyCode: 'PEN',
  },
  {
    code: 'PHL',
    name: 'Philippines',
    dialCode: '63',
    currencyCode: 'PHP',
  },
  {
    code: 'PLW',
    name: 'Palau',
    dialCode: '680',
    currencyCode: 'USD',
  },
  {
    code: 'PNG',
    name: 'Papua New Guinea',
    dialCode: '675',
    currencyCode: 'PGK',
  },
  {
    code: 'POL',
    name: 'Poland',
    dialCode: '48',
    currencyCode: 'PLN',
  },
  {
    code: 'PRI',
    name: 'Puerto Rico',
    dialCode: '1',
    currencyCode: 'USD',
  },
  {
    code: 'PRK',
    name: "Korea, Democratic People's Republic of",
    dialCode: '850',
    currencyCode: 'KPW',
  },
  {
    code: 'PRT',
    name: 'Portugal',
    dialCode: '351',
    currencyCode: 'EUR',
  },
  {
    code: 'PRY',
    name: 'Paraguay',
    dialCode: '595',
    currencyCode: 'PYG',
  },
  {
    code: 'PSE',
    name: 'Palestine, State of',
    dialCode: '970',
    currencyCode: null,
  },
  {
    code: 'PYF',
    name: 'French Polynesia',
    dialCode: '689',
    currencyCode: 'XPF',
  },
  {
    code: 'QAT',
    name: 'Qatar',
    dialCode: '974',
    currencyCode: 'QAR',
  },
  {
    code: 'REU',
    name: 'Réunion',
    dialCode: '262',
    currencyCode: 'EUR',
  },
  {
    code: 'ROU',
    name: 'Romania',
    dialCode: '40',
    currencyCode: 'RON',
  },
  {
    code: 'RUS',
    name: 'Russian Federation',
    dialCode: '7',
    currencyCode: 'RUB',
  },
  {
    code: 'RWA',
    name: 'Rwanda',
    dialCode: '250',
    currencyCode: 'RWF',
  },
  {
    code: 'SAU',
    name: 'Saudi Arabia',
    dialCode: '966',
    currencyCode: 'SAR',
  },
  {
    code: 'SDN',
    name: 'Sudan',
    dialCode: '249',
    currencyCode: 'SDG',
  },
  {
    code: 'SEN',
    name: 'Senegal',
    dialCode: '221',
    currencyCode: 'XOF',
  },
  {
    code: 'SGP',
    name: 'Singapore',
    dialCode: '65',
    currencyCode: 'SGD',
  },
  {
    code: 'SGS',
    name: 'South Georgia and the South Sandwich Islands',
    dialCode: '500',
    currencyCode: null,
  },
  {
    code: 'SHN',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    dialCode: '290',
    currencyCode: 'SHP',
  },
  {
    code: 'SJM',
    name: 'Svalbard and Jan Mayen',
    dialCode: '47',
    currencyCode: 'NOK',
  },
  {
    code: 'SLB',
    name: 'Solomon Islands',
    dialCode: '677',
    currencyCode: 'SBD',
  },
  {
    code: 'SLE',
    name: 'Sierra Leone',
    dialCode: '232',
    currencyCode: 'SLL',
  },
  {
    code: 'SLV',
    name: 'El Salvador',
    dialCode: '503',
    currencyCode: 'USD',
  },
  {
    code: 'SMR',
    name: 'San Marino',
    dialCode: '378',
    currencyCode: 'EUR',
  },
  {
    code: 'SOM',
    name: 'Somalia',
    dialCode: '252',
    currencyCode: 'SOS',
  },
  {
    code: 'SPM',
    name: 'Saint Pierre and Miquelon',
    dialCode: '508',
    currencyCode: 'EUR',
  },
  {
    code: 'SRB',
    name: 'Serbia',
    dialCode: '381',
    currencyCode: 'RSD',
  },
  {
    code: 'SSD',
    name: 'South Sudan',
    dialCode: '211',
    currencyCode: 'SSP',
  },
  {
    code: 'STP',
    name: 'Sao Tome and Principe',
    dialCode: '239',
    currencyCode: 'STD',
  },
  {
    code: 'SUR',
    name: 'Suriname',
    dialCode: '597',
    currencyCode: 'SRD',
  },
  {
    code: 'SVK',
    name: 'Slovakia',
    dialCode: '421',
    currencyCode: 'EUR',
  },
  {
    code: 'SVN',
    name: 'Slovenia',
    dialCode: '386',
    currencyCode: 'EUR',
  },
  {
    code: 'SWE',
    name: 'Sweden',
    dialCode: '46',
    currencyCode: 'SEK',
  },
  {
    code: 'SWZ',
    name: 'Swaziland',
    dialCode: '268',
    currencyCode: 'SZL',
  },
  {
    code: 'SXM',
    name: 'Sint Maarten (Dutch part)',
    dialCode: '1-721',
    currencyCode: 'ANG',
  },
  {
    code: 'SYC',
    name: 'Seychelles',
    dialCode: '248',
    currencyCode: 'SCR',
  },
  {
    code: 'SYR',
    name: 'Syrian Arab Republic',
    dialCode: '963',
    currencyCode: 'SYP',
  },
  {
    code: 'TCA',
    name: 'Turks and Caicos Islands',
    dialCode: '1-649',
    currencyCode: 'USD',
  },
  {
    code: 'TCD',
    name: 'Chad',
    dialCode: '235',
    currencyCode: 'XAF',
  },
  {
    code: 'TGO',
    name: 'Togo',
    dialCode: '228',
    currencyCode: 'XOF',
  },
  {
    code: 'THA',
    name: 'Thailand',
    dialCode: '66',
    currencyCode: 'THB',
  },
  {
    code: 'TJK',
    name: 'Tajikistan',
    dialCode: '992',
    currencyCode: 'TJS',
  },
  {
    code: 'TKL',
    name: 'Tokelau',
    dialCode: '690',
    currencyCode: 'NZD',
  },
  {
    code: 'TKM',
    name: 'Turkmenistan',
    dialCode: '993',
    currencyCode: 'TMT',
  },
  {
    code: 'TLS',
    name: 'Timor-Leste',
    dialCode: '670',
    currencyCode: 'USD',
  },
  {
    code: 'TON',
    name: 'Tonga',
    dialCode: '676',
    currencyCode: 'TOP',
  },
  {
    code: 'TTO',
    name: 'Trinidad and Tobago',
    dialCode: '1-868',
    currencyCode: 'TTD',
  },
  {
    code: 'TUN',
    name: 'Tunisia',
    dialCode: '216',
    currencyCode: 'TND',
  },
  {
    code: 'TUR',
    name: 'Turkey',
    dialCode: '90',
    currencyCode: 'TRY',
  },
  {
    code: 'TUV',
    name: 'Tuvalu',
    dialCode: '688',
    currencyCode: 'AUD',
  },
  {
    code: 'TWN',
    name: 'Taiwan, Province of China',
    dialCode: '886',
    currencyCode: 'TWD',
  },
  {
    code: 'TZA',
    name: 'Tanzania, United Republic of',
    dialCode: '255',
    currencyCode: 'TZS',
  },
  {
    code: 'UGA',
    name: 'Uganda',
    dialCode: '256',
    currencyCode: 'UGX',
  },
  {
    code: 'UKR',
    name: 'Ukraine',
    dialCode: '380',
    currencyCode: 'UAH',
  },
  {
    code: 'UMI',
    name: 'United States Minor Outlying Islands',
    dialCode: '1',
    currencyCode: 'USD',
  },
  {
    code: 'URY',
    name: 'Uruguay',
    dialCode: '598',
    currencyCode: 'UYU',
  },
  {
    code: 'USA',
    name: 'United States',
    dialCode: '1',
    currencyCode: 'USD',
  },
  {
    code: 'UZB',
    name: 'Uzbekistan',
    dialCode: '998',
    currencyCode: 'UZS',
  },
  {
    code: 'VAT',
    name: 'Holy See (Vatican City State)',
    dialCode: '39-06',
    currencyCode: 'EUR',
  },
  {
    code: 'VCT',
    name: 'Saint Vincent and the Grenadines',
    dialCode: '1-784',
    currencyCode: 'XCD',
  },
  {
    code: 'VEN',
    name: 'Venezuela, Bolivarian Republic of',
    dialCode: '58',
    currencyCode: 'VEF',
  },
  {
    code: 'VGB',
    name: 'Virgin Islands, British',
    dialCode: '1-284',
    currencyCode: 'USD',
  },
  {
    code: 'VIR',
    name: 'Virgin Islands, U.S.',
    dialCode: '1-340',
    currencyCode: 'USD',
  },
  {
    code: 'VNM',
    name: 'Viet Nam',
    dialCode: '84',
    currencyCode: 'VND',
  },
  {
    code: 'VUT',
    name: 'Vanuatu',
    dialCode: '678',
    currencyCode: 'VUV',
  },
  {
    code: 'WLF',
    name: 'Wallis and Futuna',
    dialCode: '681',
    currencyCode: 'XPF',
  },
  {
    code: 'WSM',
    name: 'Samoa',
    dialCode: '685',
    currencyCode: 'WST',
  },
  {
    code: 'YEM',
    name: 'Yemen',
    dialCode: '967',
    currencyCode: 'YER',
  },
  {
    code: 'ZAF',
    name: 'South Africa',
    dialCode: '27',
    currencyCode: 'ZAR',
  },
  {
    code: 'ZMB',
    name: 'Zambia',
    dialCode: '260',
    currencyCode: 'ZMW',
  },
  {
    code: 'ZWE',
    name: 'Zimbabwe',
    dialCode: '263',
    currencyCode: 'ZWL',
  },
];
export default countries;
