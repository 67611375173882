const RadioButton = ({ isdisabled }: { isdisabled: boolean }) => (
  <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect
      width='36'
      height='36'
      rx='8'
      fill={isdisabled ? 'var(--primary-disabled-color)' : 'var(--primary-color)'}
    />
    <path
      d='M18 9.25C16.2694 9.25 14.5777 9.76318 13.1388 10.7246C11.6998 11.6861 10.5783 13.0527 9.91606 14.6515C9.25379 16.2504 9.08051 18.0097 9.41813 19.707C9.75575 21.4044 10.5891 22.9635 11.8128 24.1872C13.0365 25.4109 14.5956 26.2442 16.293 26.5819C17.9903 26.9195 19.7496 26.7462 21.3485 26.0839C22.9473 25.4217 24.3139 24.3002 25.2754 22.8612C26.2368 21.4223 26.75 19.7306 26.75 18C26.75 15.6794 25.8281 13.4538 24.1872 11.8128C22.5462 10.1719 20.3206 9.25 18 9.25ZM18 25.5C16.5166 25.5 15.0666 25.0601 13.8332 24.236C12.5999 23.4119 11.6386 22.2406 11.0709 20.8701C10.5033 19.4997 10.3547 17.9917 10.6441 16.5368C10.9335 15.082 11.6478 13.7456 12.6967 12.6967C13.7456 11.6478 15.082 10.9335 16.5368 10.6441C17.9917 10.3547 19.4997 10.5032 20.8701 11.0709C22.2406 11.6386 23.4119 12.5999 24.236 13.8332C25.0601 15.0666 25.5 16.5166 25.5 18C25.5 19.9891 24.7098 21.8968 23.3033 23.3033C21.8968 24.7098 19.9891 25.5 18 25.5Z'
      fill='white'
    />
  </svg>
);

export default RadioButton;
