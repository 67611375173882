import React from 'react';
import { useNavigate } from 'react-router-dom';

const Unauthorized: React.FC = () => {
  const navigate = useNavigate();

  // Function to navigate to the previous page or homepage
  const goBack = () => {
    navigate(-1);
  };

  const goHome = () => {
    navigate('/');
  };

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-red-100 text-red-800 text-center'>
      <h1 className='text-4xl font-bold mb-4'>403 - Unauthorized</h1>
      <p className='text-lg mb-6'>You do not have permission to view this page.</p>
      <div className='space-x-4'>
        <button
          onClick={goBack}
          className='px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all'
        >
          Go Back
        </button>
        <button
          onClick={goHome}
          className='px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all'
        >
          Go to Homepage
        </button>
      </div>
    </div>
  );
};

export default Unauthorized;
