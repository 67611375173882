// store/modalSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../rootReducer';

interface ModalState {
  visible: boolean;
  title?: string;
  message?: string;
  confirmLabel?: React.ReactNode;
  cancelLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  className?: string;
  headerIcon?: React.ReactNode;
  singleButton?: string;
  i18nParameters?: { [key: string]: string };
  checkboxLabel?: React.ReactNode;
  checkboxChecked?: boolean;
  confirmButtonSeverify?:
    | 'success'
    | 'secondary'
    | 'warning'
    | 'info'
    | 'danger'
    | 'help'
    | 'contrast';
}

interface ModalRootState {
  confirmModal: ModalState;
}

const initialState: ModalRootState = {
  confirmModal: {
    visible: false,
  },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state: ModalRootState, action: PayloadAction<ModalState>) => {
      state.confirmModal = action.payload;
    },
    hideModal: (state) => {
      state.confirmModal.visible = false;
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;

export const selectConfirmModalData = (state: RootState) => state.confirmModal;

export default modalSlice.reducer;
