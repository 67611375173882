import { Security } from '@okta/okta-react';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import oktaAuth from './auth/Okta';
import { Toaster } from './components';
import ConfirmModal from './components/Confirm/confirmModal';
import CustomerSelectionDialog from './modules/UserPrinciple/CustomerSelectionDialog';
import routes from './routes';
import store, { useAppDispatch } from './store';
import { fethCurrentUser } from './store/slices/main/actions';
import { selectCurrentCustomer, selectToast } from './store/slices/main/mainSlice';
import {
  fetchAppRefdata,
  fetchBuyCurrency,
  fetchCurrency,
  fetchSellCurrency,
} from './store/slices/refdata/actions';
import { injectStore } from './utils/apiClient';

function App() {
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const dispatch = useAppDispatch();
  const toast = useSelector(selectToast);
  const toastRef = useRef<ToasterProps>(null);
  useEffect(() => {
    if (toast) {
      toastRef.current?.showToast(
        toast.severity,
        toast.summary,
        '',
        toast.life,
        toast.detail,
        toast.params,
      );
    }
  }, [toast]);

  useEffect(() => {
    const customerId = selectedCustomer?.id;
    if (!customerId) {
      return;
    }
    if (selectedCustomer) {
      dispatch(fethCurrentUser());
      dispatch(fetchCurrency(customerId));
      dispatch(fetchSellCurrency(customerId));
      dispatch(fetchBuyCurrency(customerId));
      dispatch(fetchAppRefdata());
    }
  }, [selectedCustomer]);

  injectStore(store);

  return (
    <>
      <CustomerSelectionDialog />
      <ConfirmModal />
      <Toaster ref={toastRef} />
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={() => {
          window.location.href = '/';
        }}
      >
        <RouterProvider router={routes} />
      </Security>
    </>
  );
}

export default App;
