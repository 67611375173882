import { Add } from '@carbon/icons-react';
import { useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Translate } from '../../../../../../i18n/translate';
import { useAppDispatch } from '../../../../../../store';
import { selectCurrentCustomer, sendToast } from '../../../../../../store/slices/main/mainSlice';
import ApprovalsSerive from '../../../approvalsService';
import DottedConnector from './DottedConnector';
import LevelCard from './LevelCard';

type Props = {
  feature: FeatureType;
  appStructure: AppStructureType;
  approverCategory: 'USER' | 'ROLE';
  initialData: Array<AppModelConfig>;
  submitStep: (appModelConfigs: Array<AppModelConfig>) => void;
};

const Step2 = ({ feature, appStructure, approverCategory, initialData, submitStep }: Props) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const currentCustomer = useSelector(selectCurrentCustomer);

  const [appModelConfigs, setAppModelConfigs] = useState<Array<AppModelConfig>>(initialData);

  useQuery({
    queryKey: ['getUsersByFeature'],
    queryFn: async () => {
      const data = await ApprovalsSerive.getUsersByFeature(feature);
      return data;
    },
    refetchOnWindowFocus: false,
    enabled: approverCategory === 'USER',
  });

  useQuery({
    queryKey: ['getRolesByFeature'],
    queryFn: async () => {
      const data = await ApprovalsSerive.getRolesByFeature(feature);
      const roles = data.filter((role) => {
        const fetuareRole = role.rolePermissions.find(
          (rolePermission) => rolePermission.feature === feature,
        );
        // ony show roles that have approve action
        if (fetuareRole) {
          return fetuareRole.actions.includes('APPROVE');
        }
        return false;
      });
      return roles;
    },
    refetchOnWindowFocus: false,
    enabled: approverCategory === 'ROLE',
  });

  const validateInput = () => {
    let isValid = true;
    appModelConfigs.some((config, index) => {
      if (appStructure === 'TIERED') {
        if (config.minThreshold === '') {
          dispatch(
            sendToast({
              severity: 'error',
              summary: 'Minimum threshold error',
              detail: `Minimum threshold is required for level ${index + 1}`,
            }),
          );
          isValid = false;
          return true;
        }
        if (config.maxThreshold === '') {
          dispatch(
            sendToast({
              severity: 'error',
              summary: 'Maximum threshold error',
              detail: `Maximum threshold is required for level ${index + 1}`,
            }),
          );
          isValid = false;
          return true;
        }
      }
      if (config.appApprovers.length === 0) {
        console.log(config);
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'Approvers error',
            detail: `Atleast one approver should be selected for level ${index + 1}`,
          }),
        );
        isValid = false;
        return true;
      }
    });
    return isValid;
  };

  const heading = (() => {
    switch (feature) {
      case 'PAYMENT':
        return 'create_approval.payment.create_heading';
      case 'BENEFICIARY_MANAGEMENT':
        return 'create_approval.beneficiary.create_heading';
      case 'MANAGE_USER':
        return 'create_approval.user.create_heading';
      case 'MANAGE_CUSTOMER':
        return 'create_approval.customer.create_heading';
      case 'MANAGE_APPROVAL_WORKFLOW':
        return 'create_approval.approval.create_heading';
      default:
        return '';
    }
  })();

  return (
    <div className='grid gap-6'>
      <div className='card px-12 grid gap-1'>
        <Translate value={heading} className='text-neutral-1 text-lg-semibold' />
        <Translate
          value='create_approval.create_approval_structure.description'
          className='text-neutral-3 text-sm-regular'
        />
      </div>
      <div className='flex justify-center w-full overflow-x-hidden'>
        <div className='flex overflow-x-auto pb-4'>
          {appModelConfigs.map((config, index) => (
            <div key={index} className='relative flex items-center'>
              <LevelCard
                level={index + 1}
                appStructure={appStructure}
                approverCategory={approverCategory}
                isEditMode
                config={config}
                showCloseButton={appStructure !== 'SIMPLE' && appModelConfigs.length > 1}
                updateDetails={(newDetails) =>
                  setAppModelConfigs((prev) => [
                    ...prev.slice(0, index),
                    newDetails,
                    ...prev.slice(index + 1),
                  ])
                }
                removeLevel={() =>
                  setAppModelConfigs((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
                }
              />
              {appStructure !== 'SIMPLE' && (
                <>
                  <DottedConnector />
                  {index === appModelConfigs.length - 1 && (
                    <Button
                      type='button'
                      severity='secondary'
                      className='border-selected flex w-fit whitespace-nowrap'
                      onClick={() =>
                        setAppModelConfigs((prev) => {
                          const newAppModelConfig: AppModelConfig = {
                            appApprovers: [],
                            reqAppCount: 1,
                          };

                          if (appStructure === 'TIERED') {
                            newAppModelConfig.currency = currentCustomer?.currency || 'USD';
                            newAppModelConfig.minThreshold = '';
                            newAppModelConfig.maxThreshold = '';
                          }

                          return [...prev, newAppModelConfig];
                        })
                      }
                    >
                      <Translate
                        value={
                          appStructure === 'GROUPED'
                            ? 'create_approval.add_group'
                            : 'create_approval.add_level'
                        }
                        className='text-selected'
                      />
                      <Add />
                    </Button>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className='flex gap-4 w-full justify-center mt-10'>
        <Button
          type='button'
          severity='contrast'
          className='w-60 inline-flex justify-center'
          onClick={() => navigate(`/manage/approvals?tab=${feature}`)}
        >
          <Translate value='create_approval.cancel' />
        </Button>
        <Button
          type='button'
          severity='info'
          onClick={() => {
            if (validateInput()) {
              submitStep(appModelConfigs);
            }
          }}
          className='w-60 inline-flex justify-center'
        >
          <Translate value='create_approval.save_and_proceed' />
        </Button>
      </div>
    </div>
  );
};

export default Step2;
