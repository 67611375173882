import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';
import { DiscalimerField } from './Disclaimer/type';

const ROOT = '/api/disclaimer-management';
const DISCLAIMERS = '/disclaimers';
const CUSTOMERS = '/customers';

const fetchDisclaimer = async (customerId: string): Promise<DiscalimerField[] | null> => {
  try {
    const response = await apiClient.get<DiscalimerField[]>(
      `${ROOT}${DISCLAIMERS}${CUSTOMERS}/${customerId}`,
    );
    return handleApiResponse(response);
  } catch (error) {
    console.error(`Error fetching disclaimer with id ${customerId}`);
    return null;
  }
};

const updateDisclaimer = async (
  payload: Array<{ type: string; content?: string }>,
  customerId: string,
) => {
  try {
    const response = await apiClient.put(
      `${ROOT}${DISCLAIMERS}${CUSTOMERS}/${customerId}`,
      payload,
    );
    return handleApiResponse(response);
  } catch (errofetchDisclaimerr) {
    console.log(`Error updating disclaimer for id ${customerId}`);
    return null;
  }
};

export { fetchDisclaimer, updateDisclaimer };
