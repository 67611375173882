const AddAlt = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5C13.125 16.5 16.5 13.125 16.5 9ZM0.25 9C0.25 4.1875 4.1875 0.25 9 0.25C13.8125 0.25 17.75 4.1875 17.75 9C17.75 13.8125 13.8125 17.75 9 17.75C4.1875 17.75 0.25 13.8125 0.25 9ZM9.625 8.375H14V9.625H9.625V14H8.375V9.625H4V8.375H8.375V4H9.625V8.375Z'
      fill='url(#paint0_linear_5292_92055)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_5292_92055'
        x1='9'
        y1='0.25'
        x2='9'
        y2='17.75'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='var(--primary-gradient-to)' />
        <stop offset='1' stopColor='var(--primary-gradient-from)' />
      </linearGradient>
    </defs>
  </svg>
);

export default AddAlt;
