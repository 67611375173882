import { ArrowLeft, ArrowRight } from '@carbon/icons-react';
import { Button } from 'primereact/button';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { useEffect, useMemo, useState } from 'react';
import { Translate, translateWithValues } from '../../i18n/translate';
import UserPermissionModalHeader from './userModalHeader';

import { useSelector } from 'react-redux';
import RoleService from '../../modules/Manage/Role/roleService';
import UserService from '../../modules/Manage/userService';
import { useAppDispatch } from '../../store';
import {
  selectCurrentCustomer,
  selectOktaUser,
  sendToast,
} from '../../store/slices/main/mainSlice';
import {
  fetchUsersForRole,
  selectRoleUsers,
  selectSelectedRole,
} from '../../store/slices/role/roleSlice';
import SearchBar from '../SearchInput/SearchInput';
import { goToNextPage, goToPreviousPage, onSelectAllChange } from './userPermissionUtils';

interface UserPermissionModalProps {
  visible: boolean;
  onHide: () => void;
  title: string;
}

const UserPermissionModal = ({ visible, onHide, title }: UserPermissionModalProps) => {
  const dispatch = useAppDispatch();
  const selectedRole = useSelector(selectSelectedRole);
  const roleUsers = useSelector(selectRoleUsers);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<UserShort[]>([]);
  const [initialSelectedUsers, setInitialSelectedUsers] = useState<UserShort[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [users, setUsers] = useState<UserShort[]>([]);
  const customer = useSelector(selectCurrentCustomer);
  const [totalPages, setTotalPages] = useState<number>(0);
  const oktaUser = useSelector(selectOktaUser);
  const USERS_PER_PAGE = 6;

  const intlSuccessMessage = translateWithValues('addUserForRoleSuccess', {
    roleName: selectedRole?.roleName,
  });
  const intlErrorMessage = translateWithValues('addUserForRoleError', {
    roleName: selectedRole?.roleName,
  });

  const intlSuccessDesc = translateWithValues('addUserSuccessDescription');
  const intlErrorDesc = translateWithValues('addUserErrorDescription');

  useEffect(() => {
    fetchUsers();
  }, [customer?.id, currentPage, searchQuery]);

  useEffect(() => {
    if (roleUsers) {
      const extractedUsers = roleUsers.map((user: any) => ({
        name: `${user.userIdentity?.firstName} ${user.userIdentity?.lastName}`,
        email: user.userIdentity.email,
        id: user.id,
      }));
      setSelectedUsers(selectedUsers.concat(extractedUsers));
      setInitialSelectedUsers(selectedUsers.concat(extractedUsers)); // Set initial users when the modal is opened
    } else {
      if (selectedRole && customer && customer.id)
        dispatch(fetchUsersForRole({ roleId: selectedRole?.id, customerId: customer.id }));
    }
  }, [roleUsers]);

  const fetchUsers = async () => {
    if (!customer?.id) return;
    try {
      const payload = {
        searchText: searchQuery,
      };
      const response = await UserService.getCustomerUser(
        customer?.id,
        currentPage - 1,
        USERS_PER_PAGE,
        payload,
      );
      if (response) {
        const extractedUsers: any = response.content.map((user: any) => ({
          name: `${user.userIdentity?.firstName} ${user.userIdentity?.lastName}`,
          email: user.userIdentity.email,
          id: user.id,
        }));
        setUsers(extractedUsers);
        setTotalPages(response.totalPages);
        //fetch users for this specific role to determine checkbox states
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const onUserSelectChange = (e: CheckboxChangeEvent, user: UserShort) => {
    const isChecked = e.checked ?? false;
    if (isChecked) {
      setSelectedUsers((prev) => [...prev, user]);
    } else {
      setSelectedUsers((prev) => prev.filter((u) => u.email !== user.email));
    }
  };

  const submitSelection = async () => {
    try {
      const submitData = {
        roleId: selectedRole?.id,
        userIds: selectedUsers.map((e: any) => e?.id),
        asAobo: false,
      };

      const { data, status } = await RoleService.assignUsersToRole(submitData as AssignRolePayload);
      // TODO: Remove data if not required
      if (status !== 200) {
        console.log('error');
        return;
      }

      // Show toaster on success/failure
      const toastSummary = data.result.success ? intlSuccessMessage : intlErrorMessage;
      const toastDetail = data.result.success ? intlSuccessDesc : intlErrorDesc;
      dispatch(
        sendToast({
          severity: data.result.success ? 'success' : 'error',
          summary: toastSummary,
          detail: toastDetail,
        }),
      );

      if (data.result.success) {
        if (selectedRole && customer && customer.id)
          dispatch(fetchUsersForRole({ roleId: selectedRole?.id, customerId: customer.id }));
        onHide();
      }
    } catch (error) {
      console.error('Error submitting selection', error);
    }
  };

  const changesMade = useMemo(() => {
    const initialIds = initialSelectedUsers.map((user) => user.id).sort();
    const currentIds = selectedUsers.map((user) => user.id).sort();
    return JSON.stringify(initialIds) !== JSON.stringify(currentIds);
  }, [initialSelectedUsers, selectedUsers]);

  const renderFooter = () => {
    return (
      <div className='flex flex-col justify-center items-center w-full'>
        <div className='w-40'>
          <Button
            className='w-full justify-center mb-2'
            severity='info'
            onClick={() => void submitSelection()}
            disabled={!changesMade}
          >
            <Translate value='save_selection' />
          </Button>
          <Button className='w-full justify-center' severity='contrast' onClick={onHide}>
            <Translate value='button_cancel' />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      header={<UserPermissionModalHeader title={title} />}
      visible={visible}
      style={{
        width: '25vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        right: 0,
        minWidth: '400px',
      }}
      onHide={onHide}
      draggable={false}
      className='max-h-[100%] full-screen-dialog'
      footer={renderFooter()}
    >
      <div className='w-full'>
        <SearchBar onSearch={handleSearch} />
      </div>
      <div className='card !p-3 flex flex-col mt-3'>
        {users.length > 0 && (
          <div className='bg-primary-surface-light py-3 pl-2 mb-2 flex items-center rounded-c8 text-sm-semibold'>
            <Checkbox
              onChange={(e) => onSelectAllChange(e, setSelectedAll, setSelectedUsers, users)}
              checked={selectedAll}
            ></Checkbox>
            <label htmlFor='selectAll' className='ml-2 text-sm-semibold text-neutral-2'>
              <Translate value='select_users' />
            </label>
          </div>
        )}
        <div className='transition-opacity duration-300 opacity-100'>
          {users.length > 0 ? (
            users.map((user, index) => (
              <div key={index} className='flex gap-4 mt-4 pl-2'>
                <Checkbox
                  className='!mt-[3px]'
                  onChange={(e) => onUserSelectChange(e, user)}
                  checked={selectedUsers.findIndex((u) => u.id === user.id) !== -1}
                />
                <div>
                  <div className='text-sm-medium text-neutral-2'>
                    {user.name}
                    {user.email == oktaUser?.email && (
                      <span className='text-success-green'>| Yourself</span>
                    )}
                  </div>
                  <div className='text-sm-regular text-neutral-3'>{user.email}</div>
                </div>
              </div>
            ))
          ) : (
            <div className='text-center text-neutral-3'>No users found</div>
          )}
        </div>
      </div>

      <div className='flex justify-between items-center mt-4 border-t-[1px] border-neutral-border pt-4'>
        <div
          className='border-[1px] rounded border-neutral-border p-2 ml-3 cursor-pointer'
          onClick={() => goToPreviousPage(currentPage, setCurrentPage)}
        >
          <ArrowLeft className='text-neutral-1' />
        </div>
        <div className='text-sm text-neutral-3'>
          Page <span className='text-black'>{currentPage}</span> of {totalPages}
        </div>
        <div
          className='border-[1px] rounded border-neutral-border p-2 mr-3 cursor-pointer'
          onClick={() => goToNextPage(currentPage, setCurrentPage, totalPages)}
        >
          <ArrowRight className='text-neutral-1' />
        </div>
      </div>
    </Dialog>
  );
};

export default UserPermissionModal;
