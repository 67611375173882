import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MoreAction } from '../../../../../icons';
import { Translate } from '../../../../../i18n/translate';
import { Status } from '../../../../../components';

const Table = ({ roles }: any) => {
  const renderDateAssigned = (role: any) => {
    return (
      <span className='text-sm-medium text-neutral-2'>
        {role.dateAssigned ? role.dateAssigned : 'N/A'}
      </span>
    );
  };

  // const renderStatus = (role: any) => {
  //   const statusClass = role.status === 'Active' ? 'status-active' : 'status-inactive';
  //   return <span className={`status-label ${statusClass}`}>{role.status}</span>;
  // };

  const renderActions = () => {
    return (
      <div className='actions-column'>
        <MoreAction />
      </div>
    );
  };

  return (
    <div className='table-card'>
      <DataTable value={roles} size='large' dataKey='id' className='custom-table'>
        <Column
          field='role'
          header='Role'
          className='role-column'
          body={(role: any) => (
            <Translate value={role.roleName} className='text-neutral-3 text-sm-regular' />
          )}
        ></Column>
        <Column
          field='dateAssigned'
          header='Date assigned'
          body={renderDateAssigned}
          className='date-column'
        ></Column>
        {/* <Column
          field='status'
          header='Status'
          body={renderStatus}
          className='status-column'
        ></Column> */}
        <Column
          sortable
          field='status'
          header='Status'
          body={(client: Client) => <Status status={client.status} />}
        ></Column>
        <Column header='Actions' body={renderActions} className='actions-column'></Column>
      </DataTable>
    </div>
  );
};

export default Table;
