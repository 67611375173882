import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import { selectLoading, sendToast } from '../../store/slices/main/mainSlice';
import Confirm from '../Confirm';

const ApprovalConfirm = ({
  showApproveConfirm,
  setShowApproveConfirm,
  apporveOrject,
  approveFn,
  id,
  type,
}: {
  apporveOrject: 'approve' | 'reject' | undefined;
  showApproveConfirm: boolean;
  setShowApproveConfirm: (show: boolean, approved: boolean) => void;
  approveFn: (this: void, id: number, requestBody: any) => Promise<any>;
  id?: number;
  type: 'customer' | 'user' | 'approval';
}) => {
  const [userComment, setUserComment] = useState('');
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectLoading);

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const approve = async () => {
    if (!id) {
      return;
    }
    const requestBody = {
      action: apporveOrject === 'approve' ? 'APPROVED' : 'REJECTED',
      userComment,
    };

    const { data, status } = await approveFn(id, requestBody);
    if (status !== 200 || !data.success) {
      showToast(
        'error',
        'error',
        apporveOrject === 'approve' ? `approve.${type}.error` : `reject.${type}.error`,
      );
      setShowApproveConfirm(false, false);
    } else {
      showToast(
        'success',
        'success',
        apporveOrject === 'approve' ? `approve.${type}.success` : `reject.${type}.success`,
      );
      setShowApproveConfirm(false, true);
    }
  };

  return (
    <Confirm
      onCancel={() => setShowApproveConfirm(false, false)}
      title={apporveOrject === 'approve' ? 'approve.comment' : 'reject.comment'}
      message={apporveOrject === 'approve' ? 'approve.comment.message' : 'reject.comment.message'}
      onConfirm={approve}
      confirmLabel={apporveOrject == 'approve' ? 'approve' : 'reject'}
      visible={showApproveConfirm}
      onCommentChange={(comment) => {
        setUserComment(comment);
      }}
      loading={isLoading}
    />
  );
};

export default ApprovalConfirm;
