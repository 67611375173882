import { Editor, EditorTextChangeEvent } from 'primereact/editor';

interface RichEditor {
  value?: string;
  name?: string;
  onChange?: (e: EditorTextChangeEvent, name: string) => void;
}

const RichEditor = ({ value, name, onChange }: RichEditor) => {
  const header = (
    <span className='ql-formats'>
      <button className='ql-bold'></button>
      <button className='ql-italic'></button>
      <button className='ql-underline'></button>
      <button className='ql-blockquote'></button>
      <button className='ql-image'></button>
      <button className='ql-link'></button>
      <button className='ql-list' value='ordered'></button>
      <button className='ql-list' value='bullet'></button>
    </span>
  );

  return (
    <Editor
      value={value}
      name={name}
      className='custom-editor'
      onTextChange={(e) => {
        if (e.htmlValue && onChange && name) {
          onChange(e, name);
        }
      }}
      headerTemplate={header}
      style={{ height: '90px' }}
    />
  );
};

export default RichEditor;
