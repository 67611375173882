import { Button } from 'primereact/button';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { PageHead } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { PageHeadContext } from '../../../../providers/PageHead';
import PaymentsTable from './PaymentsTable';

const Dashboard = () => {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const navigate = useNavigate();

  useEffect(() => {
    setPageHeadData({
      title: 'payment_requests.title',
      description: 'payment_requests.description',
    });
  }, []);

  return (
    <div className='p-8 bg-white rounded-c8 border border-neutral-surface-dark'>
      <div className='flex justify-between items-center gap-4'>
        <PageHead title='payment_table.title' description='payment_table.description' />
        <div>
          <Button
            severity='info'
            onClick={() => {
              navigate('/payment/rate-requests/add');
            }}
          >
            <Translate value='request_new_quote' />
          </Button>
        </div>
      </div>
      <PaymentsTable />
      <div className='flex justify-center gap-3 pt-6'>
        <Button severity='contrast'>
          <Translate value={'reject_select_quotas'} />
        </Button>
        <Button severity='info'>
          <Translate value={'accept_select_rates'} />
        </Button>
        <Button severity='contrast'>
          <Translate value={'send_select_quotas'} />
        </Button>
      </div>
    </div>
  );
};

export default Dashboard;
