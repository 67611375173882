import { Time } from '@carbon/icons-react';
import { useEffect, useMemo, useState } from 'react';
import { Translate } from '../../../../i18n/translate';

const CountdownTimer = ({
  createdTime,
  onTimeOut,
}: {
  createdTime: string;
  onTimeOut: () => void;
}) => {
  // Calculate the initial time left only once when the component mounts
  const initialTimeLeft = useMemo(() => {
    const createdTimeWithZ = createdTime.endsWith('Z') ? createdTime : `${createdTime}Z`;
    const now = new Date().getTime();
    const expiry = new Date(createdTimeWithZ).getTime() + 1 * 60 * 1000; // 5 minutes in milliseconds
    return Math.max(0, Math.floor((expiry - now) / 1000)); // time left in seconds
  }, [createdTime]);

  const [timeLeft, setTimeLeft] = useState(initialTimeLeft);

  useEffect(() => {
    if (timeLeft === 0) {
      onTimeOut();
      return;
    }

    const timerInterval = setInterval(() => {
      setTimeLeft((prev) => Math.max(0, prev - 1));
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
  };

  const progress = initialTimeLeft > 0 ? ((initialTimeLeft - timeLeft) / initialTimeLeft) * 100 : 0;

  const radius = 77.234;
  const strokeDashArray = 2 * Math.PI * radius;
  const strokeDashOffset = strokeDashArray * (progress / 100);
  const angle = (1 - progress / 100) * 2 * Math.PI;
  const cx_center = 124.053;
  const cy_center = 92.0005;
  const cx = cx_center + radius * Math.cos(angle - Math.PI / 2);
  const cy = cy_center + radius * Math.sin(angle - Math.PI / 2);
  const circleColor = timeLeft <= 60 ? '#A70202' : timeLeft <= 120 ? '#EAA22F' : '#078A04';

  return (
    <div className='relative'>
      <svg
        height='170'
        width='170'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        preserveAspectRatio='xMidYMid meet'
        viewBox='39.61 9.66 170 161.79'
      >
        <path
          d='M124.053 14.5537C134.224 14.5537 144.295 16.5569 153.691 20.449C163.087 24.3411 171.625 30.0458 178.816 37.2374C186.008 44.429 191.713 52.9666 195.605 62.3629C199.497 71.7592 201.5 81.8301 201.5 92.0005C201.5 102.171 199.497 112.242 195.605 121.638C191.713 131.034 186.008 139.572 178.816 146.764C171.625 153.955 163.087 159.66 153.691 163.552C144.295 167.444 134.224 169.447 124.053 169.447C113.883 169.447 103.812 167.444 94.4156 163.552C85.0193 159.66 76.4817 153.955 69.2901 146.764C62.0985 139.572 56.3938 131.034 52.5017 121.638C48.6097 112.242 46.6064 102.171 46.6064 92.0005C46.6065 81.83 48.6097 71.7591 52.5017 62.3629C56.3938 52.9666 62.0985 44.4289 69.2901 37.2373C76.4817 30.0457 85.0194 24.341 94.4157 20.449C103.812 16.5569 113.883 14.5537 124.053 14.5537Z'
          stroke='#D8DFE9'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='0.5 8'
        />

        <path
          d='M124.053 14.5537C134.224 14.5537 144.295 16.5569 153.691 20.449C163.087 24.3411 171.625 30.0458 178.816 37.2374C186.008 44.429 191.713 52.9666 195.605 62.3629C199.497 71.7592 201.5 81.8301 201.5 92.0005C201.5 102.171 199.497 112.242 195.605 121.638C191.713 131.034 186.008 139.572 178.816 146.764C171.625 153.955 163.087 159.66 153.691 163.552C144.295 167.444 134.224 169.447 124.053 169.447C113.883 169.447 103.812 167.444 94.4156 163.552C85.0193 159.66 76.4817 153.955 69.2901 146.764C62.0985 139.572 56.3938 131.034 52.5017 121.638C48.6097 112.242 46.6064 102.171 46.6064 92.0005C46.6065 81.83 48.6097 71.7591 52.5017 62.3629C56.3938 52.9666 62.0985 44.4289 69.2901 37.2373C76.4817 30.0457 85.0194 24.341 94.4157 20.449C103.812 16.5569 113.883 14.5537 124.053 14.5537Z'
          stroke={circleColor}
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray={strokeDashArray}
          strokeDashoffset={strokeDashOffset}
        />

        <circle cx={cx} cy={cy} r='5.10638' fill={circleColor} />
      </svg>

      <div className='h-full w-full absolute top-0 left-0'>
        <div className='h-[calc(100%-30px)] grid gap-2 place-content-center place-items-center text-center rounded-[50%] m-[15px] bg-white'>
          <Time />
          <Translate value='timeLeft' className='text-neutral-3' />
          <span style={{ color: circleColor }} className='text-2xl-semibold font-semibold'>
            {formatTime(timeLeft)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
