import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import WebFont from 'webfontloader';
import ClientService from '../../modules/Client/clientService';
import { selectAoboClient, selectCurrentCustomer } from '../../store/slices/main/mainSlice';
import { updateColorVariables } from '../../utils/helper';
import Header from '../Header';
import InactivityTimeout from '../InactivityTimeout';
import ScrollToTop from '../ScrollToTop';
import Sidebar from '../Sidebar';
import './Layout.css';

function Layout() {
  const [sideBarVisible, setSideBarVisible] = useState(true);
  const [brandingCompleted, setBrandingCompleted] = useState(false);
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const selectedAoboClient = useSelector(selectAoboClient);

  useEffect(() => {
    if (selectedCustomer) {
      getBranding();
    }
  }, [selectedCustomer]);

  const getBranding = async () => {
    if (!selectedCustomer?.id || selectedCustomer.id == 1 || selectedCustomer.aobo) {
      setBrandingCompleted(true);
      return;
    }

    const branding = await ClientService.getCustomerBranding(selectedCustomer.id);
    const isClientExpandedLogoExist = branding?.find((b) => b.type === 'LOGOEXPANDED');
    const isClientCollapsedLogoExist = branding?.find((b) => b.type === 'LOGOCOLLAPSED');
    const isClientFavIconExist = branding?.find((b) => b.type === 'FAVICON');
    const iBrandingColorsExists = branding?.find((b) => b.type === 'BRANDINGCOLORS');

    if (isClientExpandedLogoExist) {
      const response = await ClientService.donwload(selectedCustomer?.id, 'LOGOEXPANDED');
      if (response) {
        const imageUrl = URL.createObjectURL(response);
        document.documentElement.style.setProperty('--client-logo-expanded', `url(${imageUrl})`);
      }
    }

    if (isClientCollapsedLogoExist) {
      const response = await ClientService.donwload(selectedCustomer?.id, 'LOGOCOLLAPSED');
      if (response) {
        const imageUrl = URL.createObjectURL(response);
        document.documentElement.style.setProperty('--client-logo-expanded', `url(${imageUrl})`);
      }
    }
    if (isClientFavIconExist) {
      const response = await ClientService.donwload(selectedCustomer?.id, 'FAVICON');
      if (response) {
        const imageUrl = URL.createObjectURL(response);
        document.querySelector('link[rel="icon"]')?.setAttribute('href', imageUrl);
      }
    }

    if (iBrandingColorsExists) {
      const content = JSON.parse(iBrandingColorsExists.textContent);
      if (content.colorConfig && content.colorFormData) {
        updateColorVariables(content.colorConfig, content.colorFormData);
      }
      if (content.defaultFont) {
        WebFont.load({
          google: {
            families: [`${content.defaultFont.font}`],
          },
        });
        document.documentElement.style.setProperty('--font-family', content.defaultFont.font);
      }
    }

    setBrandingCompleted(true);
  };

  return (
    <>
      {brandingCompleted && (
        <div className='flex max-w-full h-screen bg-background'>
          <div className=' h-full'>
            <Sidebar visible={sideBarVisible} setVisible={setSideBarVisible} />
          </div>
          <div
            className={`overflow-y-auto content ${sideBarVisible ? 'content-shift' : 'content-collapsed'}`}
          >
            <Header />
            <div>
              <div className={`${selectedAoboClient ? 'h-[118px]' : 'h-[78px]'}`}></div>
              <div className='mb-[120px]'>
                <ScrollToTop />
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const RootLayout = () => {
  return (
    <>
      <InactivityTimeout />
      <Layout />
    </>
  );
};

export default RootLayout;
