import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { TabPanel, TabView } from 'primereact/tabview';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { InheritedMenu, PageHead } from '../../../../components';
import useRole from '../../../../hooks/useRoles';
import { Translate } from '../../../../i18n/translate';
import { PageHeadContext } from '../../../../providers/PageHead';
import { useAppDispatch } from '../../../../store';
import { selectCurrentCustomer } from '../../../../store/slices/main/mainSlice';
import { fetchBuyCurrency, fetchSellCurrency } from '../../../../store/slices/refdata/actions';
import ClientService from '../../../Client/clientService';
import CreateFees from '../AddFees';
import CreateMargin from '../AddMargin';
import managePricingRole from '../managePricingRole';
import Fees from './Fees';
import './index.css';
import Margins from './Margins';

const Dashboard = ({ isCustomerPricing = false }: { isCustomerPricing?: boolean }) => {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const menu = useRef<Menu>(null);
  const selectedCurrentCustomer = useSelector(selectCurrentCustomer);
  const [createMarginToggle, setCreateMarginToggle] = useState(false);
  const [createFeesToggle, setCreateFeesToggle] = useState(false);
  const [requestMRefresh, setMRequestRefresh] = useState(false);
  const [requestFRefresh, setFRequestRefresh] = useState(false);
  const [editMEntity, setEditEntity] = useState<Margin>();
  const [editFEntity, setEditFEntity] = useState<Fees>();
  const { clientId } = useParams<{ clientId: string }>();
  const [client, setClient] = useState<Client | undefined>();
  const [isViewMode, setIsViewMode] = useState(false);
  const dispatch = useAppDispatch();
  const { hasRole } = useRole();

  useEffect(() => {
    if (isCustomerPricing && clientId) {
      getCustomer();
      dispatch(fetchSellCurrency(Number(clientId)));
      dispatch(fetchBuyCurrency(Number(clientId)));
    } else {
      if (selectedCurrentCustomer && selectedCurrentCustomer.id) {
        dispatch(fetchSellCurrency(selectedCurrentCustomer.id));
        dispatch(fetchBuyCurrency(selectedCurrentCustomer.id));
      }
    }
  }, [clientId, selectedCurrentCustomer]);

  const getCustomer = async () => {
    if (!clientId) {
      return;
    }
    const _client = await ClientService.getClientById(clientId);
    if (_client) {
      setClient(_client);
    }
  };

  const items: MenuItem[] = [
    {
      label: 'Create Margins',
      onClick: () => {
        setCreateMarginToggle(true);
        setMRequestRefresh(false);
      },
    },
    {
      label: 'Create Fees',
      onClick: () => {
        setCreateFeesToggle(true);
        setFRequestRefresh(false);
      },
    },
  ];

  useEffect(() => {
    setPageHeadData({
      title: '',
      description: '',
    });
  }, []);

  return (
    <>
      {createMarginToggle && (
        <CreateMargin
          visible={createMarginToggle}
          isViewMode={isViewMode}
          editEntity={editMEntity}
          client={client}
          setCreateMarginToggle={(v) => {
            setEditEntity(undefined);
            setCreateMarginToggle(v);
            setMRequestRefresh(true);
            setIsViewMode(false);
          }}
        />
      )}
      {createFeesToggle && (
        <CreateFees
          client={client}
          visible={createFeesToggle}
          isViewMode={isViewMode}
          editEntity={editFEntity}
          setCreateFeesToggle={(v) => {
            setEditFEntity(undefined);
            setCreateFeesToggle(v);
            setFRequestRefresh(true);
            setIsViewMode(false);
          }}
        />
      )}
      <div className='px-[20px] lg:px-[49px] py-[16px] bg-white border-b border-neutral-border'>
        <PageHead
          title='managedPricing.dashboard.title'
          description='managedPricing.dashboard.description'
          button={
            hasRole(managePricingRole.CreateManagePricing) && (
              <Button
                severity='info'
                className='h-[40px]'
                onClick={(event) => {
                  menu.current?.toggle(event);
                }}
              >
                <Translate value='createPricingBtn' />
                <InheritedMenu items={items} ref={menu} popupAlign='right' />
              </Button>
            )
          }
        />
      </div>
      <div className='managed-pricing'>
        <div className='dashboard-padding'>
          <TabView className='stone-tabs justify-start'>
            {(!isCustomerPricing || client) && (
              <TabPanel header='Margins'>
                <Margins
                  isCustomerPricing={isCustomerPricing}
                  client={client}
                  refreshData={requestMRefresh}
                  onEdit={(margin) => {
                    setMRequestRefresh(false);
                    setCreateMarginToggle(true);
                    setEditEntity(margin);
                  }}
                  onView={(margin) => {
                    setCreateMarginToggle(true);
                    setEditEntity(margin);
                    setIsViewMode(true);
                  }}
                />
              </TabPanel>
            )}
            {(!isCustomerPricing || client) && (
              <TabPanel header='Fees'>
                <Fees
                  isCustomerPricing={isCustomerPricing}
                  client={client}
                  refreshData={requestFRefresh}
                  onEdit={(fees) => {
                    setFRequestRefresh(false);
                    setCreateFeesToggle(true);
                    setEditFEntity(fees);
                  }}
                  onView={(fees) => {
                    setCreateFeesToggle(true);
                    setEditFEntity(fees);
                    setIsViewMode(true);
                  }}
                />
              </TabPanel>
            )}
          </TabView>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
