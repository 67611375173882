import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import './select.css';

interface SelectProps {
  options: any;
  value: string;
  onChange: (e: SelectButtonChangeEvent) => void;
}

const Select = ({ options, value, onChange }: SelectProps) => {
  return (
    <SelectButton
      className='custom-select'
      options={options}
      optionLabel='name'
      value={value}
      onChange={onChange}
      allowEmpty={false}
    />
  );
};

export default Select;
