import { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { PageHeadContext } from '../../../../providers/PageHead';
import { AddOrEditOrView } from '../../../../types';
import UserDetails from '../AddUser/UserDetails';

const ViewUser = ({ mode }: { mode: AddOrEditOrView }) => {
  const { userId: paramUserId } = useParams<{ userId?: string }>();
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  useEffect(() => {
    setPageHeadData({
      title: 'user.view.title',
      description: 'user.view.description',
      className: 'pb-[16px] pr-4',
    });
  }, [setPageHeadData]);

  return (
    <UserDetails
      mode={mode}
      onSuccess={() => {
        // Do nothing
      }}
      userId={paramUserId}
    />
  );
};

export default ViewUser;
