import { Outlet } from 'react-router';
import { PageHeadProvider } from '../../../providers/PageHead';

const UserOutlet = () => {
  return (
    <>
      <PageHeadProvider>
        <div className='dashboard-padding'>
          <Outlet />
        </div>
      </PageHeadProvider>
    </>
  );
};
export default UserOutlet;
