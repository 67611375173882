const BlueInfo = () => (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11 0.5C8.9233 0.5 6.89323 1.11581 5.16652 2.26957C3.4398 3.42332 2.09399 5.0632 1.29927 6.98182C0.504549 8.90045 0.296614 11.0116 0.701759 13.0484C1.1069 15.0852 2.10693 16.9562 3.57538 18.4246C5.04383 19.8931 6.91476 20.8931 8.95156 21.2982C10.9884 21.7034 13.0996 21.4955 15.0182 20.7007C16.9368 19.906 18.5767 18.5602 19.7304 16.8335C20.8842 15.1068 21.5 13.0767 21.5 11C21.5 8.21523 20.3938 5.54451 18.4246 3.57538C16.4555 1.60625 13.7848 0.5 11 0.5ZM11 5C11.2225 5 11.44 5.06598 11.625 5.1896C11.81 5.31321 11.9542 5.48891 12.0394 5.69448C12.1245 5.90005 12.1468 6.12625 12.1034 6.34448C12.06 6.56271 11.9528 6.76316 11.7955 6.9205C11.6382 7.07783 11.4377 7.18498 11.2195 7.22838C11.0013 7.27179 10.7751 7.24951 10.5695 7.16436C10.3639 7.07922 10.1882 6.93502 10.0646 6.75002C9.94098 6.56501 9.875 6.3475 9.875 6.125C9.875 5.82663 9.99353 5.54048 10.2045 5.3295C10.4155 5.11853 10.7016 5 11 5ZM14 17.0938H8V15.4062H10.1563V11.0938H8.75V9.40625H11.8438V15.4062H14V17.0938Z'
      fill='url(#paint0_linear_175_9212)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_175_9212'
        x1='11'
        y1='0.5'
        x2='11'
        y2='21.5'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='var(--primary-gradient-to)' />
        <stop offset='1' stopColor='var(--primary-gradient-from)' />
      </linearGradient>
    </defs>
  </svg>
);
export default BlueInfo;
