const MoreAction = () => (
  <span className='cursor-pointer'>
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 6.25C10.6904 6.25 11.25 5.69036 11.25 5C11.25 4.30964 10.6904 3.75 10 3.75C9.30964 3.75 8.75 4.30964 8.75 5C8.75 5.69036 9.30964 6.25 10 6.25ZM10 11.25C10.6904 11.25 11.25 10.6904 11.25 10C11.25 9.30964 10.6904 8.75 10 8.75C9.30964 8.75 8.75 9.30964 8.75 10C8.75 10.6904 9.30964 11.25 10 11.25ZM11.25 15C11.25 15.6904 10.6904 16.25 10 16.25C9.30964 16.25 8.75 15.6904 8.75 15C8.75 14.3096 9.30964 13.75 10 13.75C10.6904 13.75 11.25 14.3096 11.25 15Z'
        fill='url(#paint0_linear_388_26428)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_388_26428'
          x1='10'
          y1='3.75'
          x2='10'
          y2='16.25'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='var(--primary-gradient-to)' />
          <stop offset='1' stopColor='var(--primary-gradient-from)' />
        </linearGradient>
      </defs>
    </svg>
  </span>
);

export default MoreAction;
