import { TabPanel, TabView } from 'primereact/tabview';
import { useContext, useEffect } from 'react';
import { PageHeadContext } from '../../../../providers/PageHead';
import AvailableCurrencies from './AvailableCurrencies';
import CurrencyManagement from './CurrencyManagement';
import './index.scss';

const Dashboard = () => {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  useEffect(() => {
    setPageHeadData({
      title: '',
      description: '',
    });
  }, []);
  return (
    <TabView className='stone-tabs'>
      <TabPanel header='Currency Management'>
        <CurrencyManagement />
      </TabPanel>
      <TabPanel header='Available Currencies'>
        <AvailableCurrencies />
      </TabPanel>
    </TabView>
  );
};

export default Dashboard;
