import React from 'react';

const WidgetView = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00488 15V7.5H14.5049V15H7.00488ZM7.00488 25.5V18H14.5049V25.5H7.00488ZM17.5049 15V7.5H25.0049V15H17.5049ZM17.5049 25.5V18H25.0049V25.5H17.5049Z"
        fill="currentColor"
      />
      <rect x="0.504883" y="1" width="31" height="30" rx="7.5" stroke="currentColor" />
    </svg>
  );
};

export default WidgetView;
