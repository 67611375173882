import { RoleGuard } from '../../../components';
import { AddOrEditOrView } from '../../../types';
import AddUser from './AddUser';
import Dashboard from './Dashboard';
import userRole from './userRole';
import ViewUser from './ViewUser';

const userRoutes = [
  {
    id: 'userDashboard',
    index: true,
    element: <Dashboard />,
  },
  {
    id: 'usersWithCustomer',
    index: true,
    path: ':customerId',
    element: <Dashboard />,
  },
  {
    id: 'addUser',
    path: 'add',
    element: (
      <RoleGuard roles={userRole.AddUser}>
        <AddUser />
      </RoleGuard>
    ),
    handle: {
      breadcrumb: 'Add New User',
    },
  },
  {
    id: 'Add user to customer',
    path: ':customerId/add',
    element: (
      <RoleGuard roles={userRole.AddUser}>
        <AddUser />
      </RoleGuard>
    ),
    handle: {
      breadcrumb: 'Add New User',
    },
  },
  {
    id: 'editUser',
    path: 'edit/:userId',
    element: (
      <RoleGuard roles={userRole.UpdateUser}>
        <AddUser />
      </RoleGuard>
    ),
    handle: {
      breadcrumb: 'Edit User',
    },
  },
  {
    id: 'viewUser',
    path: 'view/:userId',
    element: (
      <RoleGuard roles={userRole.ViewUser}>
        <ViewUser mode={AddOrEditOrView.View} />
      </RoleGuard>
    ),
    handle: {
      breadcrumb: 'View User',
    },
  },
  {
    id: 'approverUser',
    path: 'approve/:userId',
    element: (
      <RoleGuard roles={userRole.ApproveUser}>
        <ViewUser mode={AddOrEditOrView.Approve} />
      </RoleGuard>
    ),
    handle: {
      breadcrumb: 'Approval',
    },
  },
];

export default userRoutes;
