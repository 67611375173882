import { useMutation } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CurrencySelect, Status } from '../../../../components';
import QuoteAmountInput from '../../../../components/QuoteAmount';
import useRole from '../../../../hooks/useRoles';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import Switch from '../../../../icons/Switch';
import { useAppDispatch } from '../../../../store';
import { selectQuickQuote, sendToast } from '../../../../store/slices/main/mainSlice';
import { selectCustomerCurrencies } from '../../../../store/slices/refdata/refdataSlice';
import { QuoteStatus, RequestQuickQuoteType } from '../../../../types';
import paymentRole from '../../paymentRole';
import PaymentService from '../../paymentService';
import CountdownTimer from '../CountdownTimer';

interface QuoteAmountProps {
  onSaveAndProceed?: (currency: string, quoteId?: string) => void;
  handleStepperValidation?: (formName: RequestQuickQuoteType, isValid: boolean) => void;
}

export const QuoteAmount = ({ onSaveAndProceed, handleStepperValidation }: QuoteAmountProps) => {
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<any>();
  const dispatch = useAppDispatch();
  const [quoteStatus, setQuoteStatus] = useState(QuoteStatus.AddQuote);
  const [buyCurrency, setBuyCurrency] = useState<CurrencyConfiguration>();
  const [sellCurrency, setSellCurrency] = useState<CurrencyConfiguration>();
  const [quickQuote, setQuickQuote] = useState<QuickQouteProps | null>(null);
  const editQuote = useSelector(selectQuickQuote);
  const currencies = useSelector(selectCustomerCurrencies);
  const [currentEditableField, setCurrentEditableField] = useState<
    'margin' | 'rate' | 'inverse' | null
  >(null);

  const { hasRole } = useRole();
  const buyAmountValue = watch('buyAmount');
  const sellAmountValue = watch('sellAmount');
  const buyCurrencyValue = watch('buyCurrency');
  const sellCurrencyValue = watch('sellCurrency');

  useEffect(() => {
    setBuyCurrency(
      currencies.find((val) => {
        return buyCurrencyValue == val.isocode;
      }),
    );
  }, [buyCurrencyValue]);

  useEffect(() => {
    setSellCurrency(
      currencies.find((val) => {
        return sellCurrencyValue == val.isocode;
      }),
    );
  }, [sellCurrencyValue]);

  useEffect(() => {
    if (buyCurrency && buyAmountValue) {
      setValue('buyAmount', parseFloat(buyAmountValue).toFixed(buyCurrency.amountPrecision));
    }
  }, [buyCurrency]);

  useEffect(() => {
    if (sellCurrency && sellAmountValue) {
      setValue('sellAmount', parseFloat(sellAmountValue).toFixed(sellCurrency.amountPrecision));
    }
  }, [sellCurrency]);

  const saveQuickQuote = (data: any) => {
    const newData: any = {
      sellCurrency: data.sellCurrency,
      buyCurrency: data.buyCurrency,
      sellAmount: data.sellAmount ? data.sellAmount : undefined,
      buyAmount: data.buyAmount ? data.buyAmount : undefined,
    };
    if (
      quickQuote &&
      quoteStatus !== QuoteStatus.AddQuote &&
      quoteStatus !== QuoteStatus.QuoteExpired
    ) {
      newData['quoteId'] = quickQuote.quoteId;
      newData['margin'] = data.quoteMargin;
    }
    getQuickQuoteMutation.mutate(newData);
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const onSubmit = (data: any) => {
    saveQuickQuote(data);
  };

  const showErrorToast = () => {
    if (handleStepperValidation) {
      handleStepperValidation(RequestQuickQuoteType.QuoteAmounCurrency, isValid);
    }
    if (
      errors['buyAmount']?.type === 'max' ||
      errors['buyAmount']?.type === 'min' ||
      errors['sellAmount']?.type === 'max' ||
      errors['sellAmount']?.type === 'min'
    )
      showToast('error', 'quickQuoteValidationErrorTitle', 'quickQuoteValidationErrorDesc');
    else
      showToast('error', 'client.field.validation.error', 'client.field.validation.error.detail');
  };

  const getQuickQuoteMutation = useMutation({
    mutationFn: async (newData: any) => {
      setQuoteStatus(!newData['quoteId'] ? QuoteStatus.SubmittingQuote : QuoteStatus.RecievingData);
      const response =
        !newData['quoteId'] || quoteStatus === QuoteStatus.QuoteExpired
          ? await PaymentService.getQuickQuote(newData)
          : await PaymentService.recalculateQuote(newData);
      if (response === 'Error') {
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'failed',
            detail: !newData['quoteId'] ? 'createQuickQuoteError' : 'recalculateQuoteError',
          }),
        );
        return;
      }
      if (handleStepperValidation) {
        handleStepperValidation(RequestQuickQuoteType.QuoteAmounCurrency, true);
      }

      setQuickQuote(response);
      if (onSaveAndProceed) onSaveAndProceed(buyCurrencyValue, response.quoteId);
      setValue('quoteMargin', response.appliedMargin);
      setValue('indicativeRate', response.rate);
      setValue('inverse', response.inverseRate);
      setValue('buyAmount', response.buyAmount);
      setValue('sellAmount', response.sellAmount);
      resetEditableState();
      setQuoteStatus(QuoteStatus.DataRecieved);
      return response;
    },
  });

  const handleAmountChange = (field: 'buyAmount' | 'sellAmount') => {
    if (field === 'buyAmount' && buyAmountValue) setValue('sellAmount', '');
    else if (field === 'sellAmount' && sellAmountValue) setValue('buyAmount', '');
  };

  const handleFieldEdit = (field: 'margin' | 'rate' | 'inverse') => {
    if (!currentEditableField) {
      setCurrentEditableField(field);
    }
  };

  const handleDecimalInput = (e: React.KeyboardEvent<HTMLInputElement>, precesion?: number) => {
    const input = e.currentTarget.value;
    const key = e.key;
    // Allow control keys like backspace, arrow keys, etc.
    if (['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(key)) {
      return;
    }
    // // Check if the input is a number or a decimal point and validate
    if (!/^[0-9.]$/.test(key)) {
      e.preventDefault();
    }

    // // Prevent more than one decimal point
    if (key === '.' && input.includes('.')) {
      e.preventDefault();
    }
    // Prevent more than two decimal places
    const [, decimals] = input.split('.');
    if (decimals && precesion && decimals.length >= precesion) {
      e.preventDefault();
    }
  };

  const resetEditableState = () => {
    setCurrentEditableField(null);
  };

  const handleCurrencySwitch = () => {
    const { buyAmount, sellAmount } = getValues();
    setValue('buyAmount', sellAmount || '');
    setValue('sellAmount', buyAmount || '');
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes('/add')) {
      setQuoteStatus(QuoteStatus.AddQuote);
    } else if (currentUrl.includes('/edit')) {
      setQuickQuote(editQuote);
      setQuoteStatus(QuoteStatus.DataRecieved);
      if (editQuote.buyCurrency && editQuote.quoteId && onSaveAndProceed) {
        onSaveAndProceed(editQuote.buyCurrency, editQuote.quoteId);
      }
      setValue('quoteMargin', editQuote?.appliedMargin);
      setValue('indicativeRate', editQuote?.rate);
      setValue('inverse', editQuote?.inverseRate);
      setValue('buyAmount', editQuote?.buyAmount);
      setValue('sellAmount', editQuote?.sellAmount);
      setValue('sellCurrency', editQuote?.sellCurrency);
      setValue('buyCurrency', editQuote?.buyCurrency);
    }
  }, [editQuote]);

  return (
    <>
      <div className='rounded-c8 shadow-c bg-white p-8'>
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='quoteAmount' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='quoteAmount.description' />
            </div>
          </div>
          {quoteStatus !== QuoteStatus.AddQuote && (
            <div className='h-fit'>
              <Status status={quoteStatus} />
            </div>
          )}
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onSubmit, showErrorToast)}>
          <div className='mt-8 flex flex-col lg:flex-row gap-4 text-neutral-600 text-sm font-medium'>
            <CurrencySelect
              label='buy_currency'
              placeholder='currency'
              name='buyCurrency'
              error={errors['buyCurrency']}
              isRequired
              control={control}
              rules={{ required: true }}
              currencySource='buy'
              isEditable={quoteStatus === QuoteStatus.AddQuote}
              className='w-full'
              includeAll={false}
            />

            <QuoteAmountInput
              formRegister={register('buyAmount', {
                required: !sellAmountValue,
                onChange: () => handleAmountChange('buyAmount'),
                min: {
                  value: buyCurrency?.minAmount?.value || 0,
                  message: translateWithValues('sellAmountMin', {
                    minAmount: buyCurrency?.minAmount?.value + ' ' + buyCurrency?.isocode,
                  }),
                },
                ...(buyCurrency?.maxAmount?.value &&
                  buyCurrency?.maxAmount?.value > 0 && {
                    max: {
                      value: buyCurrency.maxAmount.value,
                      message: translateWithValues('sellAmountMax', {
                        maxAmount: buyCurrency?.maxAmount.value + ' ' + buyCurrency?.isocode,
                      }),
                    },
                  }),
                pattern: {
                  value: RegExp(
                    `^\\d+(\\.\\d{${buyCurrency?.amountPrecision && buyCurrency?.amountPrecision > 0 ? 1 : 0},${buyCurrency?.amountPrecision}})?$`,
                  ),
                  message: translateWithValues('amountValidation', {
                    validationValue: buyCurrency?.amountPrecision,
                  }),
                },
              })}
              onKeyDown={(e) => handleDecimalInput(e, buyCurrency?.amountPrecision)}
              error={errors.buyAmount}
              label='buyAmount'
              placeholder='amount'
              className='w-full'
              isRequired={!sellAmountValue}
              isEditMode={quoteStatus == QuoteStatus.AddQuote}
              disabled={Boolean(sellAmountValue)}
              isEditable={quoteStatus !== QuoteStatus.AddQuote}
              value={buyAmountValue}
              setQuoteStatus={(val: QuoteStatus) => {
                setQuoteStatus(val);
                setQuickQuote(null);
                setValue('sellAmount', '');
                setValue('buyAmount', '');
                setQuickQuote(quickQuote);
                if (onSaveAndProceed) onSaveAndProceed(buyCurrencyValue);
              }}
            />

            <div className='self-end px-4 md:px-0'>
              <Button
                className='text-transparent transform rotate-90 lg:rotate-0 !py-0 !px-0'
                onClick={handleCurrencySwitch}
              >
                <Switch />
              </Button>
            </div>

            <CurrencySelect
              label='sell_currency'
              error={errors['sellCurrency']}
              placeholder='currency'
              name='sellCurrency'
              isRequired
              control={control}
              rules={{ required: true }}
              currencySource='sell'
              isEditable={quoteStatus === QuoteStatus.AddQuote}
              className='w-full'
            />

            <QuoteAmountInput
              formRegister={register('sellAmount', {
                required: !buyAmountValue,
                onChange: () => handleAmountChange('sellAmount'),
                min: {
                  value: sellCurrency?.minAmount?.value || 0,
                  message: translateWithValues('sellAmountMin', {
                    minAmount: sellCurrency?.minAmount?.value + ' ' + sellCurrency?.isocode,
                  }),
                },
                ...(sellCurrency?.maxAmount?.value &&
                  sellCurrency?.maxAmount?.value > 0 && {
                    max: {
                      value: sellCurrency.maxAmount.value,
                      message: translateWithValues('sellAmountMax', {
                        maxAmount: sellCurrency?.maxAmount.value + ' ' + sellCurrency?.isocode,
                      }),
                    },
                  }),
                pattern: {
                  value: RegExp(
                    `^\\d+(\\.\\d{${buyCurrency?.amountPrecision && buyCurrency?.amountPrecision > 0 ? 1 : 0},${buyCurrency?.amountPrecision}})?$`,
                  ),
                  message: translateWithValues('amountValidation', {
                    validationValue: sellCurrency?.amountPrecision,
                  }),
                },
              })}
              onKeyDown={(e) => handleDecimalInput(e, sellCurrency?.amountPrecision)}
              error={errors.sellAmount}
              label='sellAmount'
              placeholder='amount'
              className='w-full'
              isEditMode={quoteStatus === QuoteStatus.AddQuote}
              isRequired={!buyAmountValue}
              disabled={Boolean(buyAmountValue)}
              isEditable={quoteStatus !== QuoteStatus.AddQuote}
              value={sellAmountValue}
              setQuoteStatus={(val: QuoteStatus) => {
                setQuoteStatus(val);
                setQuickQuote(null);
                setValue('sellAmount', '');
                setValue('buyAmount', '');
              }}
            />
            {quickQuote && quickQuote?.percentageProfit && quoteStatus !== QuoteStatus.AddQuote && (
              <div className='rounded-[10px] border-[1px] border-success-content-default text-success-content-default text-lg-semibold flex items-center px-[20px] py-2 text-nowrap h-fit self-center'>
                <span>Profit: {quickQuote.percentageProfit}%</span>
              </div>
            )}
          </div>

          {![QuoteStatus.AddQuote, QuoteStatus.SubmittingQuote].includes(quoteStatus) && (
            <div className='flex mt-8 gap-4'>
              {hasRole(paymentRole.editPayments) && (
                <div className='w-[40%]'>
                  <QuoteAmountInput
                    formRegister={register('quoteMargin', {
                      onChange: () => {
                        handleFieldEdit('margin');
                      },
                    })}
                    error={errors.quoteMargin}
                    label='quoteMargin'
                    placeholder='amount'
                    className='w-full mb-2'
                    disabled={currentEditableField !== null && currentEditableField !== 'margin'}
                    isEditMode={quoteStatus === QuoteStatus.DataRecieved}
                  />
                  <QuoteAmountInput
                    formRegister={register('indicativeRate', {
                      onChange: () => {
                        handleFieldEdit('rate');
                      },
                    })}
                    error={errors.indicativeRate}
                    label='indicativeRate'
                    placeholder='amount'
                    className='w-full mb-2'
                    disabled={currentEditableField !== null && currentEditableField !== 'rate'}
                    isEditMode={quoteStatus === QuoteStatus.DataRecieved}
                  />
                  <QuoteAmountInput
                    formRegister={register('inverse', {
                      onChange: () => {
                        handleFieldEdit('inverse');
                      },
                    })}
                    error={errors.inverse}
                    label='inverse'
                    placeholder='amount'
                    className='w-full mb-2'
                    isEditMode={quoteStatus === QuoteStatus.DataRecieved}
                    disabled={currentEditableField !== null && currentEditableField !== 'inverse'}
                  />
                </div>
              )}
              <div className='flex items-center w-[60%] bg-primary-surface-light justify-between rounded-[8px] px-4 py-[20px] min-h-[210px]'>
                {quoteStatus === QuoteStatus.RecievingData ||
                quoteStatus === QuoteStatus.SubmittingQuote ? (
                  <Translate value='calculatingRate' className='text-primary text-xs-bold' />
                ) : (
                  <>
                    <div className='grid'>
                      <Translate value='quote_received' className='text-primary-dark-content' />
                      <span className='text-primary-blue-surface-dark text-2xl-bold'>
                        {quickQuote?.sellAmount + ' ' + quickQuote?.sellCurrency}
                      </span>
                      <div className='mt-4 bg-primary-blue text-primary-dark-content text-sm-medium w-fit p-2 rounded-lg'>
                        At 1{' '}
                        {quickQuote?.buyCurrency +
                          ' = ' +
                          quickQuote?.rate +
                          ' ' +
                          quickQuote?.sellCurrency}
                      </div>
                    </div>
                    {quickQuote?.createdTime && (
                      <CountdownTimer
                        createdTime={quickQuote?.createdTime}
                        onTimeOut={() => {
                          setQuoteStatus(QuoteStatus.QuoteExpired);
                          dispatch(
                            sendToast({
                              severity: 'error',
                              summary: 'alert',
                              detail: 'quoteExpired',
                            }),
                          );
                          if (onSaveAndProceed) onSaveAndProceed('', '');
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          <div className='flex items-center justify-center mt-10 gap-5'>
            <Button
              loading={getQuickQuoteMutation.isPending}
              disabled={getQuickQuoteMutation.isPending}
              type='submit'
              severity='info'
              className='w-48 inline-flex items-center justify-center'
            >
              <Translate
                value={
                  !quickQuote?.quoteId || quoteStatus === QuoteStatus.AddQuote
                    ? 'getQuickQuote'
                    : 'recalculate'
                }
              />
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
