import React from 'react';

const BlotterView = () => {
  return (
    <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_482_39924)'>
        <path
          d='M9.12479 12.1V9.5H27.0137V12.1H9.12479ZM9.12479 17.3V14.7H27.0137V17.3H9.12479ZM9.12479 22.5V19.9H27.0137V22.5H9.12479ZM5.29145 12.1C4.92942 12.1 4.62616 11.9752 4.38168 11.7256C4.13719 11.476 4.01453 11.1675 4.01368 10.8C4.01282 10.4325 4.13549 10.124 4.38168 9.8744C4.62786 9.6248 4.93112 9.5 5.29145 9.5C5.65179 9.5 5.95547 9.6248 6.20251 9.8744C6.44955 10.124 6.57179 10.4325 6.56923 10.8C6.56668 11.1675 6.44401 11.4764 6.20123 11.7269C5.95845 11.9774 5.65519 12.1017 5.29145 12.1ZM5.29145 17.3C4.92942 17.3 4.62616 17.1752 4.38168 16.9256C4.13719 16.676 4.01453 16.3675 4.01368 16C4.01282 15.6325 4.13549 15.324 4.38168 15.0744C4.62786 14.8248 4.93112 14.7 5.29145 14.7C5.65179 14.7 5.95547 14.8248 6.20251 15.0744C6.44955 15.324 6.57179 15.6325 6.56923 16C6.56668 16.3675 6.44401 16.6764 6.20123 16.9269C5.95845 17.1774 5.65519 17.3017 5.29145 17.3ZM5.29145 22.5C4.92942 22.5 4.62616 22.3752 4.38168 22.1256C4.13719 21.876 4.01453 21.5675 4.01368 21.2C4.01282 20.8325 4.13549 20.524 4.38168 20.2744C4.62786 20.0248 4.93112 19.9 5.29145 19.9C5.65179 19.9 5.95547 20.0248 6.20251 20.2744C6.44955 20.524 6.57179 20.8325 6.56923 21.2C6.56668 21.5675 6.44401 21.8764 6.20123 22.1269C5.95845 22.3773 5.65519 22.5017 5.29145 22.5Z'
          fill='currentColor'
        />
      </g>
      <rect x='0.513672' y='1' width='31' height='30' rx='7.5' stroke='currentColor' />
      <defs>
        <clipPath id='clip0_482_39924'>
          <rect x='4.01367' y='4.5' width='24' height='24' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlotterView;
