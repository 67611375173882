import { useContext, useEffect } from 'react';
import { PageHeadContext } from '../../providers/PageHead';
import QuickQuoteForm from './QuickQuoteForm';
import PendingTaskWidget from './PendingTaskWidget';
import { Translate, translateWithValues } from '../../i18n/translate';
import { CustomTabView } from '../../components';
import PaymentsTable from '../Payment/RateRequest/Dashboard/PaymentsTable';

const Dashboard = () => {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  const tabs = [
    {
      header: translateWithValues('pending_approval'),
      content: <PaymentsTable />,
    },
    {
      header: translateWithValues('completed'),
      content: <PaymentsTable />,
    },
    {
      header: translateWithValues('incomplete'),
      content: <PaymentsTable />,
    },
    {
      header: translateWithValues('pending_execution'),
      content: <PaymentsTable />,
    },
  ];

  useEffect(() => {
    setPageHeadData({
      title: 'dashboard',
      description: 'dashboard.description',
      className: 'pb-[16px] pr-4',
    });
  }, [setPageHeadData]);

  return (
    <div>
      <div className='px-[49px]'>
        <div className='grid lg:grid-cols-2  gap-8 mt-8'>
          <QuickQuoteForm />
          <PendingTaskWidget />
        </div>
        <div className='card mt-8'>
          <div className='text-lg-semibold'>
            <Translate value='all_payments' />
          </div>
          <CustomTabView tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
