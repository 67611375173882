import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  selectCurrentCustomer,
  selectOwningCustomers,
  setCurrentCustomer,
} from '../../store/slices/main/mainSlice';
import DropdownInput from '../DropdownInput';

interface Option {
  value: string;
  label: string;
}

const MultiCustomer = () => {
  const dispatch = useDispatch();
  const owningCustomers = useSelector(selectOwningCustomers);
  const [options, setOptions] = useState<Option[]>([]);
  const selectedCurrentCustomer = useSelector(selectCurrentCustomer);
  const navigate = useNavigate();

  useEffect(() => {
    setOptions(
      owningCustomers.map((customer) => ({
        value: customer.id?.toString() || '',
        label: customer.customerShortName,
      })),
    );
  }, [owningCustomers]);

  return (
    <>
      {owningCustomers.length > 1 && (
        <DropdownInput
          onChange={(v) => {
            dispatch(
              setCurrentCustomer(
                owningCustomers.find((c) => c.id === parseInt(v.target.value)) || null,
              ),
            );
            navigate('/');
          }}
          className='min-w-[250px]'
          placeholder='Change Customer'
          options={options}
          value={selectedCurrentCustomer?.id?.toString()}
          filter={true}
        />
      )}
    </>
  );
};

export default MultiCustomer;
