import { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import SearchBar from '../SearchInput/SearchInput';
import { Translate } from '../../i18n/translate';

type ItemType = {
  id: string | number;
  label: string;
  subLabel?: string;
};

type Props = {
  items: Array<ItemType>;
  emptyMessage?: string;
  selectedItems: Array<ItemType['id']>;
  onSelectChange: (ids: Array<ItemType['id']>) => void;
  disabled?: boolean;
};

export default function SearchDropDown({
  items,
  emptyMessage,
  selectedItems,
  onSelectChange,
  disabled = false,
}: Props) {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredItems = items.filter(
    (item) =>
      item.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.subLabel?.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <div className='h-full overflow-hidden grid grid-rows-[auto_1fr]'>
      <div className='mx-1'>
        <SearchBar onSearch={(query) => setSearchQuery(query)} />
      </div>
      <div className='overflow-hidden p-3'>
        <div className='max-h-full card flex flex-col overflow-auto'>
          {!disabled && filteredItems.length > 0 && (
            <div className='bg-primary-surface-light py-3 pl-2 mb-2 rounded-c8 text-sm-semibold'>
              <Checkbox
                className='!mt-[3px]'
                onChange={(e) => onSelectChange(e.checked ? filteredItems.map((i) => i.id) : [])}
                checked={filteredItems.every((item) => selectedItems.includes(item.id))}
                disabled={disabled}
              />
              <label htmlFor='selectAll' className='ml-2 text-sm-semibold text-neutral-2'>
                <Translate value='select_all' />
              </label>
            </div>
          )}
          <div className='transition-opacity duration-300 opacity-100'>
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => (
                <div key={item.id} className='flex gap-4 mt-4 pl-2'>
                  <Checkbox
                    className='!mt-[3px]'
                    onChange={(e) => {
                      if (e.checked) {
                        onSelectChange([...selectedItems, item.id]);
                      } else {
                        onSelectChange(selectedItems.filter((i) => i !== item.id));
                      }
                    }}
                    checked={selectedItems.some((i) => i === item.id)}
                    disabled={disabled}
                  />
                  <div>
                    <div className='text-sm-medium text-neutral-2'>{item.label}</div>
                    {item.subLabel && (
                      <div className='text-sm-regular text-neutral-3'>{item.subLabel}</div>
                    )}
                  </div>
                </div>
              ))
            ) : emptyMessage ? (
              <Translate value={emptyMessage} className='text-center text-neutral-3' />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
