import { Button } from 'primereact/button';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RoleCard } from '../../../../../components';
import InfoTemplate from '../../../../../components/InfoTemplate';
import PermissionModal from '../../../../../components/PermissionModal';
import { Translate } from '../../../../../i18n/translate';
import BlotterView from '../../../../../icons/BlotterView';
import WidgetView from '../../../../../icons/WidgetView';
import { useAppDispatch } from '../../../../../store';
import { sendToast } from '../../../../../store/slices/main/mainSlice';
import {
  fetchRoles,
  selectCustomRoles,
  selectPrimaryRoles,
} from '../../../../../store/slices/role/roleSlice';
import CreateCustomRole from '../../../Role/Dashboard/CreateCustomRole';
import UserService from '../../../userService';
import userRole from '../../userRole';
import './index.css';
import Table from './table';

interface RolesAssignedProps {
  isEdit: boolean;
  userId: string | undefined;
  roleType: 'aobo' | 'primary'; // New prop to differentiate role type
}

const RolesAssigned = ({ isEdit, userId, roleType }: RolesAssignedProps) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [createCustomRole, setCreateCustomRole] = useState(false);
  const [widgetView, setWidgetView] = useState(true);
  const [selectedPrimaryRoles, setSelectedPrimaryRoles] = useState<number[]>([]);
  const [selectedCustomRoles, setSelectedCustomRoles] = useState<number[]>([]);
  const [role, setRole] = useState<Role | null>(null);
  const primaryRoles = useSelector(selectPrimaryRoles);
  const allCustomRoles = useSelector(selectCustomRoles);
  const [initialPrimaryRoles, setInitialPrimaryRoles] = useState<number[]>([]);
  const [initialCustomRoles, setInitialCustomRoles] = useState<number[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const customRoles = useMemo(() => {
    return allCustomRoles.filter((role: Role) => role.isActive);
  }, [allCustomRoles]);
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();

  const getCurrentUser = async () => {
    if (userId) {
      const user = await UserService.getUserById(userId.toString());
      setCurrentUser(user);
    }
  };

  useEffect(() => {
    if (currentUser?.owningCustomerId) {
      appDispatch(fetchRoles(currentUser.owningCustomerId));
    }
  }, [currentUser]);

  const handleSelectedRole = (roleItem: Role, isCustom: boolean) => {
    const setSelected = isCustom ? setSelectedCustomRoles : setSelectedPrimaryRoles;

    setSelected((prevRoles) => {
      const updatedRoles = prevRoles.includes(roleItem.id)
        ? prevRoles.filter((role) => role !== roleItem.id)
        : [...prevRoles, roleItem.id];

      setHasChanges(
        !(
          updatedRoles.sort().join(',') ===
          (isCustom ? initialCustomRoles : initialPrimaryRoles).sort().join(',')
        ),
      );

      return updatedRoles;
    });
  };

  const handleOpenModal = (roleItem: Role) => {
    setRole(roleItem);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setRole(null);
    setModalVisible(false);
  };

  const getSelectedRoles = (primaryList: Role[], customList: Role[]) => {
    if (userId) {
      UserService.getRoleByUserId(userId).then((res: any) => {
        const data = roleType == 'primary' ? res.roleDataList : res.aoboRoleDataList;
        if (data) {
          const primaryRoleIds = primaryList
            .filter((role) => data.some((item: any) => item.id == role.id))
            .map((role) => role.id);
          const customRoleIds = customList
            .filter((role) => data.some((item: any) => item.id == role.id))
            .map((role) => role.id);
          setSelectedPrimaryRoles(primaryRoleIds);
          setSelectedCustomRoles(customRoleIds);
          setInitialPrimaryRoles(primaryRoleIds);
          setInitialCustomRoles(customRoleIds);
          setHasChanges(false);
        }
      });
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const assignRoles = () => {
    const data = {
      userId,
      roleIds: roleType === 'primary' ? [...selectedPrimaryRoles, ...selectedCustomRoles] : [],
      aoboRoleIds: roleType === 'aobo' ? [...selectedPrimaryRoles, ...selectedCustomRoles] : [],
    };
    UserService.assignRolesToUser(data, roleType == 'aobo').then((res) => {
      const toastSummary = res?.success ? 'success' : 'failed';
      const toastDetail =
        roleType === 'aobo'
          ? res?.success
            ? 'AOBORolesUpdatedSuccessfully'
            : 'AOBORolesUpdationFailed'
          : res?.success
            ? 'rolesUpdatedSuccess'
            : 'rolesUpdationFailed';
      appDispatch(
        sendToast({
          severity: res?.success ? 'success' : 'error',
          summary: toastSummary,
          detail: toastDetail,
        }),
      );
      if (res?.success) {
        setInitialPrimaryRoles(selectedPrimaryRoles);
        setInitialCustomRoles(selectedCustomRoles);
        setHasChanges(false);
      }
    });
  };

  const handleRoleCreationComplete = () => {
    setCreateCustomRole(false);
    if (userId) {
      getCurrentUser();
    }
  };

  const onCustomRoleCancel = () => {
    setCreateCustomRole(false);
  };

  useEffect(() => {
    if (userId) {
      getCurrentUser();
    }
  }, [userId]);

  useEffect(() => {
    getSelectedRoles(primaryRoles, customRoles);
  }, [primaryRoles, customRoles]);

  return (
    <>
      {role && isModalVisible && (
        <PermissionModal visible={isModalVisible} onHide={handleCloseModal} role={role} />
      )}
      {createCustomRole ? (
        <CreateCustomRole
          onComplete={handleRoleCreationComplete}
          onCancel={onCustomRoleCancel}
          parentCustomerId={currentUser?.owningCustomerId}
        />
      ) : (
        <>
          <div className='!p-8 card'>
            <div className='grid grid-cols-2'>
              <div>
                <div className='text-sm-bold text-secondary-text'>
                  <Translate
                    value={
                      isEdit ? 'editUser.rolesAssigned.title' : 'add_user.roles_assigned.title'
                    }
                  />
                </div>
                <div className='text-neutral-3'>
                  <Translate
                    value={
                      isEdit
                        ? 'editUser.rolesAssigned.description'
                        : 'add_user.rolesAssigned.description'
                    }
                  />
                </div>
              </div>
              {/* <div className='flex justify-end items-center'>
                <Progress value={50} currentStep={2} stepLimit={3} />
                <CheckMark className='ml-4' />
              </div> */}
            </div>
            {isEdit && (
              <div className='flex justify-end'>
                <div>
                  <Button
                    className={
                      (widgetView ? '!text-selected' : '!text-unselected') +
                      ' !p-0 mr-2 !border-none'
                    }
                    onClick={() => setWidgetView(true)}
                  >
                    <WidgetView />
                  </Button>

                  <Button
                    className={
                      (!widgetView ? '!text-selected' : '!text-unselected') + ' !p-0 !border-none'
                    }
                    onClick={() => setWidgetView(false)}
                  >
                    <BlotterView />
                  </Button>
                </div>
              </div>
            )}

            {widgetView ? (
              <div className='flex flex-wrap gap-8 justify-center mt-8 max-w-[1000px] mx-auto'>
                {primaryRoles.map((role) => (
                  <RoleCard
                    key={role.id}
                    title={role.roleName}
                    description={role.description}
                    selected={selectedPrimaryRoles.includes(role.id)}
                    onSelect={() => handleSelectedRole(role, false)}
                    handleOpenModal={() => handleOpenModal(role)}
                  />
                ))}
              </div>
            ) : (
              <div className='mt-8'>
                <Table
                  roles={primaryRoles.filter((role) => selectedPrimaryRoles.includes(role.id))}
                />
              </div>
            )}
          </div>

          {customRoles.length > 0 && (
            <div className='!p-8 mt-8 card'>
              <div className='grid grid-cols-2'>
                <div>
                  <div className='text-sm-bold text-secondary-text'>
                    <Translate
                      value={
                        isEdit
                          ? 'editUser.rolesAssigned.customRolesAssigned.title'
                          : 'add_user.roles_assigned.custom_roles_assigned.title'
                      }
                    />
                  </div>
                  <div className='text-neutral-3'>
                    <Translate
                      value={
                        isEdit
                          ? 'editUser.rolesAssigned.customRolesAssigned.description'
                          : 'add_user.roles_assigned.custom_roles_assigned.description'
                      }
                    />
                  </div>
                </div>
              </div>
              {widgetView ? (
                <div className='flex flex-wrap gap-8 justify-center mt-8 max-w-[1000px] mx-auto'>
                  {customRoles.map((role) => (
                    <RoleCard
                      key={role.id}
                      title={role.roleName}
                      description={role.description}
                      selected={selectedCustomRoles.includes(role.id)}
                      onSelect={() => handleSelectedRole(role, true)}
                      handleOpenModal={() => handleOpenModal(role)}
                    />
                  ))}
                </div>
              ) : (
                <div className='mt-8'>
                  <Table
                    roles={customRoles.filter((role) => selectedCustomRoles.includes(role.id))}
                  />
                </div>
              )}
            </div>
          )}

          <div className='mt-5'>
            <InfoTemplate
              titleKey='add_user.roles_assigned.custom_role.title'
              descriptionKey='add_user.roles_assigned.custom_role.description'
              buttonKey='add_user.roles_assigned.create_custom_role'
              btnAction={() => setCreateCustomRole(true)}
              roles={userRole.AddRole}
            />
          </div>

          <div className='flex items-center justify-center mt-10 gap-5'>
            <Button severity='contrast' className='!px-7' onClick={handleCancel}>
              <Translate value='cancel' />
            </Button>
            <Button severity='info' className='!px-7' onClick={assignRoles} disabled={!hasChanges}>
              <Translate value='save_and_proceed' />
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default RolesAssigned;
