// src/components/CurrencyFlag.js
const CurrencyFlag = ({ currencyCode }: { currencyCode: string }) => {
  try {
    return currencyCode ? (
      <div className='w-max bg-neutral-surface-dark pb-[1px] pt-[4px] pr-[8px] pl-[3px] h-[30px]  rounded-[16px]'>
        <img
          className='rounded-full w-[19px] h-[19px] object-cover inline-block'
          src={`/images/c_flags_svg/${currencyCode?.toLowerCase()}.svg`}
          alt={currencyCode || `${currencyCode} flag`}
        />
        <span className='text-xs-medium text-neutral-1 ml-1'>{currencyCode}</span>
      </div>
    ) : (
      <></>
    );
  } catch (error) {
    console.error(`Flag not found for currency code: ${currencyCode}`);
    return null;
  }
};

export default CurrencyFlag;
