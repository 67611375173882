import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ToggleInput } from '../../../../../components';
import { Translate } from '../../../../../i18n/translate';
import { Status } from '../../../../../types';
import UserService from '../../../userService';
import ActionModals from '../../ActionModals';

const Settings: React.FC = () => {
  const [currentActionUser, setCurrentActionUser] = useState<User | null>(null);
  const userId = useParams<{ userId: string }>().userId;
  const [confirmationState, setConfirmationState] = useState<ConfirmationState>({
    resetPassword: false,
    resetMFA: false,
    blockUser: false,
    deleteUser: false,
    unblockUser: false,
  });

  useEffect(() => {
    getUser();
  }, [userId]);

  const getUser = async () => {
    if (!userId) return;
    const user = await UserService.getUserById(userId.toString());
    setCurrentActionUser(user);
  };

  const showConfirmPopup = (key: keyof typeof confirmationState) => {
    setConfirmationState((prevState) => ({ ...prevState, [key]: true }));
  };

  const hideConfirmPopup = (key: keyof typeof confirmationState) => {
    setConfirmationState((prevState) => ({ ...prevState, [key]: false }));
  };

  return (
    <>
      <ActionModals
        confirmationState={confirmationState}
        hideConfirmPopup={hideConfirmPopup}
        setConfirmationState={setConfirmationState}
        currentActionUser={currentActionUser}
        getUser={getUser}
      />

      <div className='!p-8 card'>
        <div>
          <div className='text-sm-bold text-secondary-text'>
            <Translate value='securitySettings' />
          </div>
          <div className='text-neutral-3'>
            <Translate value='securitySettingsDescription' />
          </div>
        </div>
        <div className='flex gap-[30px] mt-8'>
          <ToggleInput
            id='id'
            label={currentActionUser?.status == Status.ACTIVE ? 'blockUser' : 'unblockUser'}
            checked={currentActionUser?.status !== Status.ACTIVE}
            className='flex gap-[30px]'
            buttonAction={() => {
              if (currentActionUser?.status == Status.ACTIVE) {
                showConfirmPopup('blockUser');
              } else {
                showConfirmPopup('unblockUser');
              }
            }}
          />
        </div>
      </div>

      <div className='!p-8 mt-8 card'>
        <div>
          <div className='text-sm-bold text-secondary-text'>
            <Translate value='resetSecurity' />
          </div>
          <div className='text-neutral-3'>
            <Translate value='resetSecurityDescription' />
          </div>
        </div>
        <div className='mt-4 flex gap-8'>
          <Button
            severity='contrast'
            className='!px-7'
            onClick={() => {
              showConfirmPopup('resetPassword');
            }}
          >
            <Translate value='resetPassword' />
          </Button>
          <Button
            severity='contrast'
            className='!px-7'
            onClick={() => {
              showConfirmPopup('resetMFA');
            }}
          >
            <Translate value='resetMFA' />
          </Button>
        </div>
      </div>

      <div className='!p-8 mt-8 card'>
        <div>
          <div className='text-sm-bold text-secondary-text'>
            <Translate value='deleteAccount' />
          </div>
          <div className='text-neutral-3'>
            <Translate value='deleteAccountDescription' />
          </div>
        </div>
        <Button
          severity='danger'
          className='!px-7 mt-[30px]'
          onClick={() => {
            showConfirmPopup('deleteUser');
          }}
        >
          <Translate value='deleteAccount' />
        </Button>
      </div>
    </>
  );
};

export default Settings;
