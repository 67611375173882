import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../store';
import { selectCurrentCustomer } from '../../../../store/slices/main/mainSlice';
import { fetchRoles } from '../../../../store/slices/role/roleSlice';
import CustomRoles from './CustomRoles';
import PrimaryRoles from './PrimaryRoles';
import './index.scss';

export default function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentCustomer = useSelector(selectCurrentCustomer);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (currentCustomer?.id) dispatch(fetchRoles(currentCustomer.id.toString()));
  }, [currentCustomer?.id]);

  useEffect(() => {
    // Create an instance of URLSearchParams to extract query parameters
    const searchParams = new URLSearchParams(location.search);

    // Check if the 'type' query parameter is 'custom'
    if (searchParams.get('type') === 'custom') {
      setActiveIndex(1); // Second tab (index 1) will be selected
    } else {
      setActiveIndex(0); // Default to the first tab
    }
  }, [location.search]);

  const handleTabChange = (e: { index: number }) => {
    setActiveIndex(e.index);

    // Update the URL based on the selected tab
    if (e.index === 0) {
      navigate('/manage/role?type=primary');
    } else if (e.index === 1) {
      navigate('/manage/role?type=custom');
    }
  };

  return (
    // <div className='card'>
    <TabView
      className='roles-section p-4 px-[20px] lg:px-[49px]'
      activeIndex={activeIndex}
      onTabChange={handleTabChange}
    >
      <TabPanel header='Primary roles'>
        <PrimaryRoles />
      </TabPanel>
      <TabPanel header='Custom roles'>
        <CustomRoles />
      </TabPanel>
    </TabView>
    // </div>
  );
}
