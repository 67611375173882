import React, { useState } from 'react';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';

interface SearchBarProps {
    onSearch: (query: string) => void;
    placeholder?: string;
    [key: string]: any;
}

const SearchBar = ({
    onSearch,
    placeholder = 'Search',
}: SearchBarProps) => {
    const [searchQuery, setSearchQuery] = useState<string>('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setSearchQuery(query);
        onSearch(query);
    };

    return (
        <IconField iconPosition="left" className='!w-full !h-11'>
            <InputIcon className='pi pi-search !mr-3'> </InputIcon>
            <InputText
                value={searchQuery}
                onChange={handleInputChange}
                placeholder={placeholder}
                className='!w-full !pl-9 !h-11'
            />
        </IconField>
    );
};

export default SearchBar;
