import { useSelector } from 'react-redux';
import { selectRoles } from '../store/slices/main/mainSlice';

const useRole = () => {
  const roles = useSelector(selectRoles);

  const hasRole = (allowedRoles: string): boolean => {
    if (allowedRoles.includes('&')) {
      const requiredRoles = allowedRoles.split('&').map((role) => role.trim());
      return requiredRoles.every((role) => roles.includes(role));
    } else {
      const requiredRoles = allowedRoles.split(',').map((role) => role.trim());
      return requiredRoles.some((role) => roles.includes(role));
    }
  };

  return { hasRole };
};

export default useRole;
