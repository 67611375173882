export const rateRequestMockData: RateRequest[] = [
  {
    paymentId: '90874123',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'EXECUTED',
  },
  {
    paymentId: '90865123',
    buyCurrency: 'INR',
    buyAmount: '900',
    sellCurrency: 'EUR',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'PENDING_RATE',
  },
  {
    paymentId: '9087651465',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'PENDING_RATE',
  },
  {
    paymentId: '90876576',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'EUR',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'EXECUTED',
  },
  {
    paymentId: '90876545',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'PENDING_RATE',
  },
  {
    paymentId: '90876523',
    buyCurrency: 'INR',
    buyAmount: '900',
    sellCurrency: 'USD',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'EXECUTED',
  },
  {
    paymentId: '965765123',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'PENDING_RATE',
  },
  {
    paymentId: '908765176',
    buyCurrency: 'INR',
    buyAmount: '900',
    sellCurrency: 'USD',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'PENDING_RATE',
  },
  {
    paymentId: '90876234',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'PENDING_RATE',
  },
  {
    paymentId: '6546456',
    buyCurrency: 'INR',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'PENDING_RATE',
  },
  {
    paymentId: '7547456',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'PENDING_RATE',
  },
  {
    paymentId: '54757456',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'PENDING_RATE',
  },
  {
    paymentId: '7657575',
    buyCurrency: 'INR',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'EXECUTED',
  },
  {
    paymentId: '34543654',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'PENDING_RATE',
  },
  {
    paymentId: '86586585',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'EXECUTED',
  },
  {
    paymentId: '3454353453',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'PENDING_RATE',
  },
  {
    paymentId: '765756765',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'EXECUTED',
  },
  {
    paymentId: '13454354',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'PENDING_RATE',
  },
  {
    paymentId: '76576573',
    buyCurrency: 'USD',
    buyAmount: '900',
    sellCurrency: 'AED',
    sellAmount: '1500',
    valueDate: '11/01/2024',
    status: 'EXECUTED',
  },
];
