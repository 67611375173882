import Dashboard from './Dashboard';
import PrimaryUsersView from './Dashboard/PrimaryUserView';

const roleRoutes = [
  {
    id: 'roleDashboard',
    index: true,
    element: <Dashboard />,
  },
  {
    id: 'veiw',
    path: 'users',
    handle: {
      breadcrumb: 'Users'
    },
    element: <PrimaryUsersView />
  }
];

export default roleRoutes;
