import { Tag } from 'primereact/tag';
import { PageHead } from '../../components';
import { Translate } from '../../i18n/translate';
import {
  ChevronRight,
  Money,
  User,
  UserAvatar,
  UserIdentification,
  UserSettings,
} from '@carbon/icons-react';
import { useState } from 'react';
import { NextFilled } from '../../icons';

const PendingTaskWidget = () => {
  const options = [
    {
      name: 'payments',
      icon: <Money size={16} />,
    },
    {
      name: 'approval_templates',
      icon: <UserIdentification size={16} />,
    },
    {
      name: 'users',
      icon: <User size={16} />,
    },
    {
      name: 'roles',
      icon: <UserSettings size={16} />,
    },
    {
      name: 'customers',
      icon: <UserAvatar size={16} />,
    },
  ];
  const tiles = [
    {
      name: 'pending_approval',
      count: 48,
    },
    {
      name: 'incomplete',
      count: 48,
    },
    {
      name: 'pending_execution',
      count: 48,
    },
  ];
  const [selectedOptionIndex] = useState(0);
  return (
    <div className='card p-8'>
      <PageHead title='pending_request_header' description='pending_request_subtitle' />
      <div className='py-2 flex gap-3 flex-wrap'>
        {options.map((option, index) => (
          <div key={option.name}>
            <Tag
              className={`inline-block ${selectedOptionIndex === index ? 'border-2 border-transparent bg-gradient-to-b from-primary-gradient-from to-primary-gradient-to bg-origin-border cursor-pointer' : 'border border-neutral-border'} p-0 rounded-[20px]`}
            >
              <div
                className={`px-3 py-2 flex gap-1.5 ${selectedOptionIndex === index ? 'text-selected bg-primary-surface-light' : 'text-neutral-4 bg-neutral-surface-subtle'}  rounded-[20px] items-center`}
              >
                {option.icon}
                <Translate value={option.name} className='text-xs-medium' />
              </div>
            </Tag>
          </div>
        ))}
      </div>
      <div className='grid grid-cols-2 gap-4 mt-4 lg:mx-2'>
        {tiles.map((tile) => (
          <div className='card cursor-pointer' key={tile.name}>
            <div className='flex justify-between text-neutral-3 text-sm-medium'>
              <Translate value={tile.name} />
              <ChevronRight size={20} />
            </div>
            <div className='text-2xl-bold pt-3'>{tile.count}</div>
          </div>
        ))}
        <div className='card cursor-pointer flex flex-col items-center justify-center'>
          <NextFilled />
          <div className='mt-2'>
            <Translate
              value='go_to_action_centre'
              className='bg-gradient-to-b from-primary-gradient-from to-primary-gradient-to bg-clip-text text-transparent text-sm-semibold'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingTaskWidget;
