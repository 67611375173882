const Download = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.3687 7.86875L16.25 8.75L10 15L3.75 8.75L4.63125 7.86875L9.375 12.6062V1.25H10.625V12.6062L15.3687 7.86875ZM16.25 17.5V15H17.5V17.5C17.5 17.8315 17.3683 18.1495 17.1339 18.3839C16.8995 18.6183 16.5815 18.75 16.25 18.75H3.75C3.41848 18.75 3.10054 18.6183 2.86612 18.3839C2.6317 18.1495 2.5 17.8315 2.5 17.5V15H3.75V17.5H16.25Z'
      fill='url(#paint0_linear_2666_50703)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_2666_50703'
        x1='10'
        y1='1.25'
        x2='10'
        y2='18.75'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='var(--primary-gradient-to)' />
        <stop offset='1' stopColor='var(--primary-gradient-from)' />
      </linearGradient>
    </defs>
  </svg>
);

export default Download;
