import { RoleGuard } from '../../../components';
import Dashboard from './Dashboard';
import managePricingRole from './managePricingRole';

const managedPricingRoutes = [
  {
    id: 'managedPricingDashboard',
    index: true,
    element: (
      <RoleGuard roles={managePricingRole.ViewManagePricing}>
        <Dashboard isCustomerPricing={false} />,
      </RoleGuard>
    ),
  },
  {
    id: 'managedPricingClient',
    path: ':clientId',
    index: true,
    element: (
      <RoleGuard roles={managePricingRole.ViewManagePricing}>
        <Dashboard isCustomerPricing={true} />
      </RoleGuard>
    ),
  },
];

export default managedPricingRoutes;
