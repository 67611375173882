import {
  AcceptActionUsage,
  CloseOutline,
  Edit,
  Filter,
  Redo,
  Reset,
  TrashCan,
  ViewFilled,
} from '@carbon/icons-react';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTablePageEvent, DataTableSortEvent, SortOrder } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { InheritedMenu, PaginatorTemplate, Status, TableSearch } from '../../../../components';
import FilterModal from '../../../../components/FilterModal';
import InfoTemplate from '../../../../components/InfoTemplate';
import { Translate } from '../../../../i18n/translate';
import { Download, MoreAction } from '../../../../icons';
import { PageHeadContext } from '../../../../providers/PageHead';
import {
  selectCurrentCustomer,
  selectFilters,
  updateFilter,
} from '../../../../store/slices/main/mainSlice';
import { Status as StatusEnum } from '../../../../types/enum';
import { USER_STATUS_OPTIONS } from '../../../Client/clientConstants';
import UserService from '../../userService';
import ActionModals from '../ActionModals';
import userRole from '../userRole';

interface TableProps {
  pending: boolean;
}

const Table = ({ pending = false }: TableProps) => {
  const [selectedUsers, setSelectUsers] = useState<User[] | null>(null);
  const [currentActionUser, setCurrentActionUser] = useState<User | null>(null);
  const menu = useRef<Menu>(null);
  const [currentActions, setCurrentActions] = useState<MenuItem[]>([]);
  const navigate = useNavigate();
  const [confirmationState, setConfirmationState] = useState<ConfirmationState>({
    resetPassword: false,
    resetMFA: false,
    unblockUser: false,
    blockUser: false,
    deleteUser: false,
  });

  const [isModalVisible, setModalVisible] = useState(false);
  const filters = useSelector(selectFilters)[pending ? 'users_pending' : 'users_all'];
  const [filterValues, setFilterValues] = useState<any>([
    {
      header: 'By Status',
      fields: [
        {
          name: 'statuses',
          placeholder: 'Select Status',
          value: filters?.statuses || [],
          options: USER_STATUS_OPTIONS,
        },
      ],
    },
  ]);

  const hideConfirmPopup = (key: keyof typeof confirmationState) => {
    setConfirmationState((prevState) => ({ ...prevState, [key]: false }));
  };

  const showConfirmPopup = (key: keyof typeof confirmationState) => {
    setConfirmationState((prevState) => ({ ...prevState, [key]: true }));
  };

  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const [users, setUsers] = useState<User[]>([]);
  const { customerId } = useParams<{ customerId: string }>();
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [searchParams] = useSearchParams();
  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [globalSearch, setGlobalSearch] = useState('');
  const [debouncedSearch] = useDebounce(globalSearch, 300);
  const [debouncedPage] = useDebounce(pageParams, 300);
  const [activeFilterCount, setActiveFilterCount] = useState(
    filters ? Object.keys(filters).length : 0,
  );
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<SortOrder>();
  const dispatch = useDispatch();

  const getUsers = async () => {
    setLoading(true);
    const id = customerId ? parseInt(customerId) : selectedCustomer?.id;
    const filtersFinal =
      filters?.statuses && filters?.statuses.indexOf(StatusEnum.PENDING_APPROVAL) !== -1
        ? {
            ...filters,
            statuses: [...filters.statuses, StatusEnum.PENDING_CREATION],
          }
        : filters;
    const payload = {
      searchText: globalSearch.trim(),
      ...filtersFinal,
    };
    try {
      if (id) {
        const { page, rows } = pageParams;
        const response = pending
          ? await UserService.getApprovalList(
              customerId,
              page,
              rows,
              payload,
              sortOrder,
              globalSearch.trim(),
            )
          : await UserService.getCustomerUser(id, page, rows, payload, sortOrder, sortField);
        if (response) {
          const { content, totalElements } = response;
          const filteredUsers = pending
            ? content.filter((user) => user.status === StatusEnum.PENDING)
            : content.filter((user) => user.status !== StatusEnum.DELETED);
          setUsers(filteredUsers);
          setTotalRecords(totalElements);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [debouncedPage, debouncedSearch, filters, customerId, sortField, sortOrder]);

  const onPage = (event: DataTablePageEvent) => {
    setPageParams({
      ...event,
      page: event.page ?? 0,
      rows: event.rows ?? 10,
      first: event.first ?? 0,
    });
  };

  const onSort = (event: DataTableSortEvent) => {
    if (event.sortField && event.sortOrder) {
      setSortField(event.sortField);
      setSortOrder(event.sortOrder);
    }
  };

  const handleGlobalSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    setGlobalSearch(e.target.value);
  };

  const handleApplyFilters = (formdata: FilterFormData) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    dispatch(updateFilter({ filterKey: pending ? 'users_pending' : 'users_all', value: formdata }));
  };

  const downloadUser = async () => {
    const id = customerId ? parseInt(customerId) : selectedCustomer?.id;
    const payload = {
      searchText: globalSearch,
      ...filters,
    };
    if (id) {
      await UserService.downloadUser(id, payload);
    }
  };

  useEffect(() => {
    setPageHeadData({
      title: 'user.list.title',
      description: 'user.list.description',
      className: 'pb-[16px] pr-4',
      actionUI:
        users.length != 0 ? (
          <InfoTemplate
            titleKey='user.addnew.title'
            descriptionKey='user.addnew.description'
            buttonKey='user.addnew.button'
            btnAction={() => {
              if (customerId) {
                navigate(
                  `/manage/user/${customerId}/add?sb=${searchParams.get('sb')}&name=${searchParams.get('name')}`,
                );
              } else {
                navigate('/manage/user/add');
              }
            }}
            roles={userRole.AddUser}
          />
        ) : (
          <></>
        ),
    });
  }, [users]);

  const actionBodyTemplate = (user: User) => {
    const actions: MenuItem[] = [
      {
        label: 'user.view',
        onClick: () => {
          navigate(`/manage/user/view/${user?.id}`);
        },
        icon: <ViewFilled />,
        roles: userRole.ViewUser,
      },
      {
        label: 'user.edit',
        onClick: () => {
          navigate(`/manage/user/edit/${user?.id}`);
        },
        icon: <Edit />,
        roles: userRole.UpdateUser,
        disabled: [StatusEnum.PENDING_CREATION, StatusEnum.PENDING_APPROVAL].includes(
          user.approvalStatus!,
        ),
      },

      {
        label: 'resetPassword',
        onClick: () => {
          showConfirmPopup('resetPassword');
        },
        icon: <Reset />,
        roles: userRole.UpdateUser,
      },
      {
        label: 'resetMFA',
        onClick: () => {
          showConfirmPopup('resetMFA');
        },
        icon: <TrashCan />,
        roles: userRole.UpdateUser,
        disabled: [StatusEnum.PENDING_CREATION, StatusEnum.PENDING_APPROVAL].includes(
          user.approvalStatus!,
        ),
      },
      {
        label: 'blockUser',
        onClick: () => {
          showConfirmPopup('blockUser');
        },
        icon: <CloseOutline />,
        roles: userRole.UpdateUser,
        disabled: [StatusEnum.PENDING_CREATION, StatusEnum.PENDING_APPROVAL].includes(
          user.approvalStatus!,
        ),
      },
      {
        label: 'unblockUser',
        onClick: () => {
          showConfirmPopup('unblockUser');
        },
        icon: <Redo />,
        roles: userRole.UpdateUser,
        disabled: [StatusEnum.PENDING_CREATION, StatusEnum.PENDING_APPROVAL].includes(
          user.approvalStatus!,
        ),
      },
      {
        label: 'deleteUser',
        onClick: () => {
          showConfirmPopup('deleteUser');
        },
        className: 'delete-menu-item',
        icon: <TrashCan />,
        roles: userRole.DeleteUser,
        disabled: [StatusEnum.PENDING_CREATION, StatusEnum.PENDING_APPROVAL].includes(
          user.approvalStatus!,
        ),
      },
    ];

    const actionFilter: MenuItem[] = actions.filter((item) => {
      if (user.status === StatusEnum.ACTIVE && item.label === 'unblockUser') {
        return false;
      }
      if (user.status === StatusEnum.INACTIVE && item.label === 'blockUser') {
        return false;
      }
      if (
        user.status === StatusEnum.PENDING &&
        (item.label === 'blockUser' ||
          item.label === 'unblockUser' ||
          item.label === 'deleteUser' ||
          item.label === 'resetMFA' ||
          item.label === 'resetPassword')
      ) {
        return false;
      }
      return true;
    });

    if (
      (user.canBeApprovedByCurrentUser && user.approvalStatus === StatusEnum.PENDING_CREATION) ||
      user.approvalStatus === StatusEnum.PENDING_APPROVAL
    ) {
      actionFilter.unshift({
        label: 'user.approve',
        onClick: () => {
          navigate(`/manage/user/approve/${user?.id}`);
        },
        icon: <AcceptActionUsage />,
        roles: userRole.ApproveUser,
      });
    }

    return (
      <div>
        <div
          onClick={(event) => {
            setCurrentActions(actionFilter);
            setCurrentActionUser(user);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={currentActions} ref={menu} popupAlign='left' />
      </div>
    );
  };

  const header = (
    <div className='flex flex-col md:flex-row gap-3 justify-between'>
      <div>
        <div className='text-neutral-1 text-lg-semibold'>
          <Translate value='total' />
          <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
            {totalRecords}
          </span>
        </div>
        <div className='mt-1'>
          <Translate value='userListTableDescription' className='text-neutral-3 text-sm-regular' />
        </div>
      </div>
      <div className='flex justify-around'>
        <div>
          <TableSearch globalFilterValue={globalSearch} onGlobalFilterChange={handleGlobalSearch} />
        </div>
        {!pending && (
          <>
            <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
              <Button
                severity='secondary'
                className='p-button-text h-12'
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                <Filter size={20} />
                <Translate value='filterLabel' className='ml-1 hidden lg:block' />
              </Button>
              {!!activeFilterCount && (
                <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
              )}
            </div>
            <div className='ml-2'>
              <Button
                severity='contrast'
                onClick={() => {
                  downloadUser();
                }}
                className='h-12'
              >
                <Download /> <Translate value='downloadLabel' className='ml-1 hidden lg:block' />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );

  return (
    <>
      <ActionModals
        confirmationState={confirmationState}
        hideConfirmPopup={hideConfirmPopup}
        setConfirmationState={setConfirmationState}
        getUsers={getUsers}
        pageParams={pageParams}
        currentActionUser={currentActionUser}
      />

      <FilterModal
        visible={isModalVisible}
        setFilterModalToggle={setModalVisible}
        filters={filterValues}
        setFilters={setFilterValues}
        title='user_filter.title'
        onApply={handleApplyFilters}
      />

      <div>
        {!loading && users.length == 0 && !globalSearch && !activeFilterCount && (
          <div className='card'>
            <div className='text-center m-auto'>
              <img src='/images/study--skip.svg' className='m-auto pt-[60px]' />
              <div className='text-md-bold neutral-1 mt-5'>
                <Translate value='user.nousers.title' />
              </div>
              <div className='text-md-regular text-center m-auto text-neutral-3 mt-[15px] w-[60%]'>
                <Translate value='user.nousers.description' />
              </div>
            </div>
            <div className='mt-[30px]'>
              <AddNewUserHead searchParams={searchParams} customerId={customerId} />
            </div>
          </div>
        )}
      </div>

      {(users.length !== 0 || globalSearch || activeFilterCount != 0) && (
        <div className='table-card'>
          <DataTable
            paginatorTemplate={PaginatorTemplate}
            totalRecords={totalRecords}
            lazy
            paginator
            loading={loading}
            first={pageParams.first}
            rows={pageParams.rows}
            onPage={onPage}
            header={header}
            value={users}
            size={'large'}
            selectAll={true}
            selectionMode={'checkbox'}
            selection={selectedUsers!}
            onSelectionChange={(e: { value: User[] }) => setSelectUsers(e.value)}
            dataKey='id'
            onSort={onSort}
            sortField={sortField}
            sortOrder={sortOrder}
          >
            {/* <Column selectionMode='multiple' headerStyle={{ width: '1rem' }}></Column> */}
            <Column
              sortable
              field='userIdentity'
              header='User'
              body={(user: User) => (
                <div className='text-sm-medium text-neutral-2'>
                  {user.userIdentity.firstName} {user.userIdentity.lastName}
                  <div className='text-sm-regular text-neutral-3'>{user.userIdentity.email}</div>
                </div>
              )}
            ></Column>
            <Column
              sortable
              field='status'
              header='Status'
              body={(user: User) => (
                <Status
                  status={
                    user.approvalStatus == StatusEnum.PENDING_APPROVAL ||
                    user.approvalStatus == StatusEnum.PENDING_CREATION
                      ? user.approvalStatus
                      : (user.status ?? '')
                  }
                />
              )}
            ></Column>
            <Column
              field='role'
              header='Role'
              body={(user: User) =>
                user.roles?.length
                  ? `${user.roles[0].roleName}${user.roles.length > 1 ? ' +' + (user.roles.length - 1) : ''}`
                  : ''
              }
            ></Column>
            <Column header='Actions' body={actionBodyTemplate}></Column>
          </DataTable>
        </div>
      )}
    </>
  );
};

const AddNewUserHead = ({
  customerId,
  searchParams,
}: {
  customerId: string | undefined;
  searchParams: URLSearchParams;
}) => {
  const navigate = useNavigate();
  return (
    <InfoTemplate
      titleKey='user.addnew.title'
      descriptionKey='user.addnew.description'
      buttonKey='user.addnew.button'
      btnAction={() => {
        if (customerId) {
          navigate(
            `/manage/user/${customerId}/add?sb=${searchParams.get('sb')}&name=${searchParams.get('name')}`,
          );
        } else {
          navigate('/manage/user/add');
        }
      }}
      roles={userRole.AddUser}
    />
  );
};

export default Table;
