import { RoleGuard } from '../../../components';
import currencyRole from './currencyRole';
import Dashboard from './Dashboard';

const currencyRoutes = [
  {
    id: 'currencyDashboard',
    index: true,
    element: (
      <RoleGuard roles={currencyRole.ViewCurrency}>
        <Dashboard />
      </RoleGuard>
    ),
  },
];

export default currencyRoutes;
