import { Dialog } from 'primereact/dialog';
import { Translate } from '../../../../i18n/translate';
import { DropdownInput, TextInput } from '../../../../components';
import CountrySelect from '../../../../components/CountrySelect';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { BANK_TYPE_OPTIONS, EMAIL_VALIDATION_REGEX } from '../../../Client/clientConstants';
import PaymentService from '../../paymentService';
import { CustomerType, KycStatus } from '../../../../types';
import { selectCurrentCustomer, sendToast } from '../../../../store/slices/main/mainSlice';
import { useDispatch, useSelector } from 'react-redux';

interface CustomerCreationProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  handleNewCustomer: (customer: Client) => void;
  handleDeleteEntry: () => void;
}

const CustomerCreationModal = ({
  visible,
  setVisible,
  handleNewCustomer,
  handleDeleteEntry,
}: CustomerCreationProps) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CustomerCreationForm>();
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const dispatch = useDispatch();

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const saveCustomerDetails = async (customerData: CustomerCreationForm) => {
    const payload: CustomerCreationForm = {
      ...customerData,
      customerType: CustomerType.ORDERING_CUSTOMER,
      owningCustomerId: selectedCustomer?.id,
      kycStatus: KycStatus.ACTIVE,
    };
    payload.address.country = customerData.address.country.code;
    try {
      const customer = await PaymentService.createNewCustomer(payload);
      handleNewCustomer(customer);
      showToast('success', 'success', 'created_new_customer')
    } finally {
      setVisible(false);
    }
  };
  return (
    <Dialog
      className='w-3/5 scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
      header={
        <>
          <div className='text-lg font-semibold text-neutral-1'>
            <Translate value='add_new_customer.header' />
          </div>
          <div className='text-sm-regular mt-1 text-neutral-3'>
            <Translate value='add_new_customer.subtitle' />
          </div>
        </>
      }
      visible={visible}
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
      onHide={() => setVisible(false)}
      draggable={false}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(saveCustomerDetails)} className='mt-10'>
        <div className='grid grid-cols-2 gap-6'>
          <TextInput
            label='customer_identifier'
            placeholder='customer_identifier'
            isRequired
            formRegister={register('customerShortName', { required: true })}
            error={errors.customerShortName}
          />
          <TextInput
            label='customer_name'
            placeholder='customer_name'
            isRequired
            formRegister={register('customerLegalName', { required: true })}
            error={errors.customerLegalName}
          />
        </div>
        <div className='mt-6 grid grid-cols-2 gap-6'>
          <TextInput
            label='address_line_1'
            placeholder='address_line_1'
            isRequired
            formRegister={register('address.street1', { required: true })}
            error={errors.address?.street1}
          />
          <TextInput
            label='address_line_2'
            placeholder='address_line_2'
            formRegister={register('address.street2')}
            error={errors.address?.street2}
          />
        </div>
        <div className='mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          <TextInput
            label='city'
            placeholder='city'
            isRequired
            formRegister={register('address.city', { required: true })}
            error={errors.address?.city}
          />
          <TextInput
            label='state_or_region'
            placeholder='state_or_region'
            isRequired
            formRegister={register('address.state', { required: true })}
            error={errors.address?.state}
          />
          <TextInput
            label='zip_code'
            placeholder='zip_code'
            isRequired
            formRegister={register('address.postalCode', { required: true })}
            error={errors.address?.postalCode}
          />
          <CountrySelect
            label='customer_country'
            placeholder='customer_country'
            control={control}
            name='address.country'
            rules={{ required: true }}
            error={errors.address?.country}
          />
          <TextInput
            label='phone'
            placeholder='phone'
            isRequired
            formRegister={register('phone', { required: true })}
            error={errors.phone}
          />
          <TextInput
            label='email'
            placeholder='email'
            isRequired
            formRegister={register('email', {
              required: true,
              pattern: {
                value: EMAIL_VALIDATION_REGEX,
                message: 'invalid_email_address',
              },
            })}
            error={errors.email}
          />
        </div>
        <div className='mt-6 pt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 border-t border-t-2 border-neutral-border-2'>
          <DropdownInput
            label='bank_code_type'
            placeholder='bank_code_type'
            options={BANK_TYPE_OPTIONS}
            control={control}
            name='bankInfos.0.codeType'
            rules={{ required: true }}
            isRequired
            error={errors.bankInfos?.[0]?.codeType}
          />
          <TextInput
            label='bank_code'
            placeholder='bank_code'
            isRequired
            formRegister={register('bankInfos.0.routingCode', { required: true })}
            error={errors.bankInfos?.[0]?.routingCode}
          />
          <TextInput
            label='bank_account_number'
            placeholder='bank_account_number'
            control={control}
            isRequired
            formRegister={register('bankInfos.0.accountNumber', { required: true })}
            error={errors.bankInfos?.[0]?.accountNumber}
          />
        </div>
        <div className='flex justify-center mt-14 gap-6'>
          <Button
            severity='contrast'
            type='button'
            onClick={() => {
              reset();
              setVisible(false);
              handleDeleteEntry();
            }}
          >
            <Translate value='delete_entry' />
          </Button>
          <Button type='submit' severity='info' className='!px-10'>
            <Translate value='save' />
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default CustomerCreationModal;
