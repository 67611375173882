import { CheckmarkFilled } from '@carbon/icons-react';

const SuccessIcon = () => {
  return (
    <div
      className={
        'flex items-center justify-center bg-success-surface-light rounded-full min-w-[40px] h-[40px] m-auto'
      }
    >
      <CheckmarkFilled fill='var(--success-content-default)' size={24} />
    </div>
  );
};

export default SuccessIcon;
