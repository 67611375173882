type Props = { reqAppCount: number; setReqAppCount: (reqAppCount: number) => void };

function RequestAppCount({ reqAppCount, setReqAppCount }: Props) {
  return (
    <div className='grid grid-cols-3 w-32 h-10 rounded-md border border-neutral-5 shadow-input'>
      <button
        onClick={() => setReqAppCount(Math.max(reqAppCount - 1, 1))}
        className='border-r border-neutral-5 text-neutral-1'
      >
        -
      </button>
      <div className='flex justify-center items-center text-neutral-1 text-sm-semibold'>
        {reqAppCount}
      </div>
      <button
        onClick={() => setReqAppCount(reqAppCount + 1)}
        className='border-l border-neutral-5 text-neutral-1'
      >
        +
      </button>
    </div>
  );
}

export default RequestAppCount;
