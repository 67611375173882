import { CloseOutline, Filter, Redo } from '@carbon/icons-react';
import { FilterMatchMode } from 'primereact/api';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  CurrencyFlag,
  InheritedMenu,
  PaginatorTemplate,
  Status,
  TableSearch,
} from '../../../../components';
import FilterModal from '../../../../components/FilterModal';
import useRole from '../../../../hooks/useRoles';
import { Translate } from '../../../../i18n/translate';
import { Download, MoreAction } from '../../../../icons';
import { useAppDispatch } from '../../../../store';
import {
  selectCurrentCustomer,
  selectFilters,
  sendToast,
  updateFilter,
} from '../../../../store/slices/main/mainSlice';
import { fetchCurrency } from '../../../../store/slices/refdata/actions';
import { selectCustomerCurrencies } from '../../../../store/slices/refdata/refdataSlice';
import { KYC_INDICATOR_OPTIONS } from '../../../Client/clientConstants';
import currencyRole from '../currencyRole';
import CurrencyService from './currencyService';

const CurrencyManagement = () => {
  const [selectedCurrencies, setSelectCurrencies] = useState<CurrencyConfiguration[] | null>(null);
  const [currentActions, setCurrentActions] = useState<MenuItem[]>([]);
  const menu = useRef<Menu>(null);
  const dispatch = useAppDispatch();
  const currentCustomer = useSelector(selectCurrentCustomer);
  const currencies = useSelector(selectCustomerCurrencies);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    isocode: { value: [], matchMode: FilterMatchMode.IN },
    status: { value: [], matchMode: FilterMatchMode.IN },
  });
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [isModalVisible, setModalVisible] = useState(false);
  const [filterValues, setFilterValues] = useState<any>();
  const { customerId } = useParams();
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const { hasRole } = useRole();
  const filterPersisted = useSelector(selectFilters)['currency_management'];

  const generateFilterMetaData = () => {
    const _currencies: Currency[] = currencies.reduce((filteredCurrencies, currency) => {
      if (
        currency.isocode !== 'ALL' &&
        !filteredCurrencies.some((item) => item.value === currency.isocode)
      ) {
        filteredCurrencies.push({
          label: currency.isocode,
          value: currency.isocode,
        });
      }
      return filteredCurrencies;
    }, [] as Currency[]);
    return [
      {
        header: 'By Status',
        fields: [
          {
            name: 'status',
            placeholder: 'Select Status',
            value: [],
            options: KYC_INDICATOR_OPTIONS,
          },
        ],
      },
      {
        header: 'By Currency',
        fields: [
          {
            name: 'isocode',
            type: 'currency',
            placeholder: 'Select Currency',
            value: [],
            options: _currencies,
          },
        ],
      },
    ];
  };

  useEffect(() => {
    if (filterPersisted) {
      handleApplyFilters(filterPersisted);
    }
  }, []);

  useEffect(() => {
    const filterMetaData = generateFilterMetaData();
    setFilterValues(filterMetaData);
  }, [currencies]);

  useEffect(() => {
    getCurrencies();
  }, [currentCustomer, dispatch]);

  const getCurrencies = () => {
    const customerId = currentCustomer?.id;
    if (!customerId) {
      return;
    }
    setSelectCurrencies([]);
    dispatch(fetchCurrency(customerId));
  };

  const enableCurrency = async (items: CurrencyConfiguration[] | null) => {
    if (items) {
      const checkforInactive = items.some((item: CurrencyConfiguration) => {
        return item.disabled == false;
      });
      if (checkforInactive) {
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'failedToEnable',
            detail: 'currency.choose.only.inactive',
          }),
        );
        return;
      }
      const codes: any = items.map((item: CurrencyConfiguration) => {
        return { code: item.isocode };
      });
      const { data, status } = await CurrencyService.enableCurrency(
        currentCustomer?.id || 1,
        codes,
      );
      getCurrencies();

      if (status === 200 && data.success) {
        getCurrencies();
        dispatch(
          sendToast({
            severity: 'success',
            summary: 'success',
            detail: 'currenciesEnabled',
          }),
        );
      } else {
        const { result } = data;
        const { errors } = result;
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'failedToEnable',
            detail: errors[0].errorTextCode,
          }),
        );
      }
    }
  };

  const disableCurrency = async (items: CurrencyConfiguration[] | null) => {
    if (items) {
      const checkforActive = items.some((item: CurrencyConfiguration) => {
        return item.disabled == true;
      });
      console.log(items);
      if (checkforActive) {
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'failedToDisable',
            detail: 'currency.choose.only.active',
          }),
        );
        return;
      }
      const codes: any = items.map((item: CurrencyConfiguration) => {
        return { code: item.isocode };
      });
      const { data, status } = await CurrencyService.disableCurrency(
        currentCustomer?.id || 1,
        codes,
      );
      if (status === 200 && data.success) {
        getCurrencies();
        dispatch(
          sendToast({
            severity: 'success',
            summary: 'success',
            detail: 'currenciesDisabled',
          }),
        );
      } else {
        const { result } = data;
        const { errors } = result;
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'error',
            detail: errors[0].errorTextCode,
          }),
        );
      }
    }
  };

  const actionBodyTemplate = (currency: CurrencyConfiguration) => {
    const actions: MenuItem[] = [
      {
        label: 'enableCurrency',
        onClick: () => {
          enableCurrency([currency]);
        },
        icon: <Redo />,
      },
      {
        label: 'disableCurrency',
        onClick: () => {
          disableCurrency([currency]);
        },
        icon: <CloseOutline />,
      },
    ];
    const actionFilter: MenuItem[] = actions.filter((item) => {
      if (currency.disabled === false && item.label === 'enableCurrency') {
        return false;
      }
      if (currency.disabled === true && item.label === 'disableCurrency') {
        return false;
      }
      return true;
    });

    return (
      <div>
        <div
          onClick={(event) => {
            setCurrentActions(actionFilter);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={currentActions} ref={menu} popupAlign='left' />
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const _filters = { ...filters };
    _filters['global'].value = value.trim();
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleApplyFilters = (formdata: FilterFormData) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    const _filters: any = {
      global: filters.global,
    };
    Object.keys(formdata).forEach((filterName: string) => {
      _filters[filterName] = { value: formdata[filterName], matchMode: FilterMatchMode.IN };
    });
    setFilters(_filters);
    dispatch(updateFilter({ filterKey: 'currency_management', value: formdata }));
  };

  const downloadCurrencies = async () => {
    const payload = {
      text: filters.global.value,
      disabled: filters.status?.value?.[0] === 'INACTIVE',
      codes: filters.isocode?.value,
    };
    const id = customerId ? parseInt(customerId) : selectedCustomer?.id;
    if (id) {
      await CurrencyService.downloadCurrencies(id, payload);
    }
  };

  return (
    <div className='table-card'>
      <FilterModal
        visible={isModalVisible}
        setFilterModalToggle={setModalVisible}
        filters={filterValues}
        setFilters={setFilterValues}
        title='currency_filter.title'
        onApply={handleApplyFilters}
      />
      {currencies.length != 0 && (
        <DataTable
          paginator={true}
          paginatorTemplate={PaginatorTemplate}
          first={0}
          rows={10}
          totalRecords={currencies.length}
          header={
            <div className='flex flex-col md:flex-row gap-3 justify-between'>
              <div className='text-neutral-1 text-lg-semibold'>
                <Translate value='total' />
                <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
                  {currencies.length}
                </span>
              </div>
              <div className='flex gap-2'>
                <TableSearch
                  globalFilterValue={globalFilterValue}
                  onGlobalFilterChange={onGlobalFilterChange}
                />
                <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
                  <Button
                    severity='secondary'
                    className='p-button-text h-12'
                    onClick={() => {
                      setModalVisible(true);
                    }}
                  >
                    <Filter size={20} />
                    <Translate value='filterLabel' className='ml-1 hidden md:hidden lg:block' />
                  </Button>
                  {!!activeFilterCount && (
                    <Badge
                      value={activeFilterCount}
                      className='bg-primary absolute -top-2 -right-2'
                    />
                  )}
                </div>
                <div className='ml-2'>
                  <Button
                    severity='contrast'
                    onClick={() => {
                      downloadCurrencies();
                    }}
                    className='h-12'
                  >
                    <Download />
                    <Translate value='downloadLabel' className='ml-1 hidden lg:block' />
                  </Button>
                </div>

                {selectedCurrencies && selectedCurrencies?.length > 0 && (
                  <>
                    <Button
                      severity='contrast'
                      onClick={() => {
                        enableCurrency(selectedCurrencies);
                      }}
                    >
                      <Translate value='enable' />
                    </Button>
                    <Button
                      severity='contrast'
                      onClick={() => {
                        disableCurrency(selectedCurrencies);
                      }}
                    >
                      <Translate value='disable' />
                    </Button>
                  </>
                )}
              </div>
            </div>
          }
          value={currencies}
          size={'large'}
          selectAll={true}
          selectionMode={'checkbox'}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selection={selectedCurrencies!}
          onSelectionChange={(e: { value: CurrencyConfiguration[] }) =>
            setSelectCurrencies(e.value)
          }
          dataKey='id'
          globalFilterFields={[
            'isocode',
            'status',
            'ratePrecision',
            'amountPrecision',
            'minAmount',
            'maxAmount',
          ]}
          filters={filters}
        >
          {hasRole(currencyRole.EditCurrency) && (
            <Column selectionMode='multiple' headerStyle={{ width: '1rem' }} />
          )}
          <Column
            field='isocode'
            header='Currency'
            sortable
            body={(currency: CurrencyConfiguration) => (
              <div className='text-sm-medium text-neutral-2'>
                <CurrencyFlag currencyCode={currency.isocode} />
              </div>
            )}
          ></Column>
          <Column
            field='status'
            header='Status'
            sortable
            body={(currency: CurrencyConfiguration) => (
              <Status status={currency.disabled ? 'INACTIVE' : 'ACTIVE'} />
            )}
          ></Column>
          <Column
            field='ratePrecision'
            header='Rate Precision'
            body={(currency: CurrencyConfiguration) => <div>{currency.ratePrecision}</div>}
          ></Column>
          <Column
            field='amountPrecision'
            header='Amount Precision'
            body={(currency: CurrencyConfiguration) => <div>{currency.amountPrecision}</div>}
          ></Column>
          <Column
            field='minAmount'
            header='Minimum Amount'
            body={(currency: CurrencyConfiguration) => <div>{currency.minAmount?.value}</div>}
          ></Column>
          <Column
            field='maxAmount'
            header='Maximum Amount'
            body={(currency: CurrencyConfiguration) => <div>{currency.maxAmount?.value}</div>}
          ></Column>
          {hasRole(currencyRole.EditCurrency) && (
            <Column header='Actions' body={actionBodyTemplate}></Column>
          )}
        </DataTable>
      )}
    </div>
  );
};

export default CurrencyManagement;
