import { Warning } from '@carbon/icons-react';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RawHtmlContent, Translate } from '../../i18n/translate';
import { useAppDispatch } from '../../store';
import { fethCurrentUser } from '../../store/slices/main/actions';
import {
  selectAoboClient,
  selectCurrentCustomer,
  sendToast,
  setAoboClient,
  setCurrentCustomer,
} from '../../store/slices/main/mainSlice';
import { injectAoboCustoemrID } from '../../utils/apiClient';

const AoboHeader = () => {
  const dispatch = useAppDispatch();
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [parentClient] = useState<Client | null>(selectedCustomer);
  const selectedAoboClient = useSelector(selectAoboClient);
  const navigate = useNavigate();

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const exitAobo = () => {
    injectAoboCustoemrID(null);
    showToast('success', 'aobo.remove.title', 'aobo.remove.description');
    dispatch(fethCurrentUser());
    dispatch(setCurrentCustomer(parentClient));
    dispatch(setAoboClient(undefined));
    navigate('/');
  };

  if (!selectedAoboClient) {
    return <></>;
  }

  return (
    <div className='flex justify-around text-16-bold p-1 border-b border-neutral-border'>
      <div className='flex justify-start p-1'>
        <div className='mt-1 mr-1'>
          <Warning fill='var(--error-content-default)' />
        </div>
        <div className='text-error-1'>
          <RawHtmlContent
            htmlContent='aobo.warning.header'
            translateValues={{ clientName: selectedAoboClient?.label }}
          />
        </div>
      </div>
      <Button
        severity='secondary'
        className='border-0 !bg-warning-bg'
        onClick={() => {
          exitAobo();
        }}
      >
        <Translate value='aobo.exit' className='text-error-1' />
      </Button>
    </div>
  );
};

export default AoboHeader;
