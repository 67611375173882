import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import useRole from '../../hooks/useRoles';
import { selectRoles } from '../../store/slices/main/mainSlice';

interface RoleGuardProps {
  children: React.ReactNode;
  roles: string;
}

const RoleGuard: React.FC<RoleGuardProps> = ({ children, roles }) => {
  const rootRoles = useSelector(selectRoles);

  const { hasRole } = useRole();
  if (rootRoles.length !== 0 && !hasRole(roles)) {
    return <Navigate to='/unauthorized' replace />;
  }
  return <>{rootRoles.length > 0 ? children : <></>}</>;
};

export default RoleGuard;
