import { Edit } from '@carbon/icons-react';
import { useState } from 'react';
import { translateWithValues } from '../../i18n/translate';
import { QuoteStatus } from '../../types';
import TextInput, { TextInputProps } from '../TextInput';

function QuoteAmountInput(props: TextInputProps & { isEditMode: boolean }) {
  const [startEditing, setStartEditing] = useState(props.isEditMode);

  return (
    <div className='w-full flex flex-col gap-1'>
      {props.isEditable ? (
        <>
          <label className='whitespace-nowrap overflow-hidden text-ellipsis inline-block'>
            {translateWithValues(props.label)}
            {props.isRequired ? <span className='text-error-1'>*</span> : ''}
          </label>

          <div className='styled-text-display flex gap-4 mt-[10px] items-center'>
            <span className='text-md-semibold'>{props.value}</span>
            <Edit
              className='cursor-pointer'
              onClick={() => props.setQuoteStatus(QuoteStatus.AddQuote)}
            />
          </div>
        </>
      ) : (
        <TextInput
          searchIcon={
            startEditing || props.disabled ? null : (
              <Edit className='cursor-pointer' onClick={() => setStartEditing(true)} />
            )
          }
          disabled={!startEditing || props.disabled}
          {...props}
        />
      )}
    </div>
  );
}

export default QuoteAmountInput;
