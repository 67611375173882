import apiClient from '../../../utils/apiClient';
import { handleApiResponse } from '../../../utils/helper';

const EMAIL_SETTINGS = '/api/email-management/emails/customers';

const EmailSettingsService = {
  async getCustomerEmailSettings(customerId: number) {
    try {
      const response = await apiClient.get(`${EMAIL_SETTINGS}/${customerId}/email-settings`);
      return handleApiResponse<CustomerEmailSettingsType>(response);
    } catch (error) {
      console.error(`Error getting email settings for customer: ${customerId}`, error);
      return null;
    }
  },

  async createCustomerEmailSettings(customerId: number, data: CustomerEmailSettingsType) {
    try {
      const response = await apiClient.post(`${EMAIL_SETTINGS}/${customerId}/email-settings`, data);
      return handleApiResponse<typeof data>(response);
    } catch (error) {
      console.error(`Error creating email settings for customer: ${customerId}`, error);
      return null;
    }
  },

  async updateCustomerEmailSettings(customerId: number, data: CustomerEmailSettingsType) {
    try {
      const response = await apiClient.put(`${EMAIL_SETTINGS}/${customerId}/email-settings`, data);
      return handleApiResponse<typeof data>(response);
    } catch (error) {
      console.error(`Error updating email settings for customer: ${customerId}`, error);
      throw error;
    }
  },
};

export default EmailSettingsService;
